import axios from "axios";

const store = {
  state: () => ({
    customers: null,
    projects: null,
    tasks: null,
    teams: null,
    employees: null,
  }),
  getters: {
    getCustomers(state) {
      return state.customers;
    },
    getProjects(state) {
      return state.projects;
    },
    getTasks(state) {
      return state.tasks;
    },
    getTaskEmployees(state) {
      return state.employees;
    },
    getTaskTeams(state) {
      return state.teams;
    },
  },
  mutations: {
    setCustomers(state, payload) {
      state.customers = payload;
    },
    setProjects(state, payload) {

      if (payload) {
        for (let i = 0; i < payload.Items.length; i++) {
          const element = payload.Items[i];
          element.ProjectDetail = null;
        }
      }


      state.projects = payload;
    },
    setTasks(state, payload) {
      state.tasks = payload;
    },
    setTaskTeams(state, payload) {
      state.teams = payload;
    },
    setTaskEmployees(state, payload) {
      state.employees = payload;
    },
  },
  actions: {
    async getCustomers(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 1000
        };
      }
      var res = await axios.post("/project/listCustomers", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      store.commit("setCustomers", res.data.Data);
      return res.data.Data;
    },
    async getCustomer(store, payload) {
      var res = await axios.post("/project/getCustomer", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async addUpdateCustomer(store, payload) {
      var res = await axios.post("/project/AddUpdateCustomer", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async deleteCustomer(store, payload) {
      var res = await axios.post("/project/DeleteCustomer", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async getProjects(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 1000
        };
      }
      var res = await axios.post("/project/list", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      store.commit("setProjects", res.data.Data);
      return res.data.Data;
    },
    async getProject(store, payload) {
      var res = await axios.post("/project/Get", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async addUpdateProject(store, payload) {
      var res = await axios.post("/project/AddUpdateProject", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async deleteProject(store, payload) {
      var res = await axios.post("/project/DeleteProject", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async taskTeams(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 1000,
          IncludeEmployeeCount: true,
        };
      }
      var res = await axios.post("/project/listTeams", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      store.commit("setTaskTeams", res.data.Data);
      return res.data.Data;
    },
    async taskEmployees(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 1000,
          IncludeTeams: true,
          IncludeUsers: true,
        };
      }
      var res = await axios.post("/project/listEmployees", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      store.commit("setTaskEmployees", res.data.Data);
      return res.data.Data;
    },
    async getTasks(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 1000
        };
      }
      var res = await axios.post("/project/listTasks", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      store.commit("setTasks", res.data.Data);
      return res.data.Data;
    },
    async searchTasks(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 100
        };
      }
      var res = await axios.post("/project/listTasks", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async getTask(store, payload) {
      var res = await axios.post("/project/GetTask", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async addUpdateTask(store, payload) {
      var res = await axios.post("/project/AddUpdateTask", payload);
      if (res == null || res.data.HasError) {
        return null;
      } else {
        store.state.tasks.Items.unshift(res.data.Data.Item);
      }
      return res.data.Data;
    },
    async deleteTask(store, payload) {
      var res = await axios.post("/project/DeleteTask", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items.splice(index, 1);
      }

      return res.data.Data;
    },
    //UpdateTaskProject
    async updateTaskProject(store, payload) {
      var res = await axios.post("/project/UpdateTaskProject", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].Project = res.data.Data.Project;
      }

      return res.data.Data;
    },
    async updateTaskDuedate(store, payload) {
      var res = await axios.post("/project/UpdateTaskDuedate", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].DueDate = payload.DueDate;
      }

      return res.data.Data;
    },
    async updateTaskPriority(store, payload) {
      var res = await axios.post("/project/UpdateTaskPriority", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].Priority = payload.Priority;
      }

      return res.data.Data;
    },
    async updateTaskTitle(store, payload) {
      var res = await axios.post("/project/UpdateTaskTitle", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].Title = payload.Title;
      }

      return res.data.Data;
    },
    async updateTaskDetails(store, payload) {
      var res = await axios.post("/project/UpdateTaskDetails", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].Details = payload.Details;
      }

      return res.data.Data;
    },
    async updateTaskAssignees(store, payload) {
      var res = await axios.post("/project/UpdateTaskAssignees", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].AssigneeIds = payload.AssigneeIds;
      }

      return res.data.Data;
    },
    async updateExternalProjectId(store, payload) {
      var res = await axios.post("/project/updateExternalProjectId", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async cancelIntegration(store, payload) {
      var res = await axios.post("/project/CancelIntegration", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async syncTasks(store, payload) {
      var res = await axios.post("/project/syncTasks", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async updateTaskStatus(store, payload) {
      var res = await axios.post("/project/UpdateTaskStatus", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      var index = store.state.tasks.Items.findIndex(x => x.ID == payload.Id);
      if (index != -1) {
        store.state.tasks.Items[index].Status = payload.Status;
      }

      return res.data.Data;
    },
    async getProjectDetail(store, payload) {
      var res = await axios.post("/project/Get", payload);
      if (res == null || res.data.HasError) {
        return null;
      }

      // var index = store.state.projects.Items.findIndex(x => x.ID == payload.Id);
      // if (index != -1) {
      //   store.state.projects.Items[index].ProjectDetail = res.data.Data.Item;
      // }

      return res.data.Data;
    },
    async clickupWorkspaces(store, payload) {
      var res = await axios.post("/integration/clickupWorkspaces", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async clickupFolders(store, payload) {
      var res = await axios.post("/integration/clickupFolders", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async saveTrelloToken(store, payload) {
      var res = await axios.post("/project/saveTrelloToken?ProjectId=" + payload.ProjectId + "&Token=" + payload.Token, payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },

    //comments

    async listComments(store, payload) {
      if (!payload) {
        payload = {
          Page: 1,
          PageSize: 100
        };
      }
      var res = await axios.post("/project/listComments", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async addUpdateComment(store, payload) {
      var res = await axios.post("/project/addUpdateComment", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async deleteComment(store, payload) {
      var res = await axios.post("/project/deleteComment", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },
    async deleteFile(store, payload) {
      var res = await axios.post("/project/DeleteTaskAttachment", payload);
      if (res == null || res.data.HasError) {
        return null;
      }
      return res.data.Data;
    },

  },
}

export default store;