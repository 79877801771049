import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import authModule from './auth.js';
import insightsModule from './insights.js';
import teamModule from './team.js';
import employeeModule from './employee.js';
import screenshotsModule from './screenshots.js';
import timelapseVideosModule from './timelapseVideos.js';
import activityLogsModule from './activityLogs.js';
import accountModule from './account.js';
import reviewAppsModule from './reviewApps.js';
import organizationModule from './organisation.js';
import billingModule from './billing.js';
import timesheetModule from './timesheet.js';
import subscriptionModule from './subscription.js';
import timeEntryModule from './timeEntry.js';
import projectManagementModule from './project.js';
import affiliateModule from './affiliate.js';
import profileModule from './profile.js';
import costManagementModule from './costManagement.js';
import leaveManagementModule from './leaveManagement.js';
import riskUserSettingsModule from './riskUserSettings.js';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authModule,
    insightsModule,
    teamModule,
    employeeModule,
    screenshotsModule,
    timelapseVideosModule,
    activityLogsModule,
    accountModule,
    reviewAppsModule,
    organizationModule,
    billingModule,
    timesheetModule,
    subscriptionModule,
    timeEntryModule,
    projectManagementModule,
    affiliateModule,
    profileModule,
    costManagementModule,
    leaveManagementModule,
    riskUserSettingsModule
  },
  state: {
    validationErrors:[],
    timezones:[],
    enums:null,
    showMenu: false,
    darkMode: false,
  },
  getters: {
    getValidationItems(state) {
      return state.validationErrors;
    },
    getEnums(state) {
      return state.enums;
    },
    getTimezones(state) {
      return state.timezones;
    },
    getShowMenu(state) {
      return state.showMenu;
    }
  },
  mutations: {
    setValidationErrors(state, payload) {
      state.validationErrors = payload;
    },
    setEnums(state, payload) {
      state.enums = payload;
    },
    setTimezones(state, payload) {
      state.timezones = payload;
    },
    setShowMenu(state, payload) {
      state.showMenu = payload;
    }
  },
  actions: {
    async getEnums(store, payload) {
      var res = await axios.get("/definition/listEnums");
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setEnums", res.data.Data.Items);
      return res.data.Data;
    },
    async getTimezones(store, payload) {
      var res = await axios.get("/definition/listTimezones");
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setTimezones", res.data.Data.Items);
      return res.data.Data;
    },
    async getShowMenu(store, payload) {
      store.commit("setShowMenu", payload);
      return store;
    },
  },
})
