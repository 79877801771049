<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false"
        @keyup.enter.native="save" v-loading="loading == 'init'">

        <el-form-item label="Project name" required>
          <el-input v-model="formData.Title" placeholder="Worktivity" required clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Customer">
          <CustomerSelectbox :selectedId="formData.CustomerId" @changed="(id) => formData.CustomerId = id"
            :key="formData.CustomerId" />
          <ValidationErrorItem validationKey="CustomerId" />
        </el-form-item>

        <el-form-item v-if="$isInRole('Owner,Coowner')">

          <FormLabelItem title="Teams"
            :tooltip="'Select which teams can view tasks related to this project. If you don\'t make any selections, by default, all teams can view tasks related to this project.'" />

          <MultipleTeamSelectbox :selectedId="formData.TeamIds" @changed="(id) => formData.TeamIds = id"
            :key="formData.TeamIds.length" />
          <ValidationErrorItem validationKey="TeamIds" />
        </el-form-item>

        <el-form-item v-if="$isInRole('Owner,Coowner')">

          <FormLabelItem title="Employees"
            :tooltip="'Select which employees can view tasks related to this project. If you don\'t make any selections, by default, all employees can view tasks related to this project.'" />

          <MultipleEmployeeSelectbox :selectedId="formData.EmployeeIds" @changed="(id) => formData.EmployeeIds = id"
            :key="formData.EmployeeIds.length" />
          <ValidationErrorItem validationKey="EmployeeIds" />
        </el-form-item>

        <el-form-item label="Color" required>
          <el-color-picker v-model="formData.Color" :predefine="predefineColors">
          </el-color-picker>
          <ValidationErrorItem validationKey="Color" />
        </el-form-item>

        <el-form-item v-if="trackSettings.EnableCostManagement">
          <FormLabelItem title="Project budget (zero means infinite)"
            tooltip="It will be used to control you spent limit." />
          <MoneyInput :min="0" :max="100000000" :fixed="2" v-model="formData.TotalBudget" clearable>
            <template slot="append">{{ $getEnumTitle('CurrencyType', trackSettings.CostManagementCurrency) }}</template>
          </MoneyInput>
          <ValidationErrorItem validationKey="TotalBudget" />
        </el-form-item>

        <el-form-item>
          <FormLabelItem title="Notes"
            tooltip="It will be only visible by you and your team. Your customers cannot see this note." />
          <el-input v-model="formData.Notes" placeholder="" type="textarea" :rows="5" required clearable></el-input>
          <ValidationErrorItem validationKey="Notes" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Add project' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import CustomerSelectbox from '@/components/form/CustomerSelectbox.vue';
import MoneyInput from '@/components/form/MoneyInput.vue';
import MultipleEmployeeSelectbox from '@/components/form/MultipleEmployeeSelectbox.vue';
import MultipleTeamSelectbox from '@/components/form/MultipleTeamSelectbox.vue';

export default {
  name: 'ProjectssAddUpdateModal',
  props: ["data"],
  components: {
    CustomerSelectbox,
    MultipleEmployeeSelectbox,
    MultipleTeamSelectbox,
    MoneyInput
  },
  data() {
    return {
      loading: null,
      formData: {
        Id: null,
        Title: null,
        CustomerId: null,
        Color: "#8B4DFF",
        TeamIds: [],
        EmployeeIds: [],
        Notes: null,
        TotalBudget: null,
      },
      predefineColors: [
        '#8B4DFF',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585'
      ]
    }
  },
  async mounted() {
    this.loading = 'init';
    await this.$store.dispatch("getCustomers");
    if (this.data) {
      console.log(this.data);
      this.formData = this.data;
    }
    this.loading = null;
  },
  methods: {
    async save() {
      this.loading = 'save';
      var res = await this.$store.dispatch("addUpdateProject", this.formData);
      this.loading = null;
      if (res != null) {
        await this.$store.dispatch("getProjects");
        this.$emit('closeDialog');
      }
    }
  },
  computed: {
    customers() {
      return this.$store.getters.getCustomers;
    },
    account() {
      return this.$store.getters.getAccount;
    },
    organization() {
      return this.account.CurrentOrganization;
    },
    trackSettings() {
      return this.organization.TrackSettings;
    },
  }
}
</script>

<style></style>