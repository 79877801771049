import axios from "axios";

const store = {
  state: () => ({
    payroll:null,
    payrollHistory:null,
    customerInvoices:null,
  }),
  getters: {
    getCustomerInvoices(state) {
      return state.customerInvoices;
    },
    getPayroll(state) {
      return state.payroll;
    },
    getPayrollHistory(state) {
      return state.payrollHistory;
    },
  },
  mutations: {
    setCustomerInvoices(state, payload) {
      state.customerInvoices = payload;
    },
    setPayroll(state, payload) {
      state.payroll = payload;
    },
    setPayrollHistory(state, payload) {
      state.payrollHistory = payload;
    },
  },
  actions: {
    async payroll(store, payload) {
      var res = await axios.post("/costmanagement/payroll", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setPayroll", res.data.Data);
      return res.data.Data;
    },
    async payrollHistory(store, payload) {
      var res = await axios.post("/costmanagement/payrollHistory", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      store.commit("setPayrollHistory", res.data.Data);
      return res.data.Data;
    },
    async payrollExportXlsx(store, payload) {
      var res = await axios.post("/costmanagement/payrollexport", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    
    async payrollHistoryExportXlsx(store, payload) {
      var res = await axios.post("/costmanagement/payrollHistoryexport", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async payrollMarkAsPaid(store, payload) {
      var res = await axios.post("/costmanagement/payrollmarkaspaid", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      //employee id yi mevcut kayıtlarda unpaid sıfır yapalım ki ödendikce arayüzden gitsin
      return res.data.Data;
    },
    async payrollChangePayRate(store, payload) {
      var res = await axios.post("/costmanagement/PayrollChangePayRate", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async payrollMarkAsUnPaid(store, payload) {
      var res = await axios.post("/costmanagement/PayrollMarkasUnPaid", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    //Invoices
    async customerInvoiceList(store, payload) {
      var res = await axios.post("/costmanagement/ListInvoices", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      store.commit("setCustomerInvoices", res.data.Data);
      return res.data.Data;
    },
    async customerInvoiceGet(store, payload) {
      var res = await axios.post("/costmanagement/GetInvoice", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async customerInvoceAddUpdate(store, payload) {
      var res = await axios.post("/costmanagement/AddUpdateInvoice", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async customerInvoiceDelete(store, payload) {
      var res = await axios.post("/costmanagement/DeleteInvoice", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
    async customerInvoiceMarkAsSent(store, payload) {
      var res = await axios.post("/costmanagement/MarkAsSent", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
    async customerInvoiceRecordPayment(store, payload) {
      var res = await axios.post("/costmanagement/RecordPayment", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
  }
}

export default store;