
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-red p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p>You are about to cancel your subscription.</p>
            <p>You can continue to use your account until the subscription end date.</p>
            <p>You can start a new subscription anytime</p>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="cancelSubscription">
        <el-form-item label="Your password" required>
          <el-input v-model="formData.Password" type="password" placeholder="******" required clearable></el-input>
          <ValidationErrorItem validationKey="Password" />
        </el-form-item>

        <el-form-item label="Cancellation reason" required>
          <el-input v-model="formData.CancellationReason" type="text" placeholder="Please specify..." required clearable></el-input>
          <ValidationErrorItem validationKey="CancellationReason" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="danger" icon="fas fa-exclamation-circle" @click="cancelSubscription" :loading="loading == 'delete'">Cancel subscription</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CancelSubscriptionModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            CancellationReason:null,
            Password:null,
          }
        }   
    },
    methods: {
      async cancelSubscription() {
        this.loading = 'delete';
        var res = await this.$store.dispatch("cancelSubscription", this.formData);
        this.$emit('subscriptionCancelled');
        this.$emit('closeDialog');
        this.loading = null;
      }
    }
}
</script>

