<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">
      
      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save">

        <el-form-item label="Employee" required v-if="$isInRole('Owner,Coowner,Manager')">
          <div class="filter-item">
            <EmployeeSelectbox placeholder="Select an employee..." :selectedId="formData.EmployeeId" @changed="(id) => formData.EmployeeId = id" :key="formData.EmployeeId" />
          </div>
          <ValidationErrorItem validationKey="EmployeeId" />
        </el-form-item>

        <div class="d-flex justify-content-between gap-1">
          <el-form-item label="Start date" class="w-100" required>
            <el-date-picker
              v-model="formData.StartDate"
              :picker-options="pickerOptions"
              type="datetime"
              format="dd.MM.yyyy HH:mm"
              placeholder="Select date and time">
            </el-date-picker>
            <ValidationErrorItem validationKey="StartDate" />
          </el-form-item>

          <el-form-item label="End date" class="w-100" required>
            <el-date-picker
              v-model="formData.EndDate"
              :picker-options="pickerOptions"
              type="datetime"
              format="dd.MM.yyyy HH:mm"
              placeholder="Select date and time">
            </el-date-picker>
            <ValidationErrorItem validationKey="EndDate" />
          </el-form-item>
        </div>



        <div class="card bg-blue p-5 mb-3" v-if="duration">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p>Total duration is <b>{{$parseTime(duration)}}</b></p>
          <p class="text-danger" v-if="duration > 600">Duration can not be bigger than 10 hours.</p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between gap-1" v-if="activityStats && formData.EmployeeId != null && formData.StartDate != null">
        <div class="activity-bar" v-loading="loading == 'getDataActivityStats'">
        <span v-for="(item, index) in activityStats.Items" :key="item.StartDate + item.Status + index" class="stat-view"
          :style="'width:' + (item.Duration * 100) / $sum(activityStats.Items, 'Duration') + '%'"
          :class="(' background-' + getStatusBadgeBg(item.Status))" @click="selectRange(item)">
          <div class="activity-stat-info shadow-md" >
            <span class="status">{{ (item.Status != null ? $getEnumTitle('EmployeeActivityLogStatus', item.Status) :
              'Inactive') }}</span>
            <span class="date" v-if="item.Duration > 1">
              {{ moment(item.StartDate).format('DD MMM yyyy - HH:mm') }} =>
              {{ moment(item.EndDate).format('DD MMM yyyy - HH:mm') }}
            </span>
            <span class="date" v-else>
              {{ moment(item.StartDate).format('DD MMM yyyy - HH:mm') }}
            </span>
            <span class="date" v-if="item.Duration > 1">
              <b>{{ $parseTime(item.Duration) }}</b>
            </span>

            <div class="apps">
              <img v-if="item.Icon != null" :src="item.Icon" alt="">
              <template v-if="item.Url != null">
                <span>{{ item.Url }}</span>
              </template>
              <template v-else>
                <span>{{ item.AppName }}</span>
              </template>
            </div>
          </div>
          <div class="check-point" v-if="item.Status == 0 || item.Status == 4"
            :class="(item.Status == 0 ? 'clockin' : '') + (item.Status == 4 ? 'clockout' : '')">
            <i class="fas fa-bolt" v-show="item.Status == 0"></i>
            <i class="fas fa-door-open" v-show="item.Status == 4"></i>
          </div>
        </span>
      </div>
      </div>


      <div class="d-flex justify-content-between gap-1" v-if="trackSettings.EnableProjectManagement">
        <el-form-item class="w-100">
          <FormLabelItem title="Project" tooltip="You can select any project and task to associate with your time entry."  />
          <ProjectSelectbox :selectedId="formData.ProjectId" @changed="(id) => formData.ProjectId = id" :key="formData.ProjectId" />
          <ValidationErrorItem validationKey="ProjectId" />
        </el-form-item>

        <el-form-item label="Task" required v-if="trackSettings.EnableProjectManagement && formData.ProjectId != null" class="w-100">
          <TaskSelectbox :projectId="formData.ProjectId" @changed="(id) => formData.ProjectTaskId = id" :key="formData.ProjectId" />
          <ValidationErrorItem validationKey="ProjectTaskId" />
        </el-form-item>
      </div>

      

        <el-form-item label="Work status" required>
          <el-radio-group v-model="formData.LogStatus">
            <template v-for="item in $getEnums('EmployeeActivityLogStatus').filter(x => [1,2].some(y => y == x.Key))" >
              <el-radio :label="item.Key" :key="item.Key">{{ item.DisplayName }}</el-radio>
            </template>
          </el-radio-group>
          <ValidationErrorItem validationKey="LogStatus" />
        </el-form-item>

       <el-form-item label="Productivity status" required v-if="formData.LogStatus != 2">
          <el-radio-group v-model="formData.ProductivityStatus">
            <template v-for="item in $getEnums('ProductivityStatus')" >
              <el-radio :label="item.Key" :key="item.Key" :disabled="formData.LogStatus == 2">{{ item.DisplayName }}</el-radio>
            </template>
          </el-radio-group>
          <ValidationErrorItem validationKey="ProductivityStatus" />
        </el-form-item>

        <el-form-item label="Reason" required>
          <el-input v-model="formData.Reason" type="textarea" rows="3" placeholder="Please specify..." required clearable></el-input>
          <ValidationErrorItem validationKey="Reason" />
        </el-form-item>

        <div class="mb-2">
          <span class="badge cursor-pointer mr-1" @click="setReason(item)" v-for="(item,index) in reasons" :key="index">{{ item }}</span>
        </div>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          Add time entry
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import TaskSelectbox from '@/components/form/TaskSelectbox.vue';
import moment from "moment/min/moment-with-locales";
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';

export default {
    name: 'TimeEntryAddOrUpdateModal',
    props: ["data"],
    components: {
      EmployeeSelectbox,
      ProjectSelectbox,
      TaskSelectbox,
    },
    data(){
        return { 
          moment,
          loading:null,
          duration:null,
          reasons:["Phone call", "Out of office", "Customer visit", "Event"],
          pickerOptions: {
            firstDayOfWeek:1,
          },
          formData: {
            Id:null,
            EmployeeId:null,
            Title:null,
            StartDate:null,
            EndDate:null,
            LogStatus:null,
            ProductivityStatus:null,
            ProjectId:null,
            ProjectTaskId:null,
            Reason:null,
          }
        }   
    },
    mounted() {
      if(this.data)
      {
        this.formData = this.data;
      }

      //this.formData.StartDate = new Date(this.$getToday()[0]).toISOString();

      if(this.$isInRole('Employee'))
      {
        this.formData.EmployeeId = this.currentEmployeeId;
      }
    },
    methods: {
      selectRange(item) {
        console.log(item);
        this.formData.StartDate = item.StartDate;
        this.formData.EndDate = item.EndDate;
      },
      getStatusBadgeBg(status) {
      switch (status) {
        case 0:
        case 1:
          return "green";
        case 2: //OnBreak
          return "blue";
        case 3: //Idle
          return "orange";
        case 4: //Stopped Work
          return "red";
        case 5: //Yet to start
          return "yellow";
        default:
          return "soft-gray";
      }
    },
      setReason(reason) {
        debugger;
        this.formData.Reason = reason;
      },
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("createTimeEntry", this.formData);
        this.loading = null;
         if(res != null)
        {
          this.$emit('timeEntryCreated');
          this.$emit('closeDialog');
        }
      },
      async getDataActivityStats() {
        if(this.loading == 'getDataActivityStats')
        {
          return;
        }

        this.loading = "getDataActivityStats";

        var start = new Date(this.formData.StartDate);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        var req = {
          EmployeeId: this.formData.EmployeeId,
          DateFilter: 1,
          StartDate: start,
          EndDate: this.$addDayToDate(start, 1)
        };
        console.log("getDataActivityStats", req);
        await this.$store.dispatch("activityEmployeeStatistics", req);
        this.loading = null;
      },
      calculateDuration() {
        if(this.formData.StartDate == null || this.formData.EndDate == null)
        {
          this.duration = null;
        }
        else
        {
          const date1 = new Date(this.formData.StartDate);
          const date2 = new Date(this.formData.EndDate);
          const diffTime = Math.abs(date2 - date1);
          if(date2 <= date1)
          {
            this.duration = null;
          }
          else
          {
            this.duration = diffTime / 1000 / 60;
          }
          
        }
      }
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
       trackSettings() {
          return this.organization.TrackSettings;
      },
      currentEmployeeId() {
        return this.account.Employings.Items.find(x => x.OrganizationId == this.organization.ID).ID;
      },
      activityStats() {
        return this.$store.getters.getActivityStats;
      },
    },
    watch: {
      'formData.ProjectId'() {
        this.formData.ProjectTaskId = null;
      },
      'formData.LogStatus'() {
        if(this.formData.LogStatus == 2)
        {
          this.formData.ProductivityStatus = 2;
        }
        else
        {
          this.formData.ProductivityStatus = null;
        }

      },
      'formData.StartDate'() {
        this.calculateDuration();

        if(this.formData.EmployeeId != null && this.formData.StartDate != null)
        {
          this.getDataActivityStats();
        }
      },
      'formData.EmployeeId'() {
        if(this.formData.StartDate != null && this.formData.EmployeeId != null)
        {
          this.getDataActivityStats();
        }
      },
      'formData.EndDate'() {
        this.calculateDuration();
      },
    }
}
</script>

<style>

</style>