import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    teams:null
  }),
  getters: {
    getTeams(state) {
      return state.teams;
    }
  },
  mutations: {
    setTeams(state, payload) {
      state.teams = payload;
    }
  },
  actions: {
    async getTeams(store, payload) {
      if(!payload)
      {
        payload = {
          Page: 1,
          PageSize: 1000,
          IncludeEmployeeCount: true,
        };
      }
      var res = await axios.post("/team/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setTeams", res.data.Data);
      return res.data.Data;
    },
    async addUpdateTeam(store, payload) {
      var res = await axios.post("/team/addorupdate", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getTeams");
      return res.data.Data;
    },
    async deleteTeam(store, payload) {
      var res = await axios.post("/team/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getTeams");
      return res.data.Data;
    }
  },
  
  }

export default store;