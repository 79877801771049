<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement">
          <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id" :key="filter.ProjectId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement && filter.ProjectId != null">
          <TaskSelectbox :projectId="filter.ProjectId" @changed="(id) => filter.ProjectTaskId = id" :key="filter.ProjectId" />
        </div>
      </div>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5" v-if="!taskInsights">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-5" v-if="taskInsights && taskInsights.Items.length > 0" v-loading="loading == 'getData'">

      <div class="overview-stats" v-dragscroll.x>

          <div class="stats-item stats-item-task purple">
            <div class="icon-badge"><i class="fas fa-tasks"></i></div>
            <span>{{ taskInsights.Items.length }}</span>
            <p>Tasks</p>
          </div>

          <div class="stats-item stats-item-task green">
            <div class="icon-badge"><i class="fas fa-project-diagram"></i></div>
            <span>{{ currentProjects.length }}</span>
            <p>Projects</p>
          </div>

          <div class="stats-item stats-item-task blue">
            <div class="icon-badge"><i class="fas fa-clock"></i></div>
            <span>{{ $parseTime($sum(taskInsights.Items, "TotalMins")) }}</span>
            <p>Total working</p>
          </div>

          <div class="stats-item stats-item-task orange">
            <div class="icon-badge"><i class="fas fa-wallet"></i></div>
            <span>{{ $formatMoney($sum(taskInsights.Items, "TotalSpent"), $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}</span>
            <p>Spent amount</p>
          </div>

          <div class="stats-item stats-item-task red">
            <div class="icon-badge"><i class="fas fa-money-bill"></i></div>
            <span>{{ $formatMoney($sum(taskInsights.Items, "TotalBillable"), $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}</span>
            <p>Billable amount</p>
          </div>

          <div class="stats-item stats-item-task yellow">
            <div class="icon-badge"><i class="fas fa-file-invoice"></i></div>
            <span>%{{ $percentage($sum(taskInsights.Items, "TotalBillable") - $sum(taskInsights.Items, "TotalSpent"), $sum(taskInsights.Items, "TotalSpent")) }}</span>
            <p>Avg. profit</p>
          </div>


        </div>
      


    </div>
    
<template v-if="taskInsights && projects">
    <NoData v-if="currentProjects.length == 0" />
     <template  v-for="item in currentProjects" >
      <div class="card p-5 mb-5" :key="item.ID" v-loading="loading == 'getData'">
        <div class="card-header" :class="activeList.some(x => x == item.ID) ? '' : 'hr'">
        <div class="card-title">
          <h3><i class="fas fa-circle" :style="'color: ' + item.Color"></i> {{ item.Title }}</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge blue" @click="toggleActiveList(item.ID)">{{taskInsights.Items.filter(x => x.Task.ProjectId == item.ID).length}} task(s)</span>
        </div>
      </div>

      <div class="activity-table" :class="activeList.some(x => x == item.ID) ? 'hidden-list' : ''">
        <el-table :data="taskInsights.Items.filter(x => x.Task.ProjectId == item.ID)" @expand-change="expandChange" ref="tab" >

          <el-table-column label="Task">
            <template slot-scope="scope">
              <p>
                {{ scope.row.Task.Title }}
              </p>
            </template>
          </el-table-column>

          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="row" v-loading="props.row.Details.Loading == true">
                <el-table :data="props.row.Details.Items">
                    <el-table-column label="Employee" min-width="250">
                      <template slot-scope="scope2">
                        <EmployeeTablePreview :employee="scope2.row.Employee" />
                      </template>
                    </el-table-column>

                    <el-table-column label="Total working" width="150" class-name="text-center">
                    <template slot-scope="scope2">
                      <span class="badge" :style="scope2.row.TotalMins == 0 ? 'opacity:.3':''">{{ $parseTime(scope2.row.TotalMins) }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Spent" width="150" class-name="text-center">
                    <template slot-scope="scope2">
                      <span class="badge" :style="(scope2.row.TotalSpent) == 0 ? 'opacity:.3' : ''">
                        {{ $formatMoney(scope2.row.TotalSpent, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Billable" width="150" class-name="text-center">
                    <template slot-scope="scope2">
                      <span class="badge" :style="(scope2.row.TotalBillable) == 0 ? 'opacity:.3' : ''">
                        {{ $formatMoney(scope2.row.TotalBillable, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Profit" width="150" class-name="text-center">
                    <template slot-scope="scope2">
                    <el-tooltip class="item" effect="dark" :content="$formatMoney(scope2.row.TotalBillable - scope2.row.TotalSpent, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency))" placement="top">
                      <span class="badge" :class="scope2.row.TotalBillable - scope2.row.TotalSpent > 0 ? 'green' : ''">
                        %{{ $percentage(scope2.row.TotalBillable - scope2.row.TotalSpent, scope2.row.TotalSpent) }}
                      </span>
                    </el-tooltip>
                    </template>
                  </el-table-column>
                  
                  </el-table>
              </div>
              
            </template>
          </el-table-column>  

          <el-table-column label="Total working" width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :style="scope.row.TotalMins == 0 ? 'opacity:.3':''">{{ $parseTime(scope.row.TotalMins) }}</span>
            </template>
          </el-table-column>

           <el-table-column label="Spent" width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :style="(scope.row.TotalSpent) == 0 ? 'opacity:.3' : ''">
                {{ $formatMoney(scope.row.TotalSpent, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
              </span>
            </template>
          </el-table-column>

           <el-table-column label="Billable" width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :style="(scope.row.TotalBillable) == 0 ? 'opacity:.3' : ''">
                {{ $formatMoney(scope.row.TotalBillable, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="Profit" width="150" class-name="text-center">
            <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="$formatMoney(scope.row.TotalBillable - scope.row.TotalSpent, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency))" placement="top">
              <span class="badge" :class="scope.row.TotalBillable - scope.row.TotalSpent > 0 ? 'green' : ''">
                %{{ $percentage(scope.row.TotalBillable - scope.row.TotalSpent, scope.row.TotalSpent) }}
              </span>
            </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
      </template>


       <div class="d-flex mb-5 justify-content-center" v-if="currentProjects.length > 0">
        <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
          Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
        </el-button>
        <div class="d-flex align-items-center">
          <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
        </div>
       </div>
  </template>




  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import TaskSelectbox from '@/components/form/TaskSelectbox.vue';

import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';

export default {
    name: 'TaskInsightsIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      ProjectSelectbox,
      TaskSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker,
    },

    data(){
        return {
            meta: {
              title: 'Task insights',
              desc:'You can reach your organization task insights here.'
            },
            loading:null,
            activeList: [],
            filter: {
              TeamId:null,
              EmployeeId:null,
              ProjectId:null,
              DateFilter:1,
              ProjectTaskId: null,
              StartDate:null,
              EndDate:null,
              Statuses:[1]
            }
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      
      await this.$store.dispatch("getSubscription");
      await this.$store.dispatch("getTeams");
      await this.$store.dispatch("getProjects");

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      await this.getData();

    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("taskInsights", this.filter);
        this.loading = null;
      },
      expandChange(row, expanded) {
        this.$store.dispatch("taskEmployeeInsights", { 
          StartDate: this.filter.StartDate,
          EndDate: this.filter.EndDate,
          TeamId: this.filter.TeamId,
          EmployeeId: this.filter.EmployeeId,
          ProjectId: this.filter.ProjectId,
          ProjectTaskId: row.ID,
          Statuses: this.filter.Statuses
         });
      },
      // expand(row, isExpanded) {
      //   if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
      //   {
      //     this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
      //   }
      //   else
      //   {
      //     this.$store.dispatch("taskEmployeeInsights", { ProjectTaskId: row.ID });
      //     this.$refs.tab.store.states.expandRows.push(row);
      //   }
      // },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("tasksInsightsExport", this.filter);
        if(res != null)
        {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      toggleActiveList(item) {
        if (this.activeList.some(x => x == item))
        {
            this.activeList = this.activeList.filter(x => x != item);
        } else {
            this.activeList.push(item);
        }
      },
      tableRowClassName({row, rowIndex}) {
        if (rowIndex === 1) {
          return 'orange-row';
        } else if (rowIndex === 3) {
          return 'green-row';
        }
        return '';
      },
    },
    computed: {
      account() {
          return this.$store.getters.getAccount;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
      organization() {
          return this.account.CurrentOrganization;
      },
      trackSettings() {
          return this.organization.TrackSettings;
      },
      currentProjects() {
        var result = [];
        if(!this.taskInsightsReal)
        {
          return result;
        }
        for (let i = 0; i < this.taskInsights.Items.length; i++) {
          const element = this.taskInsights.Items[i];
          if(!result.some(x => x.ID == element.Task.ProjectId))
          {
            result.push(this.projects.Items.find(x => x.ID == element.Task.ProjectId));
          }
        }
        return result;
      },
      taskInsightsReal() {
        return this.$store.getters.getTaskInsights;
      },
      taskInsights() {
        return this.$store.getters.getTaskInsights;
      },
      teams() {
        return this.$store.getters.getTeams;
      },
      projects() {
        return this.$store.getters.getProjects;
      },
      employees() {
        return this.$store.getters.getEmployees;
      }
    },
    watch: {
      'filter.StartDate'() {
        this.getData();
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }
        this.getData();
      },
      'filter.EmployeeId'() {
        this.getData();
      },
      'filter.ProjectId'() {
        this.filter.ProjectTaskId = null;
        this.getData();
      },
      'filter.ProjectTaskId'() {
        this.getData();
      },
    },
}
</script>
