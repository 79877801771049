<template>
    <div class="team-member-item card p-4 mb-4 cursor-pointer" :class="bgClass" @click='$store.commit("setProfileEmployeeId", { id: member.Employee.Id, showDrawer: true })'>
        <div class="tm-user">
            <div class="tm-avatar">
                <el-avatar :size="40" :src="member.Employee.User != null ? member.Employee.User.Image : member.Employee.Image"></el-avatar>
            </div>
   
                <div class="tm-text">
                    <el-tooltip :disabled="!$isNotNullOrWhiteSpace(member.Employee.EmployeeCode)" :content="member.Employee.EmployeeCode" placement="top">
                     <div>
                     <h3 v-if="member.Employee.User != null">{{ member.Employee.User.FirstName + ' ' + member.Employee.User.LastName }}</h3>
                        <h3 v-else>{{ member.Employee.FirstName + ' ' + member.Employee.LastName }}</h3>
                      <span>{{ member.Employee.Team.Title }}</span>
                    </div>
                </el-tooltip>

                    <div>

                        <el-tooltip content="Employee's activity level is calculated based on the user's keyboard and mouse usage rate in the last 60 seconds (average for today)." placement="top">
                
                 <span class="badge" :class="'text-' + $getActivityLevelBadge($percentage(member.ActivityLevel / member.Working, 60))" v-if="member.ActivityLevel != null && member.ActivityLevel != 0">%{{ $percentage(member.ActivityLevel / member.Working, 60) }}</span>
                        <span class="badge" style="opacity:.3" v-else>%0</span>
                

                </el-tooltip>

                        
                    </div>
                </div>
               
            
            
        </div>
        <div class="tm-details">
            <div class="tm-clock">
                    
                <el-tooltip class="item" effect="dark" content="Clock-in / clock-out" placement="top">
                    <span>
                        <i class="fas fa-clock mr-1"></i>
                        <span v-if="member.ClockIn != null">{{moment(member.ClockIn).format('HH:mm')}}</span>
                        <span v-else class="text-gray">--:--</span>
                        <span class="text-gray ml-1 mr-1">/</span>
                        <span v-if="member.ClockOut != null">{{moment(member.ClockOut).format('HH:mm')}}</span>
                        <span v-else class="text-gray">--:--</span>
                    </span>
                </el-tooltip>

                <span v-if="member.ClockIn != null">{{ $parseTime(member.Working) }} worked.</span>
                <span v-else>Not started yet.</span>
            </div>
            <div class="badge" :class="colorClass">
                <span>{{ $getEnumTitle("EmployeeActivityLogStatus",member.Status) }}</span>
            </div>
        </div>
        
    </div>
</template>

<script>

import moment from "moment/min/moment-with-locales";

export default {
    name: 'TeamMemberItem',
    data() {
        return {
            moment
        }
    },
    props: {
        member: Object
    },
    computed: {

        account() {
            return this.$store.getters.getAccount;
        },
        organization() {
            return this.account.CurrentOrganization;
        },
        bgClass() {
            switch (this.member.Status) {
                case 0:
                case 1:
                    return "bg-green";
                case 2: //OnBreak
                    return "bg-blue";
                case 3: //Idle
                    return "bg-orange";
                case 4: //Stopped Work
                    return "bg-red";
                case 5: //Yet to start
                    return "bg-yellow";
                default:
                    return "bg-red";
            }
        },
        colorClass() {
            switch (this.member.Status) {
                case 0:
                case 1:
                    return "green";
                case 2: //OnBreak
                    return "blue";
                case 3: //Idle
                    return "orange";
                case 4: //Stopped Work
                    return "red";
                case 5: //Yet to start
                    return "yellow";
                default:
                    return "red";
            }
        }
    }

}
</script>
