import axios from "axios";

const store = {
  state: () => ({
    riskUserSettings:null,
  }),
  getters: {
    getRiskUserSettings(state) {
      return state.riskUserSettings;
    },
  },
  mutations: {
    setRiskUserSettings(state, payload) {
      state.riskUserSettings = payload;
    }
  },
  actions: {
    async riskUserSettings(store, payload) {
      var res = await axios.post("/riskUserSettings/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      for (let i = 0; i < res.data.Data.Items.length; i++) {
        res.data.Data.Items[i].IsOpen = false;
        res.data.Data.Items[i].Data = null;
      }

      store.commit("setRiskUserSettings", res.data.Data);
      return res.data.Data;
    },
    async addUpdateRiskUserSettings(store, payload) {
      var res = await axios.post("/riskUserSettings/AddOrUpdate", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async deleteRiskUserSettings(store, payload) {
      var res = await axios.post("/riskUserSettings/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.state.riskUserSettings.Items = store.state.riskUserSettings.Items.filter(x => x.ID != payload.Id);
      return res.data.Data;
    },
    async getRiskUserSettingsData(store, payload) {
      var res = await axios.post("/riskUserSettings/GetData", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      var index = store.state.riskUserSettings.Items.findIndex(x => x.ID == payload.Id);
      if(index != -1)
      {
        store.state.riskUserSettings.Items[index].Data = res.data.Data;
      }

      return res.data.Data;
    },
  }
}

export default store;