<template>
       <div class="card p-7 mb-5 bg-blue"  v-if="(organization.Industry == null || organization.Expectation == null) && $isInRole('Owner,Coowner')">
            <div class="card-header">
              <div class="card-title">
                <h3>Missing organization information</h3>
              </div>
            </div>

            <ul class="unordered-list mb-0 mt-3">
              <li v-if="organization.Industry == null">Please specify your organization's industry by  <router-link :to="{ name: 'settings' }">clicking here</router-link>.</li>

              <li v-if="organization.Expectation == null">Please specify your expectation from Worktivity by  <router-link :to="{ name: 'settings' }">clicking here</router-link>.</li>

            </ul>
        </div>
</template>

<script>

export default {
    name: 'MissingOrganizationInfo',
    data(){
        return {
          
        }
    },
    mounted() {
      
    },
    methods: {
      
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
    }
}
</script>
