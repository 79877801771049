<template>
    <div class="login-page">
        
        <div class="card login-card shadow-md">
            <div class="login-hero">
                <div class="login-text">
                    <h1>Login</h1>
                    <p>Are you already Worktivity customer? Login to your account.</p>
                </div>
            </div>

            <div class="login-form">
                <div class="form-header">
                    <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="dark-logo"/>
                    <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="white-logo"/>
                    <h3>Welcome Back</h3>
                    <p>Fill your email and password to sign in.</p>
                </div>
                <el-form :label-position="'top'" label-width="100px" :model="formData"  @keyup.enter.native="signIn">
                    <el-form-item label="Your e-mail address">
                        <el-input v-model="formData.Email" type="email" placeholder="someone@example.com"  clearable></el-input>
                        <ValidationErrorItem validationKey="Email" />
                    </el-form-item>
                    <el-form-item label="Your password">
                        <el-input v-model="formData.Password" type="password" placeholder="******" show-password ></el-input>
                        <ValidationErrorItem validationKey="Password" />
                    </el-form-item>

                    <el-form-item class="text-center" v-if="validateMe" style="min-height:73px">
                        <cfturnstile
                        sitekey="0x4AAAAAAAHfPtMmKhNgJWQa"
                        @verify="verify"
                        />
                        <ValidationErrorItem validationKey="CaptchaToken" />
                    </el-form-item>

                    <el-form-item class="login-button">
                        <el-button :loading="loading == 'signin'" type="primary" round @click="signIn">Login</el-button>
                    </el-form-item>
                </el-form>

                <div class="forgot-button">
                    <el-button type="text" @click="$router.push({ name: 'forgotpassword' })">Forgot Password?</el-button>
                </div>
                <div class="seperator">
                    <span>or</span>
                </div>
                <div class="signup-button">
                    <span> Don't have an account? <el-button type="text" @click="$router.push({ name: 'register' })">Sign up</el-button> </span>
                </div>

                <div class="d-flex justify-content-center">
                    <label for="theme" class="theme">
                        <span class="theme__toggle-wrap">
                            <input type="checkbox" class="theme__toggle" id="theme" role="switch" v-model="$store.state.darkMode" name="theme" value="dark" />
                            <span class="theme__icon">
                            <span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span>
                            </span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Turnstile from 'cfturnstile-vue2'

export default {
    name: 'LoginIndex',
    components: {
        'cfturnstile': Turnstile
    },
    data(){
        return {
            meta: {
                title: 'Login to your account',
                desc: 'Are you already Worktivity customer? Login to your account.'
            },
            loading:null,
            validateMe:true,
            formData: {
                Email: null,
                Password: null,
                CaptchaToken:null
            }
        }
    },

    mounted() {
        this.$setMeta(this.meta);

        if(this.$route.query.live){
            this.formData.Email = "demo@useworktivity.com";
            this.formData.Password = "worktivity2023!!";
            this.signIn();
        }
    },
    
    methods: {
        verify(token) {
            this.formData.CaptchaToken = token;
        },
        async signIn() {
            this.loading = 'signin';
            var res = await this.$store.dispatch("signIn",this.formData);
            this.loading = null;

            if(res == null)
            {
                this.validateMe = false;
                setTimeout(() => {
                    this.validateMe = true;
                },100);

                return;
            }

            var account = await this.$store.dispatch("getAccount");

            this.$gtag.event('login', {
                'method': 'Email',
            });

            if(account.CurrentOrganization == null)
            {
                this.$router.push({name: 'create-organization'});
            }
            else
            {
                this.$router.push({name: 'my-activities'});
            }
            
            
        }
    }
}
</script>
