<template>
      <el-select v-model="id" :clearable="clearable" filterable placeholder="Select a currency..." @change="selectionChanged" @clear="clearSelection">
        <el-option
          v-for="item in statuses"
          :key="item.Key"
          :label="item.DisplayName"
          :value="item.Key">
        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'CurrencySelectbox',
    props: ["selectedId", "nullable"],
    data(){
        return {
          id:null,
          clearable:false
        }
    },
    mounted() {
      this.id = this.selectedId;
      if(this.nullable)
      {
        this.clearable = this.nullable
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
    },
    computed: {
      statuses() {
        return this.$getEnums('CurrencyType');
      }
    }
}
</script>
