<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="card p-5 mb-10">
         <div class="card-header hr">
          <div class="card-title">
            <h3>Overview</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">
          <div class="overview-stats affiliate-stats">
            <div class="stats-item purple">
              <div class="icon-badge"><i class="fas fa-chart-line"></i></div>
              <div class="stats-count stats-count-referral">
                <span v-if="affiliateOverview">{{ affiliateOverview.Visited }}</span>
                <span v-else><i class="fa fa-spinner fa-spin"></i></span>
              </div>
                <el-tooltip placement="top" content="If someone visits your link 2 times in 10 minutes, it counts as 1.">
                  <p>Visitors</p>
                </el-tooltip>
            </div>
            <div class="stats-item blue">
              <div class="icon-badge"><i class="fas fa-user-friends"></i></div>
              <div class="stats-count stats-count-referral">
                <span v-if="affiliateOverview">{{ affiliateOverview.Registered }}</span>
                <span v-else><i class="fa fa-spinner fa-spin"></i></span>
              </div>
              <p>Registered users</p>
            </div>

            <div class="stats-item yellow">
              <div class="icon-badge"><i class="fas fa-credit-card"></i></div>
              <div class="stats-count stats-count-referral">
                <span v-if="affiliateOverview">{{ affiliateOverview.Subscribed }}</span>
                <span v-else><i class="fa fa-spinner fa-spin"></i></span>
              </div>
              <p>Subscribed users</p>
            </div>

            <div class="stats-item green">
              <div class="icon-badge"><i class="fas fa-lira-sign"></i></div>
              <div class="stats-count stats-count-referral">
                <span v-if="affiliateOverview">₺{{ affiliateOverview.Earnings.toFixed(2) }}</span>
                <span v-else><i class="fa fa-spinner fa-spin"></i></span>
              </div>
              <p>Earnings to be paid</p>
            </div>
        </div>


        <template v-if="affiliateOverview">
          <el-tooltip class="item" effect="dark" content="Click to copy your link" placement="top" v-if="affiliateOverview.Joined">
          <div class="card bg-green p-5 mt-5 cursor-pointer" @click="$copyToClipboard(affiliateOverview.Link)">
            <div class="card-alert">
              <div style="width:100%;">
                <p class="mb-2 d-flex justify-content-between"><b>Your referral link</b> <el-link type="primary" href="https://drive.google.com/drive/folders/1d5uxI2ePwuPBR5hZrdmD8aIeEE78QKNu?usp=drive_link" target="_blank">Show marketing materials</el-link></p>
                <div style="display:flex;">
                  <el-input v-model="affiliateOverview.Link" :readonly="true"></el-input>
                </div>
                <p class="mt-2">Copy this link to refer the user and earn a commission for a lifetime!</p>
              </div>
            </div>
          </div>
        </el-tooltip>

        <el-tooltip class="item" effect="dark" content="Click to join referral program" placement="top" v-else>
          <div class="card bg-green p-5 mt-5">
            <div class="card-alert">
              <div style="width:100%;">
                <p class="mb-2"><b>It's totally free!</b></p>
                <p class="mt-2">You will get a <b>30% LIFETIME (recurring) commission</b> from each payment made by a referred user who registers and then subscribes through the link with your unique affiliate ID.</p>
                <el-button type="success" class="text-white mt-5 join-ref-btn" :loading="loading == 'join'" @click="joinReferral">CLICK TO GET YOUR LINK</el-button>
              </div>
            </div>
          </div>
        </el-tooltip>


        <div v-if="affiliateOverview.Joined" class="d-flex justify-content-center mt-5">
          <el-tooltip placement="top" :content="affiliateOverview.Earnings < 100 ? 'You cannot create a payment request before you reach the $100 threshold.' : 'Click to request a payout.'">
            <el-link type="primary" :href="'mailto:support@useworktivity.com?Subject=Request a payout&Body=I am reaching out to you for a payment request RefId: '  + affiliateOverview.AffiliatePartner.RefCode + ' - Earnings: $' + affiliateOverview.Earnings.toFixed(2)" target="_blank" :disabled="affiliateOverview.Earnings < 100">Request a payout</el-link>
          </el-tooltip>
        </div>
        </template>
        <template v-else>
          <el-skeleton :rows="12" animated />
        </template>

        </div>
      </div>

      <div class="card p-5 mb-10">
         <div class="card-header hr">
          <div class="card-title w-100">
            <h3 class="d-flex w-100 justify-content-between"><span>F.A.Q</span> <el-link type="primary" href="https://useworktivity.com/affiliate-program" target="_blank">more</el-link></h3>
          </div>
        </div>
        
        <el-collapse v-model="activeNames" class="affiliate-faq">
        <el-collapse-item :title="item.Title" :name="item.ID" v-for="item in faqs" :key="item.ID">
          <div v-html="item.Details"></div>
        </el-collapse-item>
      </el-collapse>

        </div>

  </div>
</template>

<script>

import axios from "axios"
import Breadcrumb from '../partials/Breadcrumb';
import moment from "moment/min/moment-with-locales";

export default {
    name: 'AffiliateIndex',
    components: {
      Breadcrumb
    },

    data(){
      return {
        uploadInProgress:false,
        activeNames:["0"],
        moment,
        axios,
        meta: {
          title: 'Affiliate - Referral program',
          desc:'Join to Worktivity\'s referral program and earn up to %30 for your all sales recursively!'
        },
        loading:null,
        showPage: 0,
        refLink:'https://useworktivity.com',
        deleteAccountModal:false,
        faqs:[
          {
            ID:"0",
            Title:"What is the commission rate?",
            Details: "<p>You will get a <b>30% LIFETIME (recurring)</b> commission from each payment made by a referred user who registers and then subscribes through the link with your unique affiliate ID.</p>"
          },
          {
            ID:"1",
            Title:"Are there and pre-requirement to beign an affiliate?",
            Details: "<p>No, we allow all to apply to be a Worktivity Affiliate Partner</p>"
          },
          {
            ID:"2",
            Title:"Do you accept affiliates from all different countries?",
            Details: "<p>Yes, as long as you have a bank account and a SWIFT code to receive payments, you can apply from all countries.</p>"
          },
          {
            ID:"3",
            Title:"I am a Worktivity user, is there any additional benefit for me in the affiliate program?",
            Details: "<p>Yes, we offer special promotions and discounts for our partners based on specific conditions!</p>"
          },
          
          {
            ID:"4",
            Title:"How do you track referrals?",
            Details: "<p>We track referrals through IP and Cookie tracking, following industry standards.</p><p>If someone visits the Worktivity website through your link, creates an account, and makes a purchase, you will receive a commission for that purchase.</p>"
          },
          {
            ID:"5",
            Title:"What happens if a customer comes from two different referral links?",
            Details: "<p>The last clicked referral link is considered valid, and the relevant commission will be attributed to that person.</p><p>It follows the 'last click wins' principle.</p>"
          },
          {
            ID:"6",
            Title:"How long is the cookie duration?",
            Details: "<p>It's indefinite! Even if a customer comes through your link and makes a purchase much later, you will continue to earn commissions.</p>"
          },
          {
            ID:"7",
            Title:"What should I do if the commission for the sale I referred didn't track?",
            Details: "<p>If you have information confirming that the sale came from your referred customer, we will manually review the transaction and credit the relevant commission to your account.</p>"
          },

          {
            ID:"8",
            Title:"How do I receive my payments?",
            Details: "<p>Commissions accumulated from each sale and renewal will automatically appear on your partner dashboard.</p><p>Once you surpass the minimum threshold of $100 in commissions, you can request payment at any time for your finalized commissions.</p><p>Upon receiving a payment request, we will send your payment to your bank account within 5 business days.</p>"
          },
          {
            ID:"9",
            Title:"When can I receive the commission for today's sale?",
            Details: "<p>You can request payment for the commission that is confirmed 15 days later.</p>"
          },
          {
            ID:"10",
            Title:"Can I use your logo and materials on my website?",
            Details: "<p>Yes, you can use the Worktivity brand and logo on your website as long as you don't violate the usage terms and you don't make any alterations to the logo.</p><p>Additionally, you should clearly indicate on your website that you are a Worktivity partner.</p>"
          },
          {
            ID:"11",
            Title:"Are there any restricted affiliate activities?",
            Details: "<p>As we want to keep our Affiliate Program fair, there are some restricted activities:</p>" +
            "<p>* Coupon sites</p>" +
            "<p>* PPC advertising with a direct link leading to any UptimeRobot website (including all the tools and landing pages)</p>" +
            "<p>* Using any Worktivity brand, logo or name of the tools or their misspelled versions in PPC advertising, in domains, subdomains, or in profiles on social media</p>" +
            "<p>* Using misleading or incorrect information (non-existent discounts, etc.)</p>" +
            "<p>* Using discount coupons that are not assigned to you</p>" +
            "<p>* The so-called 'self-referrals' (when you create another account while referring yourself)</p>" +
            "<p>* The restricted activities will lead to the disapproval of your affiliate commissions or the termination of the partnership. For more information, check the <a href=\"https://useworktivity.com/legal/terms-conditions\" target=\"_blank\">Terms & Conditions</a>.</p>" +

            ""
          },
        ]
      }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.getData();
    },
    methods: {
      requestPayout() {

      },
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("affiliateOverview", {});
        this.loading = null;
      },
      async joinReferral() {
        this.loading = 'join';
        var res = await this.$store.dispatch("joinAffiliate", {});
        if(res != null)
        {

        }
        this.loading = null;
      }
    },
    computed: {
      affiliateOverview() {
            return this.$store.getters.getAffiliateOverview;
      },
      account() {
            return this.$store.getters.getAccount.User;
      },
    }
}
</script>
