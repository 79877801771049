import axios from "axios";

const store = {
  state: () => ({
    screenshots:null,
  }),
  getters: {
    getScreenshots(state) {
      return state.screenshots;
    }
  },
  mutations: {
    setScreenshots(state, payload) {
      state.screenshots = payload;
    }
  },
  actions: {
    async screenshots(store, payload) {
      var res = await axios.post("/screenshots/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setScreenshots", res.data.Data);
      return res.data.Data;
    },
    async deleteScreenshot(store, payload) {
      var res = await axios.post("/screenshots/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.state.screenshots.Items = store.state.screenshots.Items.filter(x => x.ID != payload.Id);
      return res.data.Data;
    },
    async deleteScreenshots(store, payload) {
      var res = await axios.post("/screenshots/deleteMultiple", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.state.screenshots.Items = store.state.screenshots.Items.filter(x => !payload.Ids.some(y => y == x));
      return res.data.Data;
    },
    async downloadScreenshots(store, payload) {
      var res = await axios.post('/screenshots/downloadAll', payload, {
        responseType: 'blob', // Yanıt türünü belirle (binary olarak almak için)
      });

      if(res == null)
      {
        return null;
      }

      const blob = new Blob([res.data], { type: 'application/zip' });

      // Dosyayı indirme
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'images.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return res.data;
    }
  }
}

export default store;