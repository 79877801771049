<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item">
          <SearchInput :initialValue="filter.SearchTerm" @changed="(term) => filter.SearchTerm = term" />
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => { getData(); }"
          :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5" v-if="!projects">
      <el-skeleton :rows="12" animated />
    </div>
    <template v-if="projects">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{ projects.TotalCount }} projects(s) here.</h3>
          </div>

          <div class="card-actions">
            <el-button type="primary" icon="fas fa-user-friends" @click="addProject" round>Add new project</el-button>
          </div>
        </div>

        <div class="activity-table">
          <NoData v-if="projects.Items.length == 0" />
          <el-table :data="projects.Items" v-if="projects.Items.length > 0" @expand-change="expandChange" ref="tab"
            @row-click="expand">

            <el-table-column label="Project name">
              <template slot-scope="scope">
                <div class="d-flex gap-1 align-items-center">
                  <i class="fas fa-circle" :style="'color: ' + scope.row.Color"></i>
                  <div class="app-title">
                    <span class="d-flex flex-column">
                      {{ scope.row.Title }}
                      <p class="d-flex align-items-center gap-03" v-if="scope.row.Integration != null">
                        Connected with <b>{{ $getEnumTitle('ProjectIntegration', scope.row.Integration) }}</b>
                        <img class="integration-icon" src="@/assets/img/integrations/asana_icon.png"
                          v-if="scope.row.Integration == 0" />
                        <img class="integration-icon" src="@/assets/img/integrations/clickup_icon.png"
                          v-if="scope.row.Integration == 1" />
                        <img class="integration-icon" src="@/assets/img/integrations/monday_icon.png"
                          v-if="scope.row.Integration == 2" />
                        <img class="integration-icon" src="@/assets/img/integrations/jira_icon.png"
                          v-if="scope.row.Integration == 3" />
                        <img class="integration-icon" src="@/assets/img/integrations/trello_icon.png"
                          v-if="scope.row.Integration == 4" />
                      </p>
                    </span>
                  </div>

                </div>

              </template>
            </el-table-column>

            <el-table-column type="expand">
              <template slot-scope="props">

                <div class="overview-stats">

                  <div class="stats-item purple" v-loading="!projectDetail.some(x => x.ID == props.row.ID)">
                    <span v-if="projectDetail.some(x => x.ID == props.row.ID)">{{ $parseTime(projectDetail.find(x => x.ID == props.row.ID).SpentMins) }}</span>
                    <span v-else>...</span>
                    <p>Time spent</p>
                  </div>

                  <div class="stats-item purple" v-loading="!projectDetail.some(x => x.ID == props.row.ID)"
                    v-if="trackSettings.EnableCostManagement">
                    <span v-if="projectDetail.some(x => x.ID == props.row.ID)">
                      <template v-if="projectDetail.find(x => x.ID == props.row.ID).TotalBudget > 0">
                        {{ $formatMoney(projectDetail.find(x => x.ID == props.row.ID).TotalBudget, $getEnumTitle('CurrencyType',
      organization.TrackSettings.CostManagementCurrency)) }}
                      </template>
                      <template v-else>
                        <i class="fas fa-infinity"></i>
                      </template>
                    </span>
                    <span v-else>...</span>
                    <p>Project budget</p>
                  </div>

                  <div class="stats-item purple" v-loading="!projectDetail.some(x => x.ID == props.row.ID)"
                    v-if="trackSettings.EnableCostManagement">
                    <span v-if="projectDetail.some(x => x.ID == props.row.ID)">
                      {{ $formatMoney(projectDetail.find(x => x.ID == props.row.ID).TotalCost, $getEnumTitle('CurrencyType',
      organization.TrackSettings.CostManagementCurrency)) }}
                    </span>
                    <span v-else>...</span>
                    <p>Current cost</p>
                  </div>

                  <!-- <div class="stats-item purple" v-loading="!projectDetail" v-if="trackSettings.EnableCostManagement">
                      <el-progress type="circle" :percentage="$percentage(759, projectDetail.TotalBudget)" v-if="projectDetail"></el-progress>
                      <el-progress type="circle" :percentage="$percentage(0, 100)" v-else></el-progress>
                      <p>Current progress</p>
                  </div> -->

                  <div class="stats-item purple" v-loading="!projectDetail.some(x => x.ID == props.row.ID)"
                    v-if="trackSettings.EnableCostManagement">
                    <span v-if="projectDetail.some(x => x.ID == props.row.ID)">{{ $formatMoney(projectDetail.find(x => x.ID == props.row.ID).TotalBillableAmount,
      $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}</span>
                    <span v-else>...</span>
                    <p>Billable amount</p>
                  </div>


                </div>


                <p class="mt-2">Notes: {{ props.row.Notes }}</p>
              </template>
            </el-table-column>


            <el-table-column label="Customer" width="250">
              <template slot-scope="scope">
                <div class="app-title">
                  <span v-if="scope.row.Customer != null">{{ scope.row.Customer.Name }}
                    <pre>{{ scope.row.Customer.Surname }}</pre>
                    <b v-if="scope.row.Customer.Email != null"><a :href="'mailto:' + scope.row.Customer.Email">{{
      scope.row.Customer.Email }}</a></b>
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Teams" width="150">
              <template slot-scope="scope">

                <p class="badge" v-if="scope.row.TeamIds.length != 0"><b>{{ scope.row.TeamIds.length }}</b> team(s)</p>
                <p v-if="scope.row.TeamIds.length == 0" class="badge">All teams</p>
              </template>
            </el-table-column>

            <el-table-column label="Employees" width="150">
              <template slot-scope="scope">

                <p class="badge" v-if="scope.row.EmployeeIds.length != 0"><b>{{ scope.row.EmployeeIds.length }}</b>
                  employee(s)</p>
                <p v-if="scope.row.EmployeeIds.length == 0" class="badge">All employees</p>
              </template>
            </el-table-column>




            <el-table-column width="150">
              <template slot-scope="scope">
                <el-row>
                  <el-tooltip class="item" effect="dark" content="Integrations" placement="top"
                    v-if="scope.row.Integration == null">
                    <el-dropdown class="mr-3">
                      <el-button type="warning" icon="fas fa-project-diagram" size="small" @click.stop
                        circle></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="d-flex align-items-center gap-05"
                          @click.native="connectAsana(scope.row.ID)">
                          <img class="integration-icon" src="@/assets/img/integrations/asana_icon.png" />
                          Connect with Asana
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05"
                          @click.native="connectTrello(scope.row.ID)">
                          <img class="integration-icon" src="@/assets/img/integrations/trello_icon.png" />
                          Connect with Trello
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" disabled>
                          <img class="integration-icon" src="@/assets/img/integrations/clickup_icon.png" />
                          Connect with Clickup
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" disabled>
                          <img class="integration-icon" src="@/assets/img/integrations/monday_icon.png" />
                          Connect with Monday
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" disabled>
                          <img class="integration-icon" src="@/assets/img/integrations/jira_icon.png" />
                          Connect with Jira
                        </el-dropdown-item>



                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Connected" placement="top"
                    v-if="scope.row.Integration != null">
                    <el-dropdown class="mr-3">
                      <el-button type="success" icon="fas fa-project-diagram" size="small" @click.stop
                        circle></el-button>
                      <el-dropdown-menu slot="dropdown">

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="syncTasks(scope.row)"
                          :disabled="loading == 'sync-tasks-' + scope.row.ID">
                          <i class="fa fa-sync"></i>
                          Sync tasks
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" divider
                          @click.native="cancelIntegration(scope.row)"
                          :disabled="loading == 'cancel-integration-' + scope.row.ID">
                          <i class="fa fa-exclamation-circle text-danger"></i>
                          Cancel integration
                        </el-dropdown-item>

                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Edit project" placement="top">
                    <el-button type="info" icon="fas fa-pen" size="small" circle
                      @click.stop="updateProject(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark"
                    :content="$isInRole('Manager') && organization.TrackSettings.AllowManagersToDeleteProjects == false ? 'You don\'t have permission to delete a project' : 'Delete project'"
                    placement="top">

                    <el-button
                      :disabled="$isInRole('Manager') && organization.TrackSettings.AllowManagersToDeleteProjects == false"
                      type="danger" icon="fas fa-trash" size="small" circle @click.stop="deleteProject(scope.row)"
                      :loading="loading == 'delete-' + scope.row.ID"></el-button>


                  </el-tooltip>

                </el-row>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" :title="projectModalTitle"
      :visible.sync="projectAddUpdateModal" center modal-append-to-body destroy-on-close>
      <ProjectsAddUpdateModal @closeDialog="projectAddUpdateModal = false" :data="addUpdateProject"
        v-if="projectAddUpdateModal" />
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Select project from Asana" :visible.sync="asanaModal"
      center modal-append-to-body destroy-on-close>

      <div class="custom-modal">
        <div class="modal-body">


          <div v-if="asanaProjects == null && asanaWorkspaces != null">

            <h3 class="mb-2">Workspaces</h3>
            <div class="integration-item-select" v-for="item in asanaWorkspaces.data" :key="item.gid">
              <p class="name">{{ item.name }}

                <el-button size="mini" round type="primary" @click="getAsanaProjects(item.gid)"
                  :loading="loading == item.gid">Get projects</el-button>
              </p>
            </div>
          </div>

          <div v-if="asanaProjects != null">

            <p class="cursor-pointer mb-3" @click="asanaProjects = null"><i class="fa fa-angle left"></i> Go back</p>

            <div class="integration-item-select" v-for="item in asanaProjects.data" :key="item.gid">
              <p class="name">
                <span><b>{{ item.name }}</b> <span v-if="item.team != null">in {{ item.team.name }}</span></span>
                <el-button size="mini" round type="primary" @click="saveAsanaProject(item.gid)"
                  :loading="loading == item.gid">Select</el-button>
              </p>
            </div>
          </div>


        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="asanaModal = false">Cancel</el-button>
          </div>
        </div>
      </div>

    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Select project from Asana"
      :visible.sync="clickupModal" center modal-append-to-body destroy-on-close>

      <div class="custom-modal">
        <div class="modal-body">


          <div v-if="clickupProjects == null && clickupWorkspaces != null">

            <h3 class="mb-2">Workspaces</h3>
            <div class="integration-item-select" v-for="item in clickupWorkspaces.teams" :key="item.id">
              <p class="name">{{ item.name }}

                <el-button size="mini" round type="primary" @click="getClickupProjects(item.id)"
                  :loading="loading == item.id">Get projects</el-button>
              </p>
            </div>
          </div>

          <div v-if="clickupProjects != null">

            <p class="cursor-pointer mb-3" @click="clickupProjects = null"><i class="fa fa-angle left"></i> Go back</p>

            <div class="integration-item-select" v-for="item in clickupProjects.data" :key="item.id">
              <p class="name">
                <span><b>{{ item.name }}</b> <span v-if="item.team != null">in {{ item.team.name }}</span></span>
                <el-button size="mini" round type="primary" @click="saveClickupProject(item.id)"
                  :loading="loading == item.id">Select</el-button>
              </p>
            </div>
          </div>


        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="clickupModal = false">Cancel</el-button>
          </div>
        </div>
      </div>

    </el-dialog>




    <el-dialog :close-on-click-modal="false" class="medium" title="Select board from Trello" :visible.sync="trelloModal"
      center modal-append-to-body destroy-on-close>

      <div class="custom-modal">
        <div class="modal-body">

          <div v-if="trelloLists == null && trelloBoards != null">

            <h3 class="mb-2">Boards</h3>
            <div class="integration-item-select" v-for="item in trelloBoards" :key="item.id">
              <p class="name">{{ item.name }}

                <!-- <el-button size="mini" round type="primary" @click="getTrelloLists(item.id)" :loading="loading == item.id">Get lists</el-button> -->

                <el-button size="mini" round type="primary" @click="saveTrelloBoard(item.id)"
                  :loading="loading == item.id">Select</el-button>
              </p>
            </div>
          </div>

          <div v-if="trelloLists != null">

            <p class="cursor-pointer mb-3" @click="trelloLists = null"><i class="fa fa-angle left"></i> Go back</p>

            <div class="integration-item-select" v-for="item in trelloLists" :key="item.id">
              <p class="name">
                <span><b>{{ item.name }}</b></span>
                <el-button size="mini" round type="primary" @click="saveTrelloProject(item.id)"
                  :loading="loading == item.id">Select</el-button>
              </p>
            </div>
          </div>


        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="clickupModal = false">Cancel</el-button>
          </div>
        </div>
      </div>

    </el-dialog>




  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import ProjectsAddUpdateModal from './ProjectsAddUpdateModal.vue';
import moment from "moment/min/moment-with-locales";
import axios from 'axios';
import SearchInput from '@/components/form/SearchInput.vue';

export default {
  name: 'ProjectsIndex',
  components: {
    Breadcrumb,
    ProjectsAddUpdateModal,
    SearchInput
  },
  data() {
    return {
      loading: null,
      moment,
      filter: {
        SearchTerm: null,
      },
      asana: {
        clientId: "1205216123454218",
        redirectUri: "https://api.useworktivity.com/Integration/AsanaCallback"
        //redirectUri: "http://localhost:5020/Integration/AsanaCallback"
      },
      trello: {
        apiKey: "f6435bc641f0a544222919dbb0f53855",
        //redirectUri: "http://localhost:8080/app/project-management/projects?trelloAuth=Trello"
        redirectUri: "https://app.useworktivity.com/app/project-management/projects?trelloAuth=Trello"
      },
      clickup: {
        clientId: "RS9FPHLY3LYXEO40EUD94F0CLOYIS56S",
        redirectUri: "http://localhost:5020/Integration/ClickupCallback"
      },
      meta: {
        title: 'Projects',
        desc: 'You can manage your projects here.'
      },
      projectAddUpdateModal: false,
      axiosCli: null,

      //Integration callback
      integrationProject: null,
      projectDetail:[],
      //Asana
      asanaWorkspaces: null,
      asanaModal: false,
      asanaProject: null,
      asanaProjects: null,

      //Clickup
      clickupWorkspaces: null,
      clickupModal: false,
      clickupProject: null,
      clickupProjects: null,


      trelloBoards: null,
      trelloLists: null,
      trelloModal: false,

      projectModalTitle: 'Add new project',
      addUpdateProject: {
        Id: null,
        Title: null,
        CustomerId: null,
        Color: "#8B4DFF",
        TeamIds: [],
        EmployeeIds: [],
        Notes: null,
        TotalBudget: 0
      }
    }
  },
  async mounted() {

    this.axiosCli = axios.create({
      baseURL: '',
      timeout: 10000,
      headers: {
        "content-type": "application/json",
      }
    });
    delete this.axiosCli.defaults.headers.common["utcOffset"];
    delete this.axiosCli.defaults.headers.common["timezone"];
    delete this.axiosCli.defaults.headers.common["requestSource"];

    this.$setMeta(this.meta);
    await this.$store.dispatch("getTeams");
    await this.$store.dispatch("getProjects");

    if (this.$route.query.integrated) {
      if (this.$route.query.integrated == "Asana") {
        this.asanaProject = this.projects.Items.find(x => x.ID == this.$route.query.projectId);

        const config = {
          headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + this.asanaProject.AccessToken,
          },
        };

        this.axiosCli.get('https://app.asana.com/api/1.0/workspaces?limit=100', config).then((res) => {
          this.asanaWorkspaces = res.data;
          this.asanaModal = true;
        });
      }
      if (this.$route.query.integrated == "Clickup") {
        this.clickupProject = this.projects.Items.find(x => x.ID == this.$route.query.projectId);

        const config = {
          headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + this.clickupProject.AccessToken,
          },
        };

        var clickupWorkspaceRes = await this.$store.dispatch("clickupWorkspaces", { Id: this.clickupProject.ID });
        this.clickupWorkspaces = JSON.parse(clickupWorkspaceRes);
        this.clickupModal = true;
      }
    }

    if (this.$route.query.trelloAuth) {
      var trelloProjectId = this.$route.query.trelloAuth.replace("Trello-", "");
      var token = window.location.href.split("#")[1].replace("token=", "");
      console.log(trelloProjectId, token);
      await this.$store.dispatch("saveTrelloToken", {
        ProjectId: trelloProjectId,
        Token: token
      });
      await this.$store.dispatch("getProjects");

      this.integrationProject = this.projects.Items.find(x => x.ID == trelloProjectId);
      console.log('https://api.trello.com/1/members/me/boards?fields=name,url&key=' + this.trello.apiKey + '&token=' + token);

      this.axiosCli.get('https://api.trello.com/1/members/me/boards?fields=name,url&key=' + this.trello.apiKey + '&token=' + token).then((res) => {
        this.trelloBoards = res.data;
        this.trelloModal = true;
      });



    }
  },
  methods: {
    async getData() {
      this.loading = 'getData';
      await this.$store.dispatch("getTeams");
      await this.$store.dispatch("getProjects");
      this.loading = null;
    },
    getTrelloLists(id) {
      this.loading = id;
      this.axiosCli.get('https://api.trello.com/1/boards/' + id + '/lists?&key=' + this.trello.apiKey + '&token=' + this.integrationProject.AccessToken).then((res) => {
        this.trelloLists = res.data;
        this.trelloModal = true;
        this.loading = null;
      });
    },
    saveTrelloProject() {

    },
    expandChange(row, expanded) {
      console.log("asdasd");
      if (this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID)) {
        this.$store.dispatch("getProjectDetail", { Id: row.ID, IncludeTeams: false });
      }
    },
    expand(row, isExpanded) {
      if (this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID)) {
        this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
      }
      else {
        this.$store.dispatch("getProjectDetail", { Id: row.ID, IncludeTeams: false }).then((data) => {
          this.projectDetail.push(data.Item);
        });
        this.$refs.tab.store.states.expandRows.push(row);
      }
    },
    async saveAsanaProject(gid) {
      //updateExternalProjectId
      this.loading = gid;
      var res = await this.$store.dispatch("updateExternalProjectId", {
        Id: this.asanaProject.ID,
        ExternalProjectId: gid
      });
      if (res != null) {
        await this.$store.dispatch("getProjects");
        this.asanaModal = false;
      }
    },
    async saveTrelloBoard(id) {
      this.loading = id;
      var res = await this.$store.dispatch("updateExternalProjectId", {
        Id: this.integrationProject.ID,
        ExternalProjectId: id
      });
      if (res != null) {
        await this.$store.dispatch("getProjects");
        this.trelloModal = false;
      }
    },
    getAsanaProjects(workspaceId) {
      this.loading = workspaceId;

      const config = {
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer " + this.asanaProject.AccessToken,
        },
      };

      this.axiosCli.get('https://app.asana.com/api/1.0/projects?&archived=false&limit=100&workspace=' + workspaceId + '&opt_fields=team,name,team.name', config).then((res) => {
        this.asanaProjects = res.data;
        this.loading = null;
      })
    },
    connectAsana(projectId) {

      var url = 'https://app.asana.com/-/oauth_authorize?response_type=code&client_id=' + this.asana.clientId + '&redirect_uri=' + this.asana.redirectUri + '&state=' + projectId;
      window.location.href = url;
    },
    connectTrello(projectId) {
      var url = "https://trello.com/1/authorize?expiration=never&name=Worktivity&author=Worktivity&scope=read&key=" + this.trello.apiKey + "&return_url=" + this.trello.redirectUri.replace("Trello", "Trello-" + projectId);
      window.location.href = url;
    },
    connectClickup(projectId) {
      var url = 'https://app.clickup.com/api?client_id=' + this.clickup.clientId + '&redirect_uri=' + this.clickup.redirectUri + '/' + projectId;
      window.location.href = url;
    },
    async getClickupProjects(id) {
      this.loading = id;

      var res = await this.$store.dispatch("clickupFolders", { Id: this.clickupProject.ID, WorkspaceId: id });
      this.clickupProjects = JSON.parse(res);

      this.loading = null;
    },
    getTeamsTitles(teams) {
      var result = "";
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        result += team.Title;
        if ((i + 1) < teams.length) {
          result += ", ";
        }
      }
      return result;
    },
    addProject() {
      this.projectModalTitle = 'Add new project';
      this.addUpdateProject = {
        Id: null,
        Title: null,
        CustomerId: null,
        Color: "#8B4DFF",
        TeamIds: [],
        EmployeeIds: [],
        Notes: null,
        TotalBudget: 0
      };
      this.projectAddUpdateModal = true;
    },
    updateProject(item) {
      this.projectModalTitle = 'Edit "' + item.Title + '"';
      this.addUpdateProject = {
        Id: item.ID,
        CustomerId: item.CustomerId,
        Title: item.Title,
        OrderNo: item.OrderNo,
        TeamIds: item.TeamIds,
        EmployeeIds: item.EmployeeIds,
        Color: item.Color,
        Notes: item.Notes,
        TotalBudget: item.TotalBudget,
      };
      this.projectAddUpdateModal = true;
    },
    async syncTasks(item) {
      this.loading = 'sync-tasks-' + item.ID;
      await this.$store.dispatch("syncTasks", { Id: item.ID });
      await this.$store.dispatch("getProjects");
      this.loading = null;
    },
    cancelIntegration(item) {
      this.loading = 'cancel-integration-' + item.ID;

      this.$confirm('You are about to cancel an integration. This action can not be undo, are you sure about this?', 'Warning', {
        confirmButtonText: 'Yes, cancel',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        await this.$store.dispatch("cancelIntegration", { Id: item.ID });
        await this.$store.dispatch("getProjects");
      }).catch(() => {

      });

      this.loading = null;
    },
    deleteProject(item) {
      this.loading = 'delete-' + item.ID;

      this.$confirm('You are about to delete a project. This action can not be undo, are you sure about this?', 'Warning', {
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        await this.$store.dispatch("deleteProject", { Id: item.ID });
        await this.$store.dispatch("getProjects");
      }).catch(() => {

      });

      this.loading = null;
    },
  },
  computed: {
    projects() {
      var temp = this.$store.getters.getProjects;
      if (!temp) {
        return temp;
      }
      var temp2 = JSON.parse(JSON.stringify(temp));

      if (this.filter.SearchTerm != null && this.filter.SearchTerm != "") {
        const searchRegex = new RegExp(this.$toSearchRegex(this.filter.SearchTerm));
        temp2.Items = temp2.Items.filter(x =>
          searchRegex.test(x.Title) ||
          (x.Customer != null ? searchRegex.test(x.Customer.Title) : true) ||
          (x.Customer != null ? searchRegex.test(x.Customer.Name) : true) ||
          (x.Customer != null ? searchRegex.test(x.Customer.Surname) : true) ||
          (x.Customer != null ? searchRegex.test(x.Customer.Name + ' ' + x.Customer.Surname) : true) ||
          (x.Customer != null ? searchRegex.test(x.Customer.Email) : true)
        );
        temp2.TotalCount = temp2.Items.length;
      }

      return temp2;
    },
    account() {
      return this.$store.getters.getAccount;
    },
    organization() {
      return this.account.CurrentOrganization;
    },
    trackSettings() {
      return this.organization.TrackSettings;
    },
  }
}
</script>
