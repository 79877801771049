<template>
      <el-select v-model="id" :clearable="clearable" filterable placeholder="Task status" @change="selectionChanged" @clear="clearSelection">
        <el-option
          v-for="item in statuses"
          :key="item.Key"
          :label="item.DisplayName"
          :value="item.Key">
          <div class="d-flex align-items-center">
            <span><i class="fa fa-circle mr-2" :class="'text-' + getStatusBg(item.Key)"></i> {{ item.DisplayName }}</span>
          </div>

        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'ProjectTaskStatusSelectbox',
    props: ["selectedId", "nullable"],
    data(){
        return {
          id:null,
          clearable:false
        }
    },
    mounted() {
      this.id = this.selectedId;
      if(this.nullable)
      {
        this.clearable = this.nullable
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
      getStatusBg(status) {
        switch(status)
        {
          case 0:
            return "blue";
            case 1:
            return "green";
            case 2:
            return "red";
              case 3:
            return "purple";
            case 4:
            return "orange";
            case 5:
            return "yellow";
            default:
              return"";
        }
      },
    },
    computed: {
      statuses() {
        return this.$getEnums('ProjectTaskStatus');
      }
    }
}
</script>
