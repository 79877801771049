<template>
    <el-date-picker
      v-model="dates"
      type="daterange"
      align="right"
      unlink-panels
      format="dd MMMM"
      range-separator="-"
      start-placeholder="..."
      end-placeholder="..."
      :clearable="clearable == true"
      @change="selectionChanged"
      :picker-options="pickerOptions">
    </el-date-picker>
</template>

<script>

export default {
    name: 'DateRangeFilterPicker',
    props: ["selected", "clearable"],
    data(){
        return {
          dates:null,
          pickerOptions: {
            firstDayOfWeek:1,
          shortcuts: [
            {
              text: 'Today',
              onClick(picker) {
                const start = new Date();
                start.setHours(0, 0, 0, 0);

                const end = new Date();
                end.setHours(0, 0, 0, 0);

                end.setTime(end.getTime() + (3600 * 1000 * 24) - 1);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: 'Yesterday',
              onClick(picker) {
                const start = new Date();
                start.setHours(0, 0, 0, 0);
                start.setTime(start.getTime() - (3600 * 1000 * 24));

                const end = new Date();
                end.setHours(0, 0, 0, 0);
                end.setTime(end.getTime() - (3600 * 1000 * 24));
                end.setTime(end.getTime() + (3600 * 1000 * 24) - 1);
                picker.$emit('pick', [start, end]);
              }
            },
            {
            text: 'This week',
              onClick(picker) {
                const today = new Date();
                const currentDay = today.getDay(); // Haftanın hangi günü olduğunu alır (Pazar: 0, Pazartesi: 1, ...)

                // Eğer bugün Pazartesi ise günü 0, değilse 1 çıkararak Pazartesi gününün tarihini buluruz
                const startDay = currentDay === 1 ? today : new Date(today.getFullYear(), today.getMonth(), today.getDate() - currentDay + 1);

                const startDate = new Date(startDay); // Haftanın başlangıç gününü kopyalar

                const endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6); // Bu haftanın bitiş gününe ayarlar

                endDate.setTime(endDate.getTime() + (3600 * 1000 * 24) - 1);

                picker.$emit('pick', [startDate, endDate]);
              }
            },
            {
            text: 'Last week',
            onClick(picker) {
              const today = new Date();
              const currentDay = today.getDay(); // Haftanın hangi günü olduğunu alır (Pazar: 0, Pazartesi: 1, ...)

              // Eğer bugün Pazartesi ise haftanın başlangıç tarihini 7 gün (1 hafta) önce alır,
              // aksi takdirde bu haftanın başlangıç gününden (Pazartesi) 7 gün çıkararak geçen haftanın başlangıç gününü bulur.
              const startDay = currentDay === 1 ? new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7) : new Date(today.getFullYear(), today.getMonth(), today.getDate() - currentDay + 1 - 7);

              const startDate = new Date(startDay); // Geçen haftanın başlangıç gününü kopyalar

              const endDate = new Date(startDate);
              endDate.setDate(startDate.getDate() + 6); // Geçen haftanın bitiş gününü ayarlar


              endDate.setTime(endDate.getTime() + (3600 * 1000 * 24) - 1);

              picker.$emit('pick', [startDate, endDate]);
            }
          },

             {
              text: 'This month',
              onClick(picker) {
                const today = new Date();
              const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Bu ayın başlangıç tarihi
              const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Bu ayın son gününün tarihi

              endOfMonth.setTime(endOfMonth.getTime() + (3600 * 1000 * 24) - 1);
              picker.$emit('pick', [startOfMonth, endOfMonth]);
              }
            },
            {
            text: 'Last month',
            onClick(picker) {
              const today = new Date();
              const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0); // Geçen ayın son günü
              const lastMonthFirstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Geçen ayın başlangıç tarihi


              lastMonthLastDay.setTime(lastMonthLastDay.getTime() + (3600 * 1000 * 24) - 1);



              picker.$emit('pick', [lastMonthFirstDay, lastMonthLastDay]);
            }
          },
            // {
            //   text: 'Last 7 days',
            //   onClick(picker) {
            //     const end = new Date();
            //     const start = new Date();
            //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            //     picker.$emit('pick', [start, end]);
            //   }
            // }, {
            //   text: 'Last 30 days',
            //   onClick(picker) {
            //     const end = new Date();
            //     const start = new Date();
            //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            //     picker.$emit('pick', [start, end]);
            //   }
            // }, {
            //   text: 'Last 3 months',
            //   onClick(picker) {
            //     const end = new Date();
            //     const start = new Date();
            //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            //     picker.$emit('pick', [start, end]);
            //   }
            // }
          ]
        },
        }
    },
    mounted() {
      this.dates = this.selected;
    },
    methods: {
      clearSelection() {
        this.dates = null;
        this.selectionChanged();
      },
      selectionChanged() {
        if(this.dates != null)
        {
          var parsedDates = JSON.parse(JSON.stringify(this.dates));
          var endDate = new Date(parsedDates[1]);
          endDate.setHours(0, 0, 0, 0);
          endDate.setTime(endDate.getTime() + (3600 * 1000 * 24) - 1);

          this.$emit("changed", {
            StartDate: this.dates[0],
            EndDate: endDate,
          });
        }
        else
        {
          this.$emit("changed", {
            StartDate: null,
            EndDate: null,
          });
        }
      }
    }
}
</script>
