import axios from "axios";

const store = {
  state: () => ({
    reviewed:null,
    unreviewed: null
  }),
  getters: {
    getReviewed(state) {
      return state.reviewed;
    },
    getUnreviewed(state) {
      return state.unreviewed;
    }
  },
  mutations: {
    setReviewed(state, payload) {
      state.reviewed = payload;
    },
    setUnreviewed(state, payload) {
      state.unreviewed = payload;
    }
  },
  actions: {
    async reviewed(store, payload) {
      var res = await axios.post("/ReviewApps/ListReviewed", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setReviewed", res.data.Data);
      return res.data.Data;
    },
    async unreviewed(store, payload) {
      var res = await axios.post("/ReviewApps/ListUnreviewed", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setUnreviewed", res.data.Data);
      return res.data.Data;
    },
    async changeStatus(store, payload) {
      var res = await axios.post("/ReviewApps/ChangeStatus", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      if(store.state.reviewed.Items.some(x => x.ID == payload.OrganizationAppId))
      {
        var index = store.state.reviewed.Items.findIndex(x => x.ID == payload.OrganizationAppId);
        store.state.reviewed.Items[index].Status = payload.Status;
       
      }

      if(store.state.unreviewed.Items.some(x => x.ID == payload.OrganizationAppId))
      {
        var index = store.state.unreviewed.Items.findIndex(x => x.ID == payload.OrganizationAppId);
        store.state.unreviewed.Items[index].Status = payload.Status;

         var item = store.state.unreviewed.Items[index];
        store.state.unreviewed.Items.splice(index, 1);

        store.state.reviewed.Items = [item, ...store.state.reviewed.Items];
        store.state.reviewed.TotalCount += 1;
        store.state.unreviewed.TotalCount -= 1;
      }

      return res.data.Data;
    }
  }
}

export default store;