
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">

        
     <div class="modal-body">
      <el-form :label-position="'top'" class-name="d-flex" style="justify-content: center !important; align-items: center !important; display: flex; flex-direction: column;" label-width="100px" @submit.native.prevent="false" @keyup.enter.native="save">


<el-row :gutter="20" style="max-width: 100%;" class="w-100">
  <el-col>
 <el-form-item label="Organization type" required>
   <el-radio-group v-model="organization.Invoice.Type">
     <el-radio :label="0">Individual</el-radio>
     <el-radio :label="1">Corporate</el-radio>
   </el-radio-group>
   <ValidationErrorItem validationKey="Invoice.Type" />
 </el-form-item>
</el-col>
</el-row>

 <el-row :gutter="20" class="w-100">
  <el-col>
   <el-form-item :label="organization.Invoice.Type == 0 ? 'Full name' : 'Commercial title'" required>
   <el-input v-model="organization.Invoice.CommercialTitleOrFullName" placeholder="Acme Inc." required clearable></el-input>
   <ValidationErrorItem validationKey="Invoice.CommercialTitleOrFullName" />
 </el-form-item>
 </el-col>
 </el-row>

 <el-row :gutter="20" class="w-100">
   <el-col :md="organization.Invoice.Type == 0 ? 24 : 12">
   <el-form-item :label="organization.Invoice.Type == 0 ? 'Identity number' : 'Tax number'" required>
   <el-input v-model="organization.Invoice.TaxNumberOrIdentity" placeholder="..." required clearable></el-input>
   <ValidationErrorItem validationKey="Invoice.TaxNumberOrIdentity" />
 </el-form-item>
 </el-col>

 <el-col :md="12" class="w-100">
   <el-form-item label="Tax office" v-if="organization.Invoice.Type == 1">
   <el-input v-model="organization.Invoice.TaxOffice" placeholder="..." required clearable></el-input>
   <ValidationErrorItem validationKey="Invoice.TaxOffice" />
 </el-form-item>
 </el-col>
 </el-row>


   <el-row :gutter="20" class="w-100">
     <el-col :md="12">
     <el-form-item label="Country" required>
       <el-input v-model="organization.Invoice.Country" placeholder="..." required clearable></el-input>
       <ValidationErrorItem validationKey="Invoice.Country" />
     </el-form-item>
   </el-col>

   <el-col :md="12" class="w-100">
     <el-form-item label="Zip code">
       <el-input v-model="organization.Invoice.ZipCode" placeholder="..." required clearable></el-input>
       <ValidationErrorItem validationKey="Invoice.ZipCode" />
     </el-form-item>
   </el-col>
   </el-row>

 <el-row :gutter="20" class="w-100">
  <el-col>
   <el-form-item label="Invoice address" required>
   <el-input v-model="organization.Invoice.Address" placeholder="..." required clearable></el-input>
   <ValidationErrorItem validationKey="Invoice.Address" />
 </el-form-item>
 </el-col>
 </el-row>
</el-form>
     </div>

    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          Save changes
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import MoneyInput from '@/components/form/MoneyInput.vue';

export default {
    name: 'UpdateInvoiceSettingsModal',
    components: {
        MoneyInput
    },
    data(){
        return { 
          loading:null,
        }   
    },
    async mounted() {
      
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("updateOrganizationInvoiceSettings", {
          Invoice: this.organization.Invoice
        });
        this.loading = null;
        if(res != null)
        {
          this.$emit('invoiceSaved');
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      account() {
            return this.$store.getters.getAccount;
      },
      organization() {
            return this.account.CurrentOrganization;
      },
      subscription() {
            return this.organization.Subscription;
      }
    },
    watch: {
      'organization.Invoice.Type'() {
        this.$store.commit('setValidationErrors', []);
      },
    }
}
</script>

<style>

</style>