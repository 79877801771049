
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body" v-if="data">
      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" v-loading="loading == 'init'">

         <div class="card bg-blue p-5 mb-3" v-if="data">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div class="w-100">
            <p class="d-flex justify-content-between mb-2">Invoice No: <b>{{ data.InvoiceNo }}</b></p>
            <p class="d-flex justify-content-between mb-2">Supplier: <b>{{ data.Supplier.Title }}</b></p>
            <p class="d-flex justify-content-between mb-2">Email: <b>{{ data.Supplier.Email ?? "..." }}</b></p>
            <p class="d-flex justify-content-between mb-2">Total: <b>{{ $formatMoney(data.Total, $getEnumTitle('CurrencyType', data.Currency )) }}</b></p>
            <p class="d-flex justify-content-between mb-2">Paid: <b>{{ $formatMoney(data.Paid, $getEnumTitle('CurrencyType', data.Currency )) }}</b></p>
            <p class="d-flex justify-content-between mb-2">Payment due: <b>{{ $formatMoney(priceMax, $getEnumTitle('CurrencyType', data.Currency )) }}</b></p>
          </div>
        </div>
      </div>

        <el-form-item>
          <FormLabelItem title="Amount" required />
          <MoneyInput :min="1" :max="priceMax" :fixed="2" v-model="formData.Price" clearable>
            <template slot="append">{{ $getEnumTitle('CurrencyType', data.Currency ) }}</template>
          </MoneyInput>
          <ValidationErrorItem validationKey="Price" />
        </el-form-item>

        <el-form-item label="Payment date" required>
          <el-date-picker
              v-model="formData.PaymentDate"
              type="datetime"
              format="dd.MM.yyyy HH:mm"
              placeholder="Select date and time">
            </el-date-picker>
          <ValidationErrorItem validationKey="PaymentDate" />
        </el-form-item>

        <el-form-item>
          <FormLabelItem title="Notes" tooltip="Your customer cannot see this note." />
          <el-input v-model="formData.Notes" type="textarea" :autosize="{ minRows: 2, maxRows: 5}" placeholder="...."></el-input>
          <ValidationErrorItem validationKey="Notes" />
        </el-form-item>

        <span class="cursor-pointer" @click="allPaid"><b>{{ $formatMoney(priceMax, $getEnumTitle('CurrencyType', data.Currency )) }}</b> payment due, click here to mark it as fully paid.</span>
      </el-form>
    </div>

    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save' || loading == 'init'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Create invoice' }}
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>

import MoneyInput from '@/components/form/MoneyInput.vue';
export default {
    name: 'InvoiceRecordPaymentModal',
    props: ["data"],
    components: {
      MoneyInput
    },
    data(){
        return { 
          loading:null,
          rederQuill:false,
          formData: {
            Id:null,
            Price:1,
            PaymentDate:null,
            Notes:null,
          }
        }   
    },
    async mounted() {
      this.loading = 'init';
      this.formData.Id = this.data.ID;
      this.loading = null;
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("customerInvoiceRecordPayment", this.formData);
        this.loading = null;
        if(res != null)
        {
          this.$emit('paymentRecordCreated');
          this.$emit('closeDialog');
        }
      },
      async allPaid() {
        this.formData.Price = this.priceMax;
      }
    },
    computed: {
      priceMax() {
        return (this.data.Total - this.data.Paid);
      }
    }
}
</script>