<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">
      
      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save">

        <el-form-item label="Employee" required v-if="$isInRole('Owner,Coowner,Manager')">
          <div class="filter-item">
            <EmployeeSelectbox placeholder="Select an employee..." :selectedId="formData.EmployeeId" @changed="(id) => formData.EmployeeId = id" :key="formData.EmployeeId" />
          </div>
          <ValidationErrorItem validationKey="EmployeeId" />
        </el-form-item>


       <template v-if="employeeLeaveRights != null">
        <el-form-item label="Leave type" required>
          <el-select v-model="formData.Type" placeholder="Select...">
            <el-option
                v-for="item in $getEnums('LeaveRightType')"
                :key="item.Key"
                :label="item.DisplayName + ' ' + '(' + employeeLeaveRights.Items.find(x => x.Type == item.Key).Count + ' days left)'"
                :value="item.Key">
                <span>
                  {{ item.DisplayName }} <span style="float:right">{{ '' + employeeLeaveRights.Items.find(x => x.Type == item.Key).Count + ' day(s) left' }}</span>
                </span>
              </el-option>
          </el-select>
          <ValidationErrorItem validationKey="Type" />
        </el-form-item>

        

        <el-form-item label="Day(s)" required>
          <el-input-number v-model="formData.Count" :min="0.5" :max="9999" :precision="1" :step="0.5" class="w-100" style="width:100%;"></el-input-number>
          <ValidationErrorItem validationKey="Reason" />
        </el-form-item>

        <el-form-item label="Notes">
          <el-input v-model="formData.Notes" type="textarea" rows="3" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="Notes" />
        </el-form-item>
       </template>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          Add leave right
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';

export default {
    name: 'LeaveRightAddUpdateModal',
    props: ["data"],
    components: {
      EmployeeSelectbox,
    },
    data(){
        return { 
          loading:null,
          duration:null,
          employeeLeaveRights:null,
          formData: {
            Id:null,
            EmployeeId:null,
            Type:null,
            Count:1,
            Notes:null,
          }
        }   
    },
    mounted() {
      if(this.data)
      {
        this.formData = this.data;
      }

      if(this.$isInRole('Employee'))
      {
        this.formData.EmployeeId = this.currentEmployeeId;
      }
    },
    methods: {
      async getEmployeeLeaveRight() {

        this.employeeLeaveRights = null;
        this.loading = 'employee-leave-rights';

        if(this.formData.EmployeeId != null)
        {
          this.employeeLeaveRights = await this.$store.dispatch("getEmployeeLeaveRight", {
            Id: this.formData.EmployeeId
          });
        }

        this.loading = null;
      },
      setReason(reason) {
        debugger;
        this.formData.Reason = reason;
      },
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("addUpdateLeaveRight", this.formData);
        this.loading = null;
         if(res != null)
        {
          this.$emit('leaveRightCreated');
          this.$emit('closeDialog');
        }
      },
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      trackSettings() {
        return this.organization.TrackSettings;
      },
      currentEmployeeId() {
        return this.account.Employings.Items.find(x => x.OrganizationId == this.organization.ID).ID;
      },
    },
    watch: {
      'formData.EmployeeId'() {
        this.formData.Type = null;
        this.getEmployeeLeaveRight();
      },

    }
}
</script>

<style>

</style>