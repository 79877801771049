
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save" >
        <el-form-item required>
          <FormLabelItem title="Title" required />
          <el-input v-model="formData.Title" placeholder="Idle than usual" required clearable :disabled="formData.User != null"></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Exclude this employee(s)">
          <MultipleEmployeeSelectbox :selectedId="formData.ExcludedEmployeeIds" @changed="(id) => formData.ExcludedEmployeeIds = id" :key="formData.ExcludedEmployeeIds.length" />
          <ValidationErrorItem validationKey="ExcludedEmployeeIds" />
        </el-form-item>


        <h4 class="d-flex justify-content-between">
          <span>Conditions</span>
          ({{ formData.Conditions.length }} condition)
        </h4>
        <div class="mt-2 risk-user-condition" v-for="(item,index) in formData.Conditions"  :key="'condition-' + index">

          <div class="d-flex justify-content-between" v-if="index > 0">
            <span><b>AND</b></span>
            <el-tooltip placement="top" content="Remove this condition">
              <span @click="removeCondition(index)"><i class="fas fa-trash text-danger cursor-pointer"></i></span>
            </el-tooltip>
          </div>
          <el-form-item required>
            <FormLabelItem title="Status" />
            <el-select v-model="formData.Conditions[index].Statuses" multiple="" filterable placeholder="Select...">
              <el-option
                v-for="item in $getEnums('EmployeeActivityLogStatus').filter(x => [1,2,3].some(y => y == x.Key))"
                :key="item.Key"
                :label="item.DisplayName"
                :value="item.Key">
              </el-option>
            </el-select>
            <ValidationErrorItem :validationKey="'Conditions[' + index + '].Statuses'" />
          </el-form-item>

          <el-form-item v-if="formData.Conditions[index].Statuses.some(x => x == 1)">
            <FormLabelItem title="Productivity" />
            <el-select v-model="formData.Conditions[index].ProductivityStatuses" multiple="" filterable placeholder="Select...">
              <el-option
                v-for="item in $getEnums('ProductivityStatus')"
                :key="item.Key"
                :label="item.DisplayName"
                :value="item.Key">
              </el-option>
            </el-select>
            <ValidationErrorItem :validationKey="'Conditions[' + index + '].ProductivityStatuses'" />
          </el-form-item>

          <el-form-item required>
            <FormLabelItem title="Comparer" required />
            <el-select v-model="formData.Conditions[index].Type" filterable placeholder="Select...">
              <el-option
                v-for="item in $getEnums('RiskUserConditionType')"
                :key="item.Key"
                :label="item.DisplayName"
                :value="item.Key">
              </el-option>
            </el-select>
            <ValidationErrorItem :validationKey="'Conditions[' + index + '].Type'" />
          </el-form-item>

          <el-form-item required>
            <FormLabelItem title="Duration (minutes)" required />
            <el-input-number style="width:100%;" type="number" :min="1" :max="(60 * 24 * 30)" v-model="formData.Conditions[index].ValueMinutes"></el-input-number>
            <ValidationErrorItem :validationKey="'Conditions[' + index + '].ValueMinutes'" />
          </el-form-item>

          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueMinutes = 60">1 hr</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueMinutes = (60 * 3)">3 hrs</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueMinutes = (60 * 8)">8 hrs</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueMinutes = (60 * 24)">1 d</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueMinutes = (60 * 24 * 3)">3 dys</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueMinutes = (60 * 24 * 30)">30 dys</span>


          <el-form-item required>
            <FormLabelItem title="In last (n) minutes" required />
            <el-input-number style="width:100%;" type="number" :min="1" :max="(60 * 24 * 30)" v-model="formData.Conditions[index].ValueDurationMinutes"></el-input-number>
            <ValidationErrorItem :validationKey="'Conditions[' + index + '].ValueDurationMinutes'" />
          </el-form-item>

          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueDurationMinutes = 60">1 hr</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueDurationMinutes = (60 * 3)">3 hrs</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueDurationMinutes = (60 * 8)">8 hrs</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueDurationMinutes = (60 * 24)">1d</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueDurationMinutes = (60 * 24 * 3)">3 dys</span>
          <span class="badge blue cursor-pointer ml-1" @click="formData.Conditions[index].ValueDurationMinutes = (60 * 24 * 30)">30 dys</span>
        </div>

        <div class="text-center mt-2 mb-2" v-if="formData.Conditions.length < 3">
          <el-button type="primary" size="mini" @click="addDefaultCondition" >Add condition +</el-button>
        </div>

        <div class="card bg-blue p-5 mb-2" v-if="formData && formData.Conditions && formData.Conditions.length > 0">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p>* You can view this report from the <b>Overview</b> screen.</p>
            <p>* {{ $getRiskUserTooltipText(formData).replace('You are viewing your', 'We will show your') }}</p>
          </div>
        </div>
      </div>


      </el-form>
    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Add' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import MultipleEmployeeSelectbox from '@/components/form/MultipleEmployeeSelectbox.vue';

export default {
    name: 'RiskUserSettingsAddOrUpdateModal',
    props: ["data"],
    components: {
      MultipleEmployeeSelectbox
    },
    data(){
        return { 
          loading:null,
          formData: {
            Id:null,
            Conditions: [],
            ExcludedEmployeeIds: []
          }
        }   
    },
    async mounted() {
      if(!this.employees)
      {
        await this.$store.dispatch("getEmployees");
      }

      if(this.data)
      {
        this.formData = this.data;
      }
      else
      {
        this.addDefaultCondition();
      }
    },
    methods: {
      removeCondition(index) {
        this.formData.Conditions.splice(index,1);
      },
      addDefaultCondition() {
        this.formData.Conditions.push({
          Statuses:[],
          ProductivityStatuses:[],
          Type:0,
          ValueMinutes:60,
          ValueDurationMinutes:120,
        });
      },
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("addUpdateRiskUserSettings", this.formData);
       
        this.loading = null;
         if(res != null)
        {
          this.$emit('closeDialog');
          this.$emit('riskUserSettingsUpdated');
        }
      }
    },
    computed: {
      employees() {
        return this.$store.getters.getEmployees;
      },
      enums() {
        return this.$store.getters.getEnums;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      }
    }
}
</script>