<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area mb-0">
      <div class="filter-options">

        <div class="filter-item">
          <SearchInput :initialValue="filter.SearchTerm" @changed="(term) => filter.SearchTerm = term" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager') && teams && employees && employees.Items && employeesReal.Items.some(x => x.Blocked == true)">
          <el-tooltip class="item" effect="dark" content="Toggle on to show blocked employees" placement="top">
            <el-switch v-model="filter.IncludeBlockedEmployees" active-text=""></el-switch>
          </el-tooltip>
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => { getData(); }"
          :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>


    <div class="activity-filter" v-if="teams && employees">
      <div class="filter-item purple" :class="activeTab == 'employees' ? 'isactive' : ''" @click="activeTab = 'employees'">
        <i class="fas fa-user-friends"></i>
        <span>Employees<b>{{ employees.TotalCount }}</b></span>
      </div>
      <div class="filter-item purple" :class="activeTab == 'teams' ? 'isactive' : ''" @click="activeTab = 'teams'">
        <i class="fas fa-layer-group"></i>
        <span>Teams<b>{{ teams.TotalCount }}</b></span>
      </div>
    </div>

    <div class="card p-5" v-if="!employees || !teams">
      <el-skeleton :rows="12" animated />
    </div>
    <template v-if="employees && teams">
      <template v-if="activeTab == 'employees'">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{ employees.TotalCount }} employee(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-user-friends" @click="addEmployee" round>Add new employee</el-button>
          </div>
        </div>

        <div class="activity-table">
          <el-table :data="employees.Items" :row-class-name="tableRowClassName">

            <el-table-column label="Employee">
              <template slot-scope="scope">

                <el-tooltip :disabled="!$isNotNullOrWhiteSpace(scope.row.EmployeeCode)" :content="scope.row.EmployeeCode" placement="left">
                <div class="app-title" v-if="scope.row.User != null">
                  <el-avatar v-if="scope.row.User.Image != null" :size="25" :src="scope.row.User.Image"></el-avatar>
                  <span :class="scope.row.Blocked ? 'text-red' : ''">
                    {{ scope.row.User.FirstName }} <pre>{{ scope.row.User.LastName }} </pre>
                    <b>{{ scope.row.User.Email }}</b>
                  </span>
                </div>
                <div class="app-title" v-else>
                  <el-avatar :size="25" src="https://img.imageus.dev/https://worktivity.s3.eu-central-1.amazonaws.com/default/user.jpg?width=160&height=160&mode=cover"></el-avatar>
                  <span :class="scope.row.Blocked ? 'text-red' : ''">
                    {{ scope.row.Invitation.FirstName }} <pre>{{ scope.row.Invitation.LastName }}</pre>
                    <b>{{ scope.row.Invitation.Email }}</b>
<el-tooltip class="item" effect="dark" :content="'Click to copy, link will be expire at ' + moment(scope.row.Invitation.ExpireDate).format('DD.MM.yyyy HH:mm')" placement="top" v-if="new Date(scope.row.Invitation.ExpireDate) > new Date()">
                    <span class="text-green" @click="$copyToClipboard('https://app.useworktivity.com/register?invitationCode=' + scope.row.Invitation.InvitationCode)"><i class="fas fa-copy"></i> Invited</span>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Invitation is expired, you can click to resend it." placement="top" v-else>
                    <el-button type="text" size="small" @click="resendInvitation(scope.row.ID)" :loading="loading == 'resend-invitation-' + scope.row.ID"><span class="text-red"><i class="fas fa-envelope"></i> Expired, click to resend</span></el-button>
                  </el-tooltip>
                  </span>
                </div>
            </el-tooltip>

              </template>
            </el-table-column>

            <el-table-column label="Pay rate" width="150" v-if="trackSettings.EnableCostManagement">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="'Bill rate: ' + scope.row.BillRate + ' ' + $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)" placement="top">
                    <span class="badge elipsis" :style="scope.row.PayRate == 0 ? 'opacity:.3' : ''">
                      {{ scope.row.PayRate }} {{ $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency) }}
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>

            <el-table-column label="Team" width="200">
              <template slot-scope="scope">
                <el-tooltip placement="left"  :disabled="scope.row.Team.Title.length < 20" :content="scope.row.Team.Title">
                  <span class="badge elipsis">
                  <i class="fas fa-circle" :style="'color: ' + scope.row.Team.Color"></i> {{ scope.row.Team.Title }}
                  </span>
               </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="Role" width="150">
              <template slot-scope="scope">
                <span class="badge" :class="$getRoleColor(scope.row.Role)">{{ $getEnumTitle('TypeOfAuthority', scope.row.Role) }}</span>
              </template>
            </el-table-column>

            <el-table-column label="App version" width="100">
              <template slot-scope="scope">
                <span class="badge" v-if="scope.row.User != null && scope.row.User.AppVersion  != null">{{ scope.row.User.AppVersion }}</span>
                <span class="badge" v-else>N/A</span>
              </template>
            </el-table-column>

            <el-table-column width="75">
              <template slot-scope="scope">
                <el-row>

                    <el-dropdown class="mr-3">
                      <el-button icon="fas fa-ellipsis-h" size="small" circle></el-button>
                      <el-dropdown-menu slot="dropdown">

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="updateCostSettings(scope.row)" v-if="trackSettings.EnableCostManagement">
                          <span>
                            <i class="fas fa-dollar-sign"></i>
                            Edit cost settings
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="updateTrackSettings(scope.row)">
                          <span>
                            <i :class="scope.row.EnableCustomTrackSettings ? 'fas fa-user-secret text-success' : 'fas fa-user-secret'"></i>
                            Edit track settings
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="updateEmployee(scope.row)" :disabled="scope.row.Role == 2">
                          <span>
                            <i class="fas fa-pen"></i>
                            Edit employee 
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="toggleBlock(scope.row)">
                          <span>
                            <i :class="scope.row.Blocked ? 'fas fa-lock text-danger' : 'fas fa-lock-open'"></i>
                            {{ scope.row.Blocked ? 'Unblock this employee' : 'Block this employee' }}
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05 text-red" @click.native="deleteEmployee(scope.row)" :disabled="scope.row.Role == 2">
                          <span>
                            <i class="fas fa-trash"></i>
                            Delete employee 
                          </span>
                        </el-dropdown-item>
                        


                      </el-dropdown-menu>
                    </el-dropdown>


                </el-row>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </template>

    <template v-if="activeTab == 'teams'">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{teams.TotalCount}} team(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-layer-group" @click="addteam" round>Add new team</el-button>
          </div>
        </div>

        <div class="activity-table">
          <el-table :data="teams.Items" :row-class-name="tableRowClassName">

            <el-table-column label="Team">
              <template slot-scope="scope">
                <p><i class="fas fa-circle" :style="'color: ' + scope.row.Color"></i> {{ scope.row.Title }}</p>
              </template>
            </el-table-column>

            <el-table-column label="Employees" width="100">
              <template slot-scope="scope">
                <span class="badge">{{ scope.row.EmployeeCount }}</span>
              </template>
            </el-table-column>

            <el-table-column width="100">
              <template slot-scope="scope">
                <el-row>

                  <el-tooltip class="item" effect="dark" content="Edit team" placement="top">
                    <el-button type="info" icon="fas fa-pen" size="small" circle @click="updateTeam(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Delete team" placement="top">
                    <el-button type="danger" icon="fas fa-trash" size="small" circle @click="deleteTeam(scope.row)"></el-button>
                  </el-tooltip>

                </el-row>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </template>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" :title="employeeModalTitle" :visible.sync="employeesAddOrUpdateModal" center modal-append-to-body destroy-on-close>
      <EmployeesAddOrUpdateModal @closeDialog="employeesAddOrUpdateModal = false" :data="addUpdateEmployee" v-if="employeesAddOrUpdateModal"/>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" :title="teamsAddOrUpdateModalTitle" :visible.sync="teamsAddOrUpdateModal" center modal-append-to-body destroy-on-close>
      <TeamsAddOrUpdateModal @closeDialog="teamsAddOrUpdateModal = false" :data="addUpdateTeam" v-if="teamsAddOrUpdateModal"/>
    </el-dialog>

     <el-dialog :close-on-click-modal="false" class="medium" :title="employeeDeleteModalTitle" :visible.sync="deleteEmployeeModal" center modal-append-to-body destroy-on-close>
      <DeleteEmployeeModal @closeDialog="deleteEmployeeModal = false" :employeeId="deleteEmployeeId" v-if="deleteEmployeeModal"/>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" :title="deleteTeamModalTitle" :visible.sync="deleteTeamModal" center modal-append-to-body destroy-on-close>
      <DeleteTeamModal @closeDialog="deleteTeamModal = false" :teamId="deleteTeamId" v-if="deleteTeamModal"/>
    </el-dialog>

     <el-dialog :close-on-click-modal="false" class="medium" :title="editTrackSettingsModalTitle" :visible.sync="editTrackSettingsModal" center modal-append-to-body destroy-on-close>
      <EditTrackSettingsModal @closeDialog="editTrackSettingsModal = false" v-if="editTrackSettingsModal" :data="editTrackSettings"/>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" :title="editCostSettingsModalTitle" :visible.sync="editCostSettingsModal" center modal-append-to-body destroy-on-close>
        <EditCostSettingsModal @closeDialog="editCostSettingsModal = false" v-if="editCostSettingsModal" :data="editCostSettings"/>
      </el-dialog>


  </div>
</template>

<script>


//TODO: Employee isminin altında İnvitation status a göre tekrar gönder vs butonları çıkmalı


import Breadcrumb from '../partials/Breadcrumb';
import EmployeesAddOrUpdateModal from './EmployeesAddOrUpdateModal.vue';
import DeleteEmployeeModal from './DeleteEmployeeModal.vue';
import DeleteTeamModal from './DeleteTeamModal.vue';
import TeamsAddOrUpdateModal from './TeamsAddOrUpdateModal.vue';
import EditTrackSettingsModal from './EditTrackSettingsModal.vue';
import EditCostSettingsModal from './EditCostSettingsModal.vue';
import moment from "moment/min/moment-with-locales";
import SearchInput from '@/components/form/SearchInput.vue';

export default {
    name: 'EmployeesIndex',
    components: {
      Breadcrumb,
      EmployeesAddOrUpdateModal,
      TeamsAddOrUpdateModal,
      DeleteEmployeeModal,
      DeleteTeamModal,
      EditTrackSettingsModal,
      EditCostSettingsModal,
      SearchInput
    },

    data(){
        return {
          loading:null,
          moment,
          meta: {
            title: 'Employees',
            desc:'You can manage your teams and employees here.'
          },
          employeesAddOrUpdateModal: false,
          teamsAddOrUpdateModal: false,
          teamsAddOrUpdateModalTitle: 'Add new team',
          addUpdateTeam: {
            Id:null,
            Title:null,
            Color:null,
          },
          activeTab: 'employees',
          employeeModalTitle: 'Add new employee',
          addUpdateEmployee: {
            ID:null,
            FirstName:null,
            LastName:null,
            Email:null,
            TeamId:null,
            Role:null,
            EmployeeCode:null,
          },
          deleteEmployeeModal:false,
          deleteEmployeeId:null,
          employeeDeleteModalTitle:'Delete employee',
          deleteTeamId:null,
          deleteTeamModalTitle:null,
          deleteTeamModal:false,
          editTrackSettingsModalTitle: '',
          editTrackSettingsModal:false,
          editTrackSettings: {
            EmployeeId: null,
            EnableCustomTrackSettings: false,
            EnableScreenshots: false,
            BlurScreenshots: false,
            EnableTimelapseVideos: false,
            EnableGhostMode:false,
            EnableAutoClockIn:false,
            ScreenCaptureIntervalMins: 5
          },

          editCostSettingsModalTitle: '',
          editCostSettingsModal: false,
          editCostSettings: {
            EmployeeId: null,
            PayRate:0,
            BillRate:0,
            WeeklyLimit:false,
            WeeklyLimitHours:45
          },
          filter: {
            SearchTerm:null,
            IncludeBlockedEmployees:false
          }
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.getData();

      if(this.$route.query.a){
        this.addEmployee();
      }

      this.$root.$on('openEmployeeModal', () => {
        this.addEmployee();
      });
    },
    beforeDestroy() {
      this.$root.$off('openEmployeeModal');
    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("getTeams");
        await this.$store.dispatch("getEmployees");
        this.loading = null;
      },
      async toggleBlock(item) {
        this.loading = 'block-' + item.ID;
        this.$confirm('You are about to  ' + (item.Blocked ? "unblock " : "block ") + '' + (item.User != null ? item.User.FirstName : item.Invitation.FirstName) + '. Do you want to countinue? When you block an employee, their data remains stored and is still subject to the license. However, you can add a different user in their place.', (item.Blocked ? "Unblock an employee" : "Block an employee"), {
          confirmButtonText: 'Yes, ' + (item.Blocked ? "unblock " : "block "),
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          this.$store.dispatch("toggleBlockEmployee", {Id: item.ID});
        }).catch(() => {
            
        });
        this.loading = null;
      },
      addEmployee() {
        this.employeeModalTitle = 'Add new employee';
        this.addUpdateEmployee = {
            EmployeeId:null,
            User:null,
            FirstName:null,
            LastName:null,
            Email:null,
            TeamId:null,
            Role:null,
            EmployeeCode:null
        };
        this.employeesAddOrUpdateModal = true;
      },
      addteam() {
        this.teamsAddOrUpdateModalTitle = 'Add new team';
        this.addUpdateTeam = {
            Id:null,
            Title: null,
            Color:null,
        };
        this.teamsAddOrUpdateModal = true;
      },
      updateCostSettings(employee) {
        this.editCostSettingsModalTitle = 'Edit cost settings "' + (employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName) + '"';
        this.editCostSettings = {
          EmployeeId: employee.ID,
          PayRate: employee.PayRate,
          BillRate: employee.BillRate,
          WeeklyLimit: employee.WeeklyLimit,
          WeeklyLimitHours: employee.WeeklyLimitHours
        };
        this.editCostSettingsModal = true;
      },
      updateTrackSettings(employee) {
        this.editTrackSettingsModalTitle = 'Edit track settings "' + (employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName) + '"';
        this.editTrackSettings = {
            EmployeeId:employee.ID,
            EnableCustomTrackSettings: employee.EnableCustomTrackSettings,
            EnableScreenshots: employee.EnableScreenshots,
            BlurScreenshots: employee.BlurScreenshots,
            EnableTimelapseVideos: employee.EnableTimelapseVideos,
            ScreenCaptureIntervalMins: employee.ScreenCaptureIntervalMins,
            EnableGhostMode:employee.EnableGhostMode,
            EnableAutoClockIn: employee.EnableAutoClockIn
        };
        this.editTrackSettingsModal = true;
      },
      updateTeam(item) {
        this.teamsAddOrUpdateModalTitle = 'Update "' + item.Title + '"';
        this.addUpdateTeam = {
            Id:item.ID,
            Title: item.Title,
            Color:item.Color
        };
        this.teamsAddOrUpdateModal = true;
      },
      updateEmployee(employee) {
        this.employeeModalTitle = 'Edit "' + (employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName) + '"';
        this.addUpdateEmployee = {
            EmployeeId:employee.ID,
            FirstName:(employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName),
            User: employee.User,
            LastName:employee.User != null ? employee.User.LastName : employee.Invitation.LastName,
            Email:employee.User != null ? employee.User.Email : employee.Invitation.Email,
            TeamId:employee.TeamId,
            Role:employee.Role,
            EmployeeCode:employee.EmployeeCode,
        };
        this.employeesAddOrUpdateModal = true;
      },
      deleteEmployee(item) {
        this.deleteEmployeeId = item.ID;
        this.employeeDeleteModalTitle = 'Delete "' + (item.User != null ? item.User.FirstName : item.Invitation.FirstName) + '"'
        this.deleteEmployeeModal = true;
      },
      deleteTeam(item) {
        this.deleteTeamId = item.ID;
        this.deleteTeamModalTitle = 'Delete "' + item.Title + '"'
        this.deleteTeamModal = true;
      },
      async resendInvitation(id) {
        this.loading = 'resend-invitation-' + id;
        await this.$store.dispatch("resendEmployeeInvitation", {
          EmployeeId: id
        })
        this.loading = false;
      },
      tableRowClassName({row, rowIndex}) {
        if (row.Blocked == true) {
          return 'red-row';
        }
        return '';
      }
    },
    computed: {
      teams() {
        var temp = this.$store.getters.getTeams;
        if (!temp) {
          return temp;
        }
        var temp2 = JSON.parse(JSON.stringify(temp));

        if (this.filter.SearchTerm != null && this.filter.SearchTerm != "") {
          const searchRegex = new RegExp(this.$toSearchRegex(this.filter.SearchTerm));
          temp2.Items = temp2.Items.filter(x =>
            searchRegex.test(x.Title)
          );
          temp2.TotalCount = temp2.Items.length;
        }

        return temp2;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      trackSettings() {
        return this.organization.TrackSettings;
      },
      employeesReal() {
        return this.$store.getters.getEmployees;
      },
      employees() {
        var temp = this.$store.getters.getEmployees;
        if (!temp) {
          return temp;
        }
        var temp2 = JSON.parse(JSON.stringify(temp));

        if (this.filter.SearchTerm != null && this.filter.SearchTerm != "") {
          const searchRegex = new RegExp(this.$toSearchRegex(this.filter.SearchTerm));
          temp2.Items = temp2.Items.filter(x =>
            
            (
              x.User != null ? 
              (
                searchRegex.test(x.User.FirstName) ||
                searchRegex.test(x.User.LastName) ||
                searchRegex.test(x.User.FirstName + ' ' + x.User.LastName) ||
                searchRegex.test(x.User.Email)
              ) : 
              (
                searchRegex.test(x.Invitation.FirstName) ||
                searchRegex.test(x.Invitation.LastName) || 
                searchRegex.test(x.Invitation.FirstName + ' ' + x.Invitation.LastName) ||
                searchRegex.test(x.Invitation.Email)
              )
            )
            ||
            x.EmployeeCode == this.filter.SearchTerm
          );
        }

        temp2.Items = temp2.Items.filter(x => this.filter.IncludeBlockedEmployees ? true : x.Blocked != true);
        temp2.TotalCount = temp2.Items.length;
        return temp2;
      }
    }
}
</script>
