<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area mb-0">
      <div class="filter-options">
        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :key="filter.TeamId" :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" />
        </div>
        <div class="filter-item">
          <SearchInput :initialValue="filter.SearchTerm" @changed="(term) => filter.SearchTerm = term" />
        </div>
        <div class="filter-item" v-if="activeTab == 'reviewed'">
          <MultipleProductivityStatusSelectbox :selectedId="filter.ProductivityStatuses" @changed="(id) => filter.ProductivityStatuses = id" />
        </div>
        

      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => {filter.Page = 1; getData()}" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="activity-filter" v-if="reviewed && unreviewed">
      <div class="filter-item purple" :class="activeTab == 'unreviewed' ? 'isactive' : ''" @click="activeTab = 'unreviewed'">
        <i class="fas fa-question-circle"></i>
        <span>Unreviewed apps<b :class="unreviewed.TotalCount > 99 ? 'square' : ''">{{ unreviewed.TotalCount }}</b></span>
      </div>
      <div class="filter-item green" :class="activeTab == 'reviewed' ? 'isactive' : ''" @click="activeTab = 'reviewed'">
        <i class="fas fa-check-circle"></i>
        <span>Reviewed apps<b :class="reviewed.TotalCount > 99 ? 'square' : ''">{{ reviewed.TotalCount }}</b></span>
      </div>
    </div>

    <div class="card p-5 mt-5" v-if="!reviewed || !unreviewed">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10" id="activityTable" v-if="reviewed && unreviewed">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Applications</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge blue no-cursor">Total {{ activeTab == 'unreviewed' ? unreviewed.TotalCount : reviewed.TotalCount }}</span>
        </div>
      </div>

      <div class="activity-table" >

        <NoData v-if="getTableData.length == 0" />
        <el-table :data="getTableData" v-if="getTableData.length > 0" v-loading="loading == 'getData'">

          <el-table-column label="Application" min-width="250">
            <template slot-scope="scope">
              <div class="app-title" v-if="scope.row.ActivityApp">
                <el-avatar :size="25" shape="square" :src="scope.row.ActivityApp.Icon"></el-avatar>
                <div>
                  <span>
                  {{ scope.row.ActivityApp.Name }}
                  <a target="_blank" :href="'http://' + scope.row.ActivityApp.Url" v-if="scope.row.ActivityApp.Url != null">{{ scope.row.ActivityApp.Url }}</a>
                  </span>

                  <el-tooltip class="ai-item" effect="dark" :content="'The Worktivity AI model automatically identified this application as suitable for the '+ scope.row.ActivityApp.AICategory +' category.'" placement="bottom" v-if="scope.row.ActivityApp.AICategory != null">
                    <p><i class="fa fa-info-circle"></i> {{ scope.row.ActivityApp.AICategory }}</p>
                  </el-tooltip>
      
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Team" width="275">
            <template slot-scope="scope">
              <span class="badge elipsis" v-on:click="filter.TeamId = scope.row.Team.ID" v-if="scope.row.Team != null"><i class="fas fa-circle" :style="'color: ' + scope.row.Team.Color"></i> {{ scope.row.Team.Title }}</span>
            </template>
          </el-table-column>

          <el-table-column label="AI Suggestion" width="150">
            <template slot-scope="scope">

              <el-tooltip class="item" effect="dark" :content="'Worktivity AI model determined this application as ' + scope.row.AISuggestion + ' for the ' + scope.row.Team.Title + ' Team. These results are not conclusive. However, it will be more beneficial for you to make a decision that suits your own workflow.'" placement="top"  v-if="scope.row.AISuggestion != null && scope.row.Team != null">
              <span class="badge">{{ scope.row.AISuggestion }}</span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="'AI result is waiting for the productivity analysis.'" placement="top"  v-if="scope.row.AISuggestion == null">
              <span class="badge">Pending</span>
              </el-tooltip>
              
            </template>
          </el-table-column>

          <el-table-column label="First interaction" width="200">
            <template slot-scope="scope">
              <span class="badge">{{ moment(scope.row.FirstInteraction).format('DD MMM yyyy - HH:mm') }}</span>
            </template>
          </el-table-column>

          <el-table-column width="150">
            <template slot-scope="scope" >
              <el-row :key="scope.row.ID + scope.row.Status">

                <el-tooltip class="item" effect="dark" content="Mark as productive" placement="top">
                  <el-button type="success" icon="fas fa-check" size="small" circle @click="changeStatus(scope.row.ID, 1)" :loading="loading == 'changeStatus-' + scope.row.ID + 1" :plain="scope.row.Status != 1"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Mark as neutral" placement="top">
                  <el-button type="info" icon="fas fa-minus" size="small" circle @click="changeStatus(scope.row.ID, 2)" :loading="loading == 'changeStatus-' + scope.row.ID + 2" :plain="scope.row.Status != 2"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Mark as unproductive" placement="top">
                  <el-button type="danger" icon="fas fa-times" size="small" circle @click="changeStatus(scope.row.ID, 3)" :loading="loading == 'changeStatus-' + scope.row.ID + 3" :plain="scope.row.Status != 3"></el-button>
                </el-tooltip>

              </el-row>
            </template>
          </el-table-column>

        </el-table>
      </div>

      <Pagination 
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="activeTab == 'reviewed' ? reviewed.TotalCount : unreviewed.TotalCount"  
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />

    </div>

  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import SearchInput from '@/components/form/SearchInput.vue';
import Pagination from '@/components/Pagination';
import MultipleProductivityStatusSelectbox from '@/components/form/MultipleProductivityStatusSelectbox.vue';

import moment from "moment/min/moment-with-locales";

export default {
    name: 'ReviewAppsIndex',
    components: {
      Breadcrumb,
      Pagination,
      TeamSelectbox,
      SearchInput,
      MultipleProductivityStatusSelectbox,
    },

    data(){
        return {
          moment,
          meta: {
            title: 'Review apps',
            desc:'You can review your teams app usage here.'
          },
          loading:null,
          activeTab:'unreviewed',
          filter: {
            SearchTerm:null,
            TeamId:null,
            Statuses: 0,
            PageSize:12,
            Page:1,
            IncludeActivityApp:true,
            IncludeTeams:true,
            ProductivityStatuses:[]
          }
        }
    },
    mounted() {
      this.$setMeta(this.meta);
      this.getData();
    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("reviewed", this.filter);
        await this.$store.dispatch("unreviewed", this.filter);
        this.loading = null;
      },
      async changeStatus(id, status) {
        this.loading = 'changeStatus-' + id + status;
        var res = await this.$store.dispatch("changeStatus", {
          OrganizationAppId: id,
          Status: status,

          //TODO: Özkan - bunlar apide kalmış apiden kaldırınca kaldırmak gerek
          PageSize:1,
          Page:1
        });
        
        this.loading = null;

        if(this.activeTab == 'unreviewed')
        {
            await this.$store.dispatch("unreviewed", this.filter);
          
        }
      }
    },
    computed: {
      account() {
          return this.$store.getters.getAccount;
      },
      organization() {
          return this.account.CurrentOrganization;
      },
      trackSettings() {
          return this.organization.TrackSettings;
      },
      reviewed() {
        return this.$store.getters.getReviewed;
      },
      unreviewed() {
        return this.$store.getters.getUnreviewed;
      },
      getTableData() {
        if(!this.reviewed || !this.unreviewed)
          return [];

        return this.activeTab == 'reviewed' ? this.reviewed.Items : this.unreviewed.Items;
      },
    },
    watch: {
      'activeTab'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
        this.getData();
        }
      },
      'filter.TeamId'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
        this.getData();
        }
      },
      'filter.ProductivityStatuses'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
        this.getData();
        }
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("activityTable");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("activityTable");
      },
      'filter.TeamId'() {
        this.filter.Page = 1;
        this.getData();
      },
      'filter.SearchTerm'() {
        this.filter.Page = 1;
        this.getData();
      }
    }
    
}
</script>
