<template>
      <el-select v-model="id" filterable :placeholder="placeholder ? placeholder : 'All employees'" v-if="employees && teams" @change="selectionChanged" clearable @clear="clearSelection">
        <el-option-group
          v-for="group in teams.Items"
          :key="group.ID"
          :label="group.Title">
          <el-option
          v-for="item in employees.Items.filter(x => x.User != null && x.TeamId == group.ID)"
          :key="item.ID"
          :label="item.User.FirstName + ' ' + item.User.LastName"
          :value="item.ID">
          <div class="d-flex align-items-center">
          
              <img :src="$imageus(item.User.Image,'100','100','cover')" class="img-border-100 w20 mr-2" />
              <span class="d-flex w-100 align-items-center justify-content-between">
                <span>{{ item.User.FirstName }} <b>{{ item.User.LastName }}</b></span>
                <el-tooltip :disabled="item.Blocked != true" content="Blocked" placement="right">
                <i class="fas fa-lock" style="font-size:11px; float:right;" v-if="item.Blocked == true"></i>
              </el-tooltip>
              </span>
      
          </div>
        </el-option>
        </el-option-group>
      </el-select>
</template>

<script>

export default {
    name: 'EmployeeSelectbox',
    props: ["selectedId", "placeholder"],
    data(){
        return {
          id:null,
        }
    },
    async mounted() {
      this.id = this.selectedId;

      if(this.teams == null)
      {
        await this.getTeams();
      }

      if(this.employees == null)
      {
        await this.getData();
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        if(this.id)
        {
          this.$emit("changedTeam", this.employees.Items.find(x => x.ID == this.id).TeamId);
        }
        
        this.$emit("changed", this.id);
      },
      async getData() {
        await this.$store.dispatch("getEmployees");
      },
      async getTeams() {
        await this.$store.dispatch("getTeams");
      }
    },
    computed: {
      employees() {
        return this.$store.getters.getEmployees;
      },
      teams() {
        return this.$store.getters.getTeams;
      }
    }
    
}
</script>
