<template>
    <div class="custom-no-data">

      <img src="@/assets/img/nodata.png" />
      <h4>No Data</h4>
      <p>There is nothing to show here.</p>
    </div>
</template>


<script>
export default {
    name: 'NoData',
    data() {
      return {
        
      };
    }
}
</script>


<style lang="less">

.custom-no-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top:20px;
  margin-bottom:20px;
  width:100%;
  img {
    width:100px;
    filter: grayscale(100%);
    margin-bottom:20px;
  }
}
</style>