<template>
  <div class="content-wrapper">

    <vue-page-transition name="fade" class="container container-fluid">
      <router-view></router-view>
    </vue-page-transition>
    
  </div>
</template>

<script>
export default {
  name: 'Main',
  components: {

  },

  data(){
    return {

    }
  },
 
}
</script>
