
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" v-loading="loading == 'init'">

        <el-form-item label="Customer" required>
          <CustomerSelectbox :selectedId="formData.CustomerId" @changed="(id) => formData.CustomerId = id" :key="formData.CustomerId" />
          <ValidationErrorItem validationKey="CustomerId" />
        </el-form-item>

         <el-form-item label="Currency" required>
           <CurrencySelectbox :selectedId="formData.Currency" @changed="(id) => formData.Currency = id" />
            <ValidationErrorItem validationKey="Currency" />
        </el-form-item>
      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save' || loading == 'init'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Create invoice' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import CustomerSelectbox from '@/components/form/CustomerSelectbox.vue';
import CurrencySelectbox from '@/components/form/CurrencySelectbox.vue';

export default {
    name: 'InvoiceAddUpdateModal',
    props: ["data"],
    components: {
        CustomerSelectbox,
        CurrencySelectbox
    },
    data(){
        return { 
          loading:null,
          rederQuill:false,
          formData: {
            Id:null,
            CustomerId:null,
            Currency:null,
          }
        }   
    },
    async mounted() {
      this.loading = 'init';
      if(this.data)
      {
        this.formData = this.data;
      }
      this.loading = null;
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("customerInvoceAddUpdate", this.formData);
        this.loading = null;
        if(res != null)
        {
          this.$emit('invoiceCreated', res.Item);
        }
      }
    },
    computed: {
      
    }
}
</script>