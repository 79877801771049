import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    trackSettings:null,
  }),
  getters: {
    getTrackSettings(state) {
      return state.trackSettings;
    },
  },
  mutations: {
    setTrackSettings(state, payload) {
      state.trackSettings = payload;
    },
  },
  actions: {
    async getTrackSettings(store, payload) {
      var res = await axios.post("/organization/getTrackSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      store.commit("setTrackSettings", res.data.Data);
      return res.data.Data;
    },
    async updateOrganizationProfile(store, payload) {
      var res = await axios.post("/organization/updateProfile", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async updateOrganizationInvoiceSettings(store, payload) {
      var res = await axios.post("/organization/UpdateInvoiceSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async deleteOrganization(store, payload) {
      var res = await axios.post("/organization/deleteOrganization", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async updateTrackSettings(store, payload) {
      var res = await axios.post("/organization/updateTrackSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
  }
}

export default store;