
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-red p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p><b>Delete {{ account.User.Email }}</b></p>
            <p>You are about to delete the entire account associated with the email address <b>ozkan@internative.net</b>.</p>
            <p>All of your data will be permanently deleted.</p>
            <p>This action can not be undo, are you sure about this?</p>
          </div>
        </div>
      </div>

      <div class="row d-flex align-items-center justify-content-center mb-5" v-if="!next">
        <el-button type="danger" icon="fas fa-exclamation-circle" @click="showNext" v-if="!next">Proceed to next step</el-button>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" v-if="next" @keyup.enter.native="deleteAccount">

        <el-form-item label="Account e-mail" required>
          <el-input v-model="formData.Email" placeholder="Type the email address of the account you want to delete." required clearable></el-input>
        </el-form-item>

        <el-form-item label="Your password" required>
          <el-input v-model="formData.Password" type="password" placeholder="******" :disabled="formData.Email != account.User.Email" required clearable></el-input>
          <ValidationErrorItem validationKey="Password" />
        </el-form-item>

        <el-form-item label="Reason" required>
          <el-input v-model="formData.DeletionReason" type="text" placeholder="Please specify..." :disabled="formData.Email != account.User.Email" required clearable></el-input>
          <ValidationErrorItem validationKey="DeletionReason" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer" v-if="next">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="danger" icon="fas fa-exclamation-circle" @click="deleteAccount" :loading="loading == 'delete'" :disabled="formData.Email != account.User.Email">Delete account</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DeleteAccountModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          next:false,
          formData: {
            DeletionReason:null,
            Password:null,
            Email:null
          }
        }   
    },
    methods: {
      showNext() {
        this.next = true;
      },
      async deleteAccount() {
        this.loading = 'delete';

        var res = await this.$store.dispatch("deleteAccount", this.formData);
        
        this.loading = null;
        if(res != null)
        {
          await this.$store.dispatch("signOut");
          await this.$router.push({name: "login"});
        }
      }
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
    }
}
</script>

