/* eslint-disable no-console */
const sw_version = 1.1;
import { Message, MessageBox } from "element-ui";
import { register } from "register-service-worker";
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js?v=${sw_version}`, {
    scope: "/",
    ready() {
      console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; confirm or please refresh.", registration);
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
      // MessageBox.confirm("The new version of your Worktivity panel is available. Click the \"Refresh\" button to get the update.", "Version update", {
      //   confirmButtonText: "Refresh",
      //   cancelButtonText: "Cancel",
      //   type: "warning",
      // })
      //   .then(() => {
      //     Message.success("Download completed");
      //     registration.waiting.postMessage({ type: "SKIP_WAITING" });
      //     window.location.reload();
      //   })
      //   .catch(() => {
      //     Message.info("Update canceled");
      //   });
    },
    onupdatefound(registration) {
      console.log("Registration new update", registration);
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
