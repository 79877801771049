<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item">
          <SearchInput :initialValue="filter.SearchTerm" @changed="(term) => filter.SearchTerm = term" />
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => { getData(); }"
          :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>


    <div class="card p-5" v-if="!customers">
      <el-skeleton :rows="12" animated />
    </div>
    <template v-if="customers">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{ customers.TotalCount }} customer(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-user-friends" @click="addCustomer" round>Add new customer</el-button>
          </div>
        </div>

        <div class="activity-table">
          <NoData v-if="customers.Items.length == 0" />
          <el-table :data="customers.Items" v-if="customers.Items.length > 0" ref="tab" @row-click="expand">
            <el-table-column label="Company" width="250">
              <template slot-scope="scope">
                <p>{{ scope.row.Title }}</p>
                <span>{{ scope.row.Name }} <b>{{ scope.row.Surname }}</b></span>
              </template>
            </el-table-column>

            <el-table-column label="Name" width="250">
              <template slot-scope="scope">
                <p v-if="scope.row.Email">{{ scope.row.Email }}</p>
                <p v-if="scope.row.Phone">{{ scope.row.Phone }}</p>
              </template>
            </el-table-column>

            <el-table-column type="expand">
            <template slot-scope="props">
              <p><b>Tax office:</b> {{ props.row.TaxOffice }}</p>
              <p><b>Tax number:</b> {{ props.row.TaxNumber }}</p>
              <p><b>Address:</b> {{ props.row.Address }}</p>
              <p><b>Notes:</b> {{ props.row.Notes }}</p>
            </template>
          </el-table-column>  


            <el-table-column label="Website">
              <template slot-scope="scope">
                <p v-if="scope.row.Website"><a :href="scope.row.Website" target="_blank">{{ scope.row.Website }}</a></p>
              </template>
            </el-table-column>


            <el-table-column :width="trackSettings.EnableCostManagement && $isInRole('Owner,Coowner') ? 150 : 100">
              <template slot-scope="scope">
                <el-row>

                  <el-tooltip class="item" effect="dark" content="Create invoice" placement="top" v-if="trackSettings.EnableCostManagement && $isInRole('Owner,Coowner')">
                    <el-button type="secondary" icon="fas fa-file-invoice" size="small" circle @click.stop="createInvoiceCustomer(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Edit customer" placement="top">
                    <el-button type="info" icon="fas fa-pen" size="small" circle @click.stop="updateCustomer(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" :content="$isInRole('Manager') && organization.TrackSettings.AllowManagersToDeleteCustomers == false ? 'You don\'t have permission to delete a customer' : 'Delete customer'" placement="top">
                    <el-button type="danger" :disabled="$isInRole('Manager') && organization.TrackSettings.AllowManagersToDeleteCustomers == false" icon="fas fa-trash" size="small" circle @click.stop="deleteCustomer(scope.row)" :loading="loading == 'delete-' + scope.row.ID"></el-button>
                  </el-tooltip>

                </el-row>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" :title="customerModalTitle" :visible.sync="customerAddUpdateModal" center modal-append-to-body destroy-on-close>
      <CustomersAddUpdateModal @closeDialog="customerAddUpdateModal = false" :data="addUpdateCustomer" v-if="customerAddUpdateModal"/>
    </el-dialog>

     <el-dialog :close-on-click-modal="false" class="medium" title="Create invoice" :visible.sync="invoiceAddUpdateModalShow" center modal-append-to-body destroy-on-close>
      <InvoiceAddUpdateModal @invoiceCreated="invoiceCreated" @closeDialog="invoiceAddUpdateModalShow = false" :data="createInvoice" v-if="invoiceAddUpdateModalShow"/>
    </el-dialog>


  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import CustomersAddUpdateModal from './CustomersAddUpdateModal.vue';
import moment from "moment/min/moment-with-locales";
import InvoiceAddUpdateModal from '@/views/costmanagement/InvoiceAddUpdateModal.vue';
import SearchInput from '@/components/form/SearchInput.vue';

export default {
    name: 'EmployeesIndex',
    components: {
      Breadcrumb,
      CustomersAddUpdateModal,
      InvoiceAddUpdateModal,
      SearchInput
    },
    data(){
        return {
          loading:null,
          moment,
          meta: {
            title: 'Customers',
            desc:'You can manage your customers here.'
          },
          customerAddUpdateModal: false,
          invoiceAddUpdateModalShow:false,
          createInvoice: {
            Id:null,
            CustomerId:null,
            Currency: 0
          },
          customerModalTitle: 'Add new customer',
          addUpdateCustomer: {
            Id:null,
            Name:null,
            Surname:null,
            Email:null,
            Website:null,
            Notes:null,
            Address: null,
            Phone:null,
            Title:null
          },
          filter: {
            SearchTerm:null,
          }
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.getData();
    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("getCustomers");
        this.loading = null;
      },
      createInvoiceCustomer(item) {
        this.createInvoice.CustomerId = item.ID;
        this.invoiceAddUpdateModalShow = true;
      },
      async invoiceCreated(item) {
        this.$router.push({name:'update-invoice', query: {Id:item.ID}});
      },
      expand(row, isExpanded) {
        if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
        {
          this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
        }
        else
        {
          this.$refs.tab.store.states.expandRows.push(row);
        }
      },
      addCustomer() {
        this.customerModalTitle = 'Add new customer';
        this.addUpdateCustomer = {
            Id:null,
            Name:null,
            Surname:null,
            Email:null,
            Website:null,
            Notes:null,
            Address:null,
            TaxOffice:null,
            TaxNumber:null,
            Title:null,
        };
        this.customerAddUpdateModal = true;
      },
      updateCustomer(item) {
        this.customerModalTitle = 'Edit "' + item.Name + '"';
        this.addUpdateCustomer = {
          Id:item.ID,
          Name:item.Name,
          Surname:item.Surname,
          Email:item.Email,
          Website:item.Website,
          Notes:item.Notes,
          Address:item.Address,
          Phone:item.Phone,
          Title:item.Title,
          Address:item.Address,
          TaxOffice:item.TaxOffice,
          TaxNumber:item.TaxNumber,
          Title:item.Title,
        };
        this.customerAddUpdateModal = true;
      },
      deleteCustomer(item) {
        this.loading = 'delete-' + item.ID;

        this.$confirm('You are about to delete a customer. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          await this.$store.dispatch("deleteCustomer",{ Id: item.ID });
          await this.$store.dispatch("getCustomers");
        }).catch(() => {
            
        });

        this.loading = null;
      },
    },
    computed: {
      customers() {
        var temp = this.$store.getters.getCustomers;
        if (!temp) {
          return temp;
        }
        var temp2 = JSON.parse(JSON.stringify(temp));

        if (this.filter.SearchTerm != null && this.filter.SearchTerm != "") {
          const searchRegex = new RegExp(this.$toSearchRegex(this.filter.SearchTerm));
          temp2.Items = temp2.Items.filter(x =>
            searchRegex.test(x.Title) ||
            searchRegex.test(x.Surname) ||
            searchRegex.test(x.Name + ' ' + x.Surname) ||
            searchRegex.test(x.Email)
          );
          temp2.TotalCount = temp2.Items.length;
        }

        return temp2;
      },
      account() {
            return this.$store.getters.getAccount;
        },
      organization() {
          return this.account.CurrentOrganization;
      },
      trackSettings() {
          return this.organization.TrackSettings;
      },
    }
}
</script>
