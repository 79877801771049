<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="activity-filter">
      <div class="filter-item purple" :class="showPage == 0 ? 'isactive' : ''" @click="showPage = 0">
        <i class="fas fa-user-cog"></i>
        <span>Profile</span>
      </div>
      <div class="filter-item purple" :class="showPage == 1 ? 'isactive' : ''" @click="showPage = 1">
        <i class="fas fa-lock"></i>
        <span>Security</span>
      </div>
      <div class="filter-item purple" :class="showPage == 2 ? 'isactive' : ''" @click="showPage = 2" v-if="organization != null">
        <i class="fas fa-bell"></i>
        <span>Notifications</span>
      </div>
    </div>

    <template v-if="showPage == 0">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Your profile</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">
          <el-form :label-position="'top'" label-width="100px" @keyup.enter.native="updateAccount">
            <el-form-item label="Profile picture" required>
              <el-upload
                class="avatar-uploader"
                :action="axios.defaults.baseURL + 'account/UploadFile?UploadPath=User'"
                :headers="{ Authorization: currentToken() }"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :before-upload="beforeUpload"
                :onProgress="fileUploadProgress"
                >
                <img v-if="account.Image" :src="account.Image" class="avatar">
                <i class="el-icon-plus avatar-uploader-icon" v-if="!uploadInProgress"></i>
                <i class="fa fa-spinner fa-spin avatar-uploader-icon" v-else></i>
                
              </el-upload>
              <ValidationErrorItem validationKey="Image" />
            </el-form-item>

            <el-form-item label="Name" required>
              <el-input v-model="account.FirstName" placeholder="John" required clearable></el-input>
              <ValidationErrorItem validationKey="FirstName" />
            </el-form-item>

            <el-form-item label="Surname" required>
              <el-input v-model="account.LastName" placeholder="Doe" required clearable></el-input>
              <ValidationErrorItem validationKey="LastName" />
            </el-form-item>

            <el-form-item label="Email" required>
              <el-input v-model="account.Email" type="email" placeholder="someone@example.com"  clearable></el-input>
              <ValidationErrorItem validationKey="Email" />
            </el-form-item>

            <el-form-item class="space-between-buttons">
              <el-button :loading="loading == 'deleteAccount'" type="danger" icon="fas fa-trash" round @click="deleteAccount">Delete account</el-button>

              <el-button :loading="loading == 'updateAccount'" type="primary" icon="fas fa-check" round @click="updateAccount">Save changes</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    </template>

    <template v-if="showPage == 1">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Change password</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">

          <div class="card bg-blue p-5 mb-3 mt-3">
            <div class="card-alert">
              <i class="fas fa-info-circle"></i>
              <div>
                <span v-if="account.PasswordUpdateDate != null">Your last password change date is <b>{{ moment(account.PasswordUpdateDate).format('DD MMMM yyyy HH:mm') }}</b>, for security reasons we recommend that you renew your account password every 6 months at most.</span>
                <span v-else>
                  You never changed your account password, for security reasons we recommend that you renew your account password every 6 months at most.
                </span>
              </div>
            </div>
          </div>

          <el-form :label-position="'top'" label-width="100px" :model="updatePasswordForm" @keyup.enter.native="updatePassword">

            <el-form-item label="Current password" required>
              <el-input v-model="updatePasswordForm.CurrentPassword" type="password" placeholder="******" show-password required></el-input>
              <ValidationErrorItem validationKey="CurrentPassword" />
            </el-form-item>

            <el-form-item label="New password" required>
              <el-input v-model="updatePasswordForm.NewPassword" type="password" placeholder="******" show-password required></el-input>
              <ValidationErrorItem validationKey="NewPassword" />
            </el-form-item>

            <el-form-item label="New password (retype)" required>
              <el-input v-model="updatePasswordForm.NewPasswordRetry" type="password" placeholder="******" show-password required></el-input>
              <ValidationErrorItem validationKey="NewPasswordRetry" />
            </el-form-item>

            <el-form-item class="tracking-buttons">
              <el-button :loading="loading == 'updatePassword'" type="primary" icon="fas fa-check" round @click="updatePassword">Save changes</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    
  </template>


  <template v-if="showPage == 2 && organization != null">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Notifications</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">

          <div class="card bg-blue p-5 mb-3 mt-3">
            <div class="card-alert">
              <i class="fas fa-info-circle"></i>
              <div>
                <span>
                  This notification setting is applicable to the <b>{{ organization.Title }}</b> organization you logged into.
                  <br>
                  If you have accounts in multiple organizations, you can customize your notification settings by switching between organizations.
                </span>
              </div>
            </div>
          </div>

          <el-table :data="notificationSettings.Item.Settings" v-loading="loading == 'notificationSettings'">

            <el-table-column label="Notification type">
              <template slot-scope="scope">
                <span>{{ $getEnumTitle('NotificationType', scope.row.Type) }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Email" class-name="text-center" width="150">
              <template slot-scope="scope">
                <el-tooltip :disabled="scope.row.EmailIsActive" content="Soon" placement="top" v-if="scope.row.Email != null">
                  <el-switch v-model="scope.row.Email" :disabled="!scope.row.EmailIsActive"></el-switch>
                </el-tooltip>
                
              </template>
            </el-table-column>

            <el-table-column label="Push" class-name="text-center" width="150">
              <template slot-scope="scope">
                <el-tooltip :disabled="scope.row.WebIsActive" content="Soon" placement="top" v-if="scope.row.Web != null">
                  <el-switch v-model="scope.row.Web" :disabled="!scope.row.WebIsActive"></el-switch>
                </el-tooltip>
              </template>
            </el-table-column>

          </el-table>

          <div class="d-flex justify-content-end mt-5">
            <el-button :loading="loading == 'updateNotificationSettings'" type="primary" icon="fas fa-check" round @click="updateNotificationSettings">Save changes</el-button>
          </div>

        </div>
      </div>
  </template>



    <el-dialog :close-on-click-modal="false" class="medium" title="Delete account" :visible.sync="deleteAccountModal" center modal-append-to-body destroy-on-close>
      <DeleteAccountModal @closeDialog="deleteAccountModal = false" v-if="deleteAccountModal"/>
    </el-dialog>

  </div>
</template>

<script>

import axios from "axios"

import Breadcrumb from '../partials/Breadcrumb';
import moment from "moment/min/moment-with-locales";
import DeleteAccountModal from './DeleteAccountModal.vue';

export default {
    name: 'SettingsIndex',
    components: {
      Breadcrumb,
      DeleteAccountModal
    },

    data(){
      return {
        uploadInProgress:false,
        moment,
        axios,
        meta: {
          title: 'Settings',
          desc:'You can change your profile settings here.'
        },
        loading:null,
        updatePasswordForm: {
          CurrentPassword: null,
          NewPassword: null,
          NewPasswordRetry:null
        },
        showPage: 0,
        deleteAccountModal:false,
      }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.$store.dispatch("getAccount");
    },
    watch: {
      'showPage' () {
        if(this.showPage == 2) //Notifications
        {
          this.getNotificationSettings();
        }

      },
    },
    methods: {
      async getNotificationSettings() {
        this.loading = 'notificationSettings';
        await this.$store.dispatch("getNotifications", {});
        this.loading = null;
      },
      async updateNotificationSettings() {
        this.loading = 'updateNotificationSettings';
        await this.$store.dispatch("updateNotifications", {
          Settings: this.notificationSettings.Item.Settings
        });
        this.loading = null;
      },
      currentToken() {
        return localStorage.getItem('token');
      },
      deleteAccount() {
        this.deleteAccountModal = true;
      },
      async updateAccount() {
        this.loading = 'updateAccount';
        await this.$store.dispatch("updateProfile", this.account);
        this.loading = null;
      },
      async updatePassword() {
        this.loading = 'updatePassword';
        await this.$store.dispatch("updatePassword", this.updatePasswordForm);
        this.updatePasswordForm.CurrentPassword = null,
        this.updatePasswordForm.NewPassword = null,
        this.updatePasswordForm.NewPasswordRetry = null,
        this.loading = null;
      },
      fileUploadProgress(event) {
        //this.logoPercent = event.percent;
        this.uploadInProgress = true;
      },
      uploadSuccess(res, file) {
        this.uploadInProgress = false;
        if (res.HasError) {
          this.$message.error(res.Message);
        } else {
          this.$message.success(res.Message);
          this.account.Image = res.Data.Url;
          this.updateAccount();
        }
      },
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG or PNG format.');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        return isJPG && isLt2M;
      }
    },
    computed: {
      account() {
            return this.$store.getters.getAccount.User;
      },
      notificationSettings() {
            return this.$store.getters.getNotifications;
      },
      organization() {
            return this.$store.getters.getAccount.CurrentOrganization;
      },
    }
}
</script>
