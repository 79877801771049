<template>
  <div class="container mt-10">

     <portal to="action-left-buttons">
      <div class="mr-5 d-flex align-items-center">
        <span class="cursor-pointer d-flex align-items-center" href="javascript:;" @click="$router.push({name: 'invoices'})"><i class="fas fa-angle-left"></i></span>
      </div>
    </portal>

    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <portal to="action-buttons" v-if="invoice">
      <el-button type="secondary" round icon="fas fa-eye" @click="viewAsClientt" v-if="!viewAsClient">View as client</el-button>
      <el-button type="secondary" round icon="fas fa-eye-slash" @click="viewAsAdmin" v-else>View as admin</el-button>


      <el-button type="secondary" round icon="fas fa-check" @click="markAsSent" :loading="loading == 'mark-as-sent'" v-if="invoice.Status == 1">Mark as awaiting</el-button>
      <el-button type="secondary" round icon="fas fa-dollar-sign" @click="recordPayment" :disabled="[1,4].some(x => x == invoice.Status)">Record payment</el-button>

      <el-button type="primary" round icon="fas fa-file-pdf" :loading="loading == 'downloadPdf'" @click="exportAsPdf">Download</el-button>

    </portal>

    <div class="card p-5" v-if="invoiceId == null">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10 client-invoice" id="client-invoice" v-else>
      <Invoice :editableView="editableView" :showHistory="showHistory" :editingView="editingView" :id="invoiceId" v-if="invoice != null" :key="'invoice-' + invoice.Status + invoice.Total + invoice.Paid" />
    </div>


     <el-dialog :close-on-click-modal="false" class="medium" title="Record payment" :visible.sync="invoiceRecordPaymentModalShow" center modal-append-to-body destroy-on-close>
      <InvoiceRecordPaymentModal @paymentRecordCreated="getData" @closeDialog="invoiceRecordPaymentModalShow = false" :data="invoice" v-if="invoiceRecordPaymentModalShow"/>
    </el-dialog>

  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import Invoice from '@/components/Invoice.vue';
import MoneyInput from "@/components/form/MoneyInput.vue";
import moment from "moment/min/moment-with-locales";
import html2pdf from 'html2pdf.js';
import InvoiceRecordPaymentModal from './InvoiceRecordPaymentModal.vue';

export default {
    name: 'UpdateInvoiceIndex',
    components: {
      Breadcrumb,
      Invoice,
      MoneyInput,
      InvoiceRecordPaymentModal
    },
    data(){
        return {
          loading:null,
          moment,
          invoiceId:null,
          invoiceRecordPaymentModalShow:false,
          editableView:true,
          editingView:true,
          showHistory:true,
          invoice: null,
          viewAsClient:false,
          meta: {
            id:null,
            title: 'Edit an invoice',
            desc:'You can fill invoice details from here.'
          },
        }
    },
    async mounted() {
      this.$setMeta(this.meta);

      if(this.$route.query.Id){
        this.invoiceId = this.$route.query.Id
        await this.getData();
      }
      else
      {
        this.$router.push({name:'invoices'});
      }

    },
    methods: {
      recordPayment(item) {
        this.invoiceRecordPaymentModalShow = true;
      },
      async getData() {
        var res = await this.$store.dispatch("customerInvoiceGet", { Id: this.invoiceId});
        if(res != null)
        {
          if(res.Item.Status != 1)
          {
            this.editableView = false;
            this.editingView = false;
          }
          this.invoice = res.Item
        }
        else
        {
          this.$router.push({name: 'invoices'});
        }
      },
      markAsSent() {
        this.loading = 'mark-as-sent';
        this.$confirm('You are about to mark this invoice as "awaiting." You cannot edit this invoice anymore. This action cannot be undone. Are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, mark as awaiting',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          var res = await this.$store.dispatch("customerInvoiceMarkAsSent",{ Id: this.invoice.ID });
          if(res != null)
          {
            await this.getData();
          }
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      
      },
      viewAsAdmin() {
        if(this.invoice.Status == 1)
        {
          this.editableView = true; 
          this.editingView = true; 
        }
        else
        {
          this.editableView = false; 
          this.editingView = false; 
        }
        this.showHistory=true; 
        this.viewAsClient = false;
      },
      viewAsClientt() {
        this.editableView = false; 
        this.editingView = false; 
        this.showHistory=false; 
        this.viewAsClient = true;
      },
      exportAsPdf() {
        this.loading = 'downloadPdf';
        this.editableView = false; 
        this.editingView = false; 
        this.showHistory = false;

        this.viewAsClient = true

        var element = document.getElementById('client-invoice');
        var opt = {
          margin:       0,
          filename:     "invoice.pdf",
          enableLinks:true,
          image:        { type: 'jpeg', quality: 1 },
          html2canvas:  { scale: 4, windowWidth: 1920, windowHeight: element.clientHeight, useCORS:true  },
          jsPDF:        { unit: 'in', format: [this.pixelToInch(element.clientWidth),this.pixelToInch(element.clientHeight)], orientation: 'portrait' }
        };

        // New Promise-based usage:
        html2pdf().set(opt).from(element).save().then(() => {
          this.loading = null;
        }).catch((err) => {
          this.loading = null;
          console.log("pdf err", err);
        });
      },
      pixelToInch(pixel) {
        const inchesPerPixel = 1 / 96;
        const inches = pixel * inchesPerPixel;
        return inches;
      }
    },
    computed: {

    }
}
</script>
