import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    connected:true,
  }),
  getters: {
  },
  mutations: {
    signIn(state, payload) {
      var token = "Bearer " + payload;
      localStorage.setItem("token", token);
      axios.defaults.headers.common.Authorization = token;
    }
  },
  actions: {
    async signIn(store, payload) {
      var res = await axios.post("/login/signinweb", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("signIn", res.data.Data.Token);
      return res.data.Data;
    },
    async signUp(store, payload) {
      var res = await axios.post("/login/signup", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      store.commit("signIn", res.data.Data.Token);
      return res.data.Data;
    },
    signOut(store, payload) {
      localStorage.removeItem("token");
      axios.defaults.headers.common.Authorization = null;
    },
    async forgotPassword(store, payload) {
      var res = await axios.post("/login/forgotPassword", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
    async resetPassword(store, payload) {
      var res = await axios.post("/login/resetPassword", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
    async createOrganization(store, payload) {
      var res = await axios.post("/login/createOrganization", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
    async checkInvitationCode(store, payload) {
      var res = await axios.post("/login/CheckInvitationCode", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
  }
}

export default store;