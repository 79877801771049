<template>
    <div class="invoice-wrapper" v-if="invoice">

      <div class="invoice-header">
        <div class="logo-area">
          <img :src="organization.Image" alt="">
        </div>
        <div class="middle-area">
          <span>INVOICE</span>
        </div>
        <div class="title-area">
          <span>Invoice number: <b class="text-blue">{{ invoice.InvoiceNo }}</b></span>
          <span :class="editableView ? 'editing' : ''" class="editable-area" @click="() => { edit.InvoiceDate = true }">
            <i class="fas fa-pen"></i> Invoice date: <b>{{ moment(invoice.InvoiceDate).format('DD/MM/yyyy') }}</b>
          </span>
        </div>
      </div>

      <div class="invoice-writing">
        <div class="supplier-area editable-area" :class="editableView ? 'editing' : ''" @click="() => { edit.Supplier = true }">
          <h3>Supplier, <i class="fas fa-pen"></i></h3>
          <span><b>{{ invoice.Supplier.Title }}</b></span>
          <span>{{ invoice.Supplier.Address ?? '...' }}</span>
          <span class="mt-2">Tax number: <b>{{ invoice.Supplier.TaxNumber ?? '...' }}</b></span>
          <span>Tax office: <b>{{ invoice.Supplier.TaxOffice ?? '...' }}</b></span>
          <span class="mt-2">Phone: <b>{{ invoice.Supplier.Phone ?? '...' }}</b></span>
          <span>Email: <b>{{ invoice.Supplier.Email ?? '...' }}</b></span>
        </div>
        <div class="stamp-area">
          <img src="@/assets/img/stamp/draft-stamp.svg" alt="Draft" v-if="invoice.Status == 1">
          <img src="@/assets/img/stamp/awaiting-stamp.svg" alt="Sent" v-if="invoice.Status == 2">
          <img src="@/assets/img/stamp/partially-stamp.svg" alt="Partially paid" v-if="invoice.Status == 3">
          <img src="@/assets/img/stamp/paid-stamp.svg" alt="Partially paid" v-if="invoice.Status == 4">
        </div>
        <div class="client-area editable-area" :class="editableView ? 'editing' : ''" >
          <div class="d-flex flex-column" @click="() => { edit.Customer = true }">
          <h3><i class="fas fa-pen"></i> Client,</h3>
          <span><b>{{ invoice.Customer.Title ?? '...' }}</b></span>
          <span>{{ invoice.Customer.Address ?? '...' }}</span>
          <span class="mt-2">Tax number: <b>{{ invoice.Customer.TaxNumber ?? '...' }}</b></span>
          <span>Tax office: <b>{{ invoice.Customer.TaxOffice ?? '...' }}</b></span>
          <span class="mt-2">Phone: <b>{{ invoice.Customer.Phone ?? '...' }}</b></span>
          <span>Email: <b>{{ invoice.Customer.Email ?? '...' }}</b></span>
          </div>

          <span :class="editableView ? 'editing' : ''" class="editable-area text-green mt-5" @click="() => { edit.IssuedDate = true }"><i class="fas fa-pen"></i> Issued date: <b class="text-green">{{ moment(invoice.IssuedDate).format('DD/MM/yyyy') }}</b></span>
          <span :class="editableView ? 'editing' : ''" class="editable-area text-red" @click="() => { edit.DueDate = true }"><i class="fas fa-pen"></i> Due date: <b class="text-red">{{ moment(invoice.DueDate).format('DD/MM/yyyy') }}</b></span>

        </div>
        
      </div>

      <div class="invoice-items">
        <div class="invoice-table-header" :class="!editingView ? 'no-editmode' : ''">
          <span v-if="editingView"></span>
          <span v-if="!editingView">#</span>
          <span>Product/Service</span>
          <span>Price</span>
          <span>Quantitiy</span>
          <span>Total</span>
          <span v-if="editingView"></span>
        </div>
        <draggable class="invoice-item-container" v-model="invoice.Items" handle=".handle" ghost-class="ghost" :sort="editingView">
          <transition-group class="span">
            <div class="invoice-item" v-for="(item,index) in invoice.Items" :key="'invoice-detail' + index" :class="!editingView ? 'no-editmode' : ''">

              <div class="invoice-item-child handle" v-if="editingView">
                <i class="fas fa-grip-vertical"></i>
              </div>

              <div class="invoice-item-child order" v-if="!editingView">
                <span># {{ index+1 }}</span>
              </div>

              <div class="invoice-item-child">
                <span v-if="!editingView">{{ item.Title }}</span>
                <el-input v-else placeholder="..." v-model="invoice.Items[index].Title"></el-input>
              </div>

              <div class="invoice-item-child">
                <span v-if="!editingView">
                  {{ $formatMoney(item.Price, $getEnumTitle('CurrencyType', invoice.Currency)) }}
                </span>
                <MoneyInput v-else :min="0" :max="99999" :fixed="2" v-model="invoice.Items[index].Price" required clearable>
                  <template slot="append">{{ $getEnumTitle('CurrencyType', invoice.Currency) }}</template>
                </MoneyInput>
              </div>

              <div class="invoice-item-child">
                <span v-if="!editingView">{{ item.Quantity }}</span>
                <el-input-number v-else v-model="invoice.Items[index].Quantity" :min="1" :max="99999"></el-input-number>
              </div>

              <div class="invoice-item-child">
                <span>
                  {{ $formatMoney((invoice.Items[index].Price * invoice.Items[index].Quantity), $getEnumTitle('CurrencyType', invoice.Currency)) }}
                </span>
              </div>

              <div class="invoice-item-child actions" v-if="editingView">
                <el-button :loading="loading == 'deleteitem'" :size="'mini'" type="danger" icon="fas fa-trash" circle @click="removeItem(index)"></el-button>
              </div>

            </div>
          </transition-group>
        </draggable>
        <el-button type="secondary" class="add-button" round icon="fas fa-plus" @click="addNewItem" v-if="editingView">Add new line</el-button>
      </div>

      <div class="total-area">
        <div class="invoice-notes editable-area" :class="editableView ? 'editing' : ''" @click="() => { edit.Notes = true }">
          <template v-if="true">
            <h3>Note: <i class="fas fa-pen"></i></h3>
            <p>{{ invoice.Notes }}</p>
          </template>
          <template v-else> 
            <el-button type="text" @click=";">Add invoice note</el-button>
          </template>
        </div>
        <div class="calc-total">
          <span>Total: <b>{{ $formatMoney(total, $getEnumTitle('CurrencyType', invoice.Currency)) }}</b></span>
          <span>Paid: <b>{{ $formatMoney(paid, $getEnumTitle('CurrencyType', invoice.Currency)) }}</b></span>
          <span>Amount due: <b>{{ $formatMoney((total - paid), $getEnumTitle('CurrencyType', invoice.Currency)) }}</b></span>
        </div>
      </div>

      <div class="history-area" v-if="showHistory">
        <h3>Invoice history</h3>
        <el-table :data="invoice.History">
          <el-table-column label="Status">
            <template slot-scope="scope">
              <p class="text-gray">{{ scope.row.Title }}</p>
              <template v-if="scope.row.Type == 2">
                <p class="text-gray">Amount: <b>{{ $formatMoney(scope.row.Paid, $getEnumTitle('CurrencyType', invoice.Currency)) }}</b></p>
                <p class="text-gray">Payment date: <b>{{ scope.row.PaymentDate }}</b></p>
                <p class="text-gray" v-if="scope.row.Notes != null">Notes: {{ scope.row.Notes }}</p>
              </template>
            </template>
          </el-table-column>
          
          <el-table-column label="Employee"  width="200">
            <template slot-scope="scope">
              <p class="text-gray mb-0">{{ scope.row.EmployeeTitle }}</p>
              <p class="text-gray mb-0">{{ scope.row.EmployeeEmail }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Date" width="150">
            <template slot-scope="scope">
              <span class="text-gray">{{ moment(scope.row.CreateDate).format('DD MMM yyyy HH:mm') }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="brand-area" v-if="!editableView && !editingView">
        <span>Provided by</span>
        <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="dark-logo"/>
        <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="white-logo"/>
      </div>


    <el-dialog :close-on-click-modal="false" class="medium" title="Edit due date" :visible.sync="edit.InvoiceDate" center modal-append-to-body destroy-on-close>
      <div class="custom-modal">
        <div class="modal-body">
          <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false">
            <el-form-item label="Due date">
              <el-date-picker
                v-model="invoice.InvoiceDate"
                type="date"
                format="dd.MM.yyyy"
                placeholder="Pick a date">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="closeEditModal">Cancel</el-button>
          </div>
          <div class="right-buttons">
            <el-button type="primary" @click="saveInvoice" :loading="loading == 'save'" icon="fas fa-check">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Edit issued date" :visible.sync="edit.IssuedDate" center modal-append-to-body destroy-on-close>
      <div class="custom-modal">
        <div class="modal-body">
          <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false">
            <el-form-item label="Issued date">
              <el-date-picker
                v-model="invoice.IssuedDate"
                type="date"
                format="dd.MM.yyyy"
                placeholder="Pick a date">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="closeEditModal">Cancel</el-button>
          </div>
          <div class="right-buttons">
            <el-button type="primary" @click="saveInvoice" :loading="loading == 'save'" icon="fas fa-check">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Edit due date" :visible.sync="edit.DueDate" center modal-append-to-body destroy-on-close>
      <div class="custom-modal">
        <div class="modal-body">
          <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false">
            <el-form-item label="Due date">
              <el-date-picker
                v-model="invoice.DueDate"
                type="date"
                format="dd.MM.yyyy"
                placeholder="Pick a date">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="closeEditModal">Cancel</el-button>
          </div>
          <div class="right-buttons">
            <el-button type="primary" @click="saveInvoice" :loading="loading == 'save'" icon="fas fa-check">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Edit supplier" :visible.sync="edit.Supplier" center modal-append-to-body destroy-on-close>
      <div class="custom-modal">
        <div class="modal-body">
          <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false">
            <el-form-item label="Supplier name">
              <el-input v-model="invoice.Supplier.Title" placeholder="Acme Inc." required></el-input>
            </el-form-item>
            <el-form-item label="Address">
              <el-input v-model="invoice.Supplier.Address" placeholder="..."></el-input>
            </el-form-item>
            <el-form-item label="Tax number">
              <el-input v-model="invoice.Supplier.TaxNumber" placeholder="..."></el-input>
            </el-form-item>
            <el-form-item label="Tax office">
              <el-input v-model="invoice.Supplier.TaxOffice" placeholder="..."></el-input>
            </el-form-item>
            <el-form-item label="Phone">
              <el-input v-model="invoice.Supplier.Phone" placeholder="+1 (555) 555-555"></el-input>
            </el-form-item>
            <el-form-item label="Email">
              <el-input v-model="invoice.Supplier.Email" placeholder="someone@example.com"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="closeEditModal">Cancel</el-button>
          </div>
          <div class="right-buttons">
            <el-button type="primary" @click="saveInvoice" :loading="loading == 'save'" icon="fas fa-check">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Edit client" :visible.sync="edit.Customer" center modal-append-to-body destroy-on-close>
      <div class="custom-modal">
        <div class="modal-body">
          <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false">
            <el-form-item label="Client name">
              <el-input v-model="invoice.Customer.Title" placeholder="Acme Inc." required></el-input>
            </el-form-item>
            <el-form-item label="Address">
              <el-input v-model="invoice.Customer.Address" placeholder="..."></el-input>
            </el-form-item>
            <el-form-item label="Tax number">
              <el-input v-model="invoice.Customer.TaxNumber" placeholder="..."></el-input>
            </el-form-item>
            <el-form-item label="Tax office">
              <el-input v-model="invoice.Customer.TaxOffice" placeholder="..."></el-input>
            </el-form-item>
            <el-form-item label="Phone">
              <el-input v-model="invoice.Customer.Phone" placeholder="+1 (555) 555-555"></el-input>
            </el-form-item>
            <el-form-item label="Email">
              <el-input v-model="invoice.Customer.Email" placeholder="someone@example.com"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="closeEditModal">Cancel</el-button>
          </div>
          <div class="right-buttons">
            <el-button type="primary" @click="saveInvoice" :loading="loading == 'save'" icon="fas fa-check">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Edit notes" :visible.sync="edit.Notes" center modal-append-to-body destroy-on-close>
      <div class="custom-modal">
        <div class="modal-body">
          <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false">
            <el-form-item label="Notes">
              <el-input v-model="invoice.Notes" type="textarea" :autosize="{ minRows: 4, maxRows: 10}" placeholder="...."></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="modal-footer" slot="footer">
          <div class="left-buttons">
            <el-button @click="closeEditModal">Cancel</el-button>
          </div>
          <div class="right-buttons">
            <el-button type="primary" @click="saveInvoice" :loading="loading == 'save'" icon="fas fa-check">
              Save
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
      
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import MoneyInput from "@/components/form/MoneyInput.vue";
import moment from "moment/min/moment-with-locales";

export default {
    name: 'Invoice',
    props:["editableView","editingView", "id", "showHistory"],
    components: {
      draggable,
      MoneyInput
    },
    data() {
      return {
        moment,
        invoice: null,
        loading:null,
        saveTimeout:null,
        init:true,
        edit: {
          InvoiceDate:false,
          IssuedDate:false,
          DueDate:false,
          Customer:false,
          Supplier: false,
          Notes: false
        },
      };
    },
    async mounted() {
      await this.getData();
    },
    beforeDestroy() {
      if(this.saveTimeout != null)
      {
        window.clearTimeout(this.saveTimeout);
      }
    },
    methods: {
      removeItem(index) {
        this.invoice.Items.splice(index, 1);
      },
      async getData() {
        var res = await this.$store.dispatch("customerInvoiceGet", { Id: this.id});
        if(res != null)
        {
          this.invoice = res.Item
        }
        else
        {
          this.$router.push({name: 'invoices'});
        }
      },
      addNewItem() {
        this.invoice.Items.push({
          Title:null,
          Price:0,
          Quantity:1,
          Total:0,
        })
      },
      closeEditModal() {
        this.edit.InvoiceDate = false;
        this.edit.IssuedDate = false;
        this.edit.DueDate = false;
        this.edit.Customer = false;
        this.edit.Supplier = false;
        this.edit.Notes = false;
      },
      async saveInvoice() {
        this.loading = 'save';
        var res = await this.$store.dispatch("customerInvoceAddUpdate", {
          Id: this.invoice.ID,
          CustomerId: this.invoice.CustomerId,
          Currency: this.invoice.Currency,
          IssuedDate: this.invoice.IssuedDate,
          InvoiceDate: this.invoice.InvoiceDate,
          DueDate: this.invoice.DueDate,
          Supplier: this.invoice.Supplier,
          Customer: this.invoice.Customer,
          Total: this.total,
          Paid: this.paid,
          Notes: this.invoice.Notes,
          Items:this.invoice.Items
        });

        if(res != null)
        {
          this.closeEditModal();
        }

        this.loading = false;
      }
    },
    computed: {
      account() {
            return this.$store.getters.getAccount;
      },
      organization() {
            return this.account.CurrentOrganization;
      },
      total() {
        var tempTotal = 0;
        for (let i = 0; i < this.invoice.Items.length; i++) {
          const element = this.invoice.Items[i];
          tempTotal += (element.Price * element.Quantity)
        }
        return tempTotal;
      },
      paid() {
        var tempTotal = 0;
        for (let i = 0; i < this.invoice.History.length; i++) {
          const element = this.invoice.History[i];
          if(element.Type == 1)
          {
            tempTotal += element.Paid;
          }
          
        }
        return tempTotal;
      }
    },
    watch: {
      'edit': {
        handler: async function (val, oldVal) {
          if(!this.editableView) {
            this.closeEditModal();
          }
         },
        deep: true
      },
      'invoice.Items': {
        handler: async function (val, oldVal) {
          if(this.init)
            {
              this.init = false;
              return;
            }

          if(this.saveTimeout != null)
          {
            window.clearTimeout(this.saveTimeout);
          }
          this.saveTimeout = setTimeout(() => {
            this.saveInvoice();
          },350);
         },
        deep: true
      },
    }
}
</script>
