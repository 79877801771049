import axios from "axios"
import Vue from "vue";
import {
  Message
} from 'element-ui';
import store from "@/store";
import router from "@/router";

axios.defaults.baseURL = 'https://api.useworktivity.com/';
//axios.defaults.baseURL = 'http://localhost:5020/';
//axios.defaults.baseURL = "http://178.233.224.64:5020/";

axios.defaults.headers.common["utcOffset"] = new Date().getTimezoneOffset();
axios.defaults.headers.common["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
axios.defaults.headers.common["requestSource"] = "app.useworktivity.com";

Vue.prototype.$axios = axios;

if (localStorage.getItem("token") != null) {
  var currentToken = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = currentToken;
}

axios.interceptors.response.use(
  function (response) {
    store.commit('setValidationErrors', response.data.ValidationErrors);

    if (response.data.Message != null) {
      if (response.data.HasError) {
        Message.error(response.data.Message);
      } else {
        Message.success(response.data.Message);
      }
    }
    return response;
  },
  function (error) {
    if (!error.response) {
      Message.error("We couldn't reach to worktivity servers, please check your internet connection and refresh your screen.");
    } else if (401 === error.response.status) {
      store.dispatch("signOut", null);
      router.push("/").catch(() => {});
      return null;
    } else if (500 === error.response.status) {
      Message.error("Server error. Something went wrong.");
      return null;
    } else if (502 === error.response.status) {
      Message.info("We are currently under maintanence, please try again a few moments later.");
      router.push("/under-maintenance").catch(() => {});
      //TODO: Özkan - 502 sayfası yapılmalı.
      return null;
    } else if (404 === error.response.status) {
      Message.error("Not found");
      error.response = {
        ...error.response,
        Data: {
          HasError: true,
          Message: "The request you made could not be found. Please refresh the screen and try again."
        }
      };
      return error.response;
    } else if (403 === error.response.status) {
      Message.info("You don't have a permission to do this. You are redirected to \"My Activities\" screen.");
      error.response = {
        ...error.response,
        Data: {
          HasError: true,
          Message: "You don't have a permission to view this source."
        }
      };
      return error.response;
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function (config) {

  //Sadece bizim api ye gidenleri değiştiriyoruz, diğer yerlerde trello vs patlıyor.
  if (config.baseURL != null && config.baseURL.indexOf("api.useworktivity.com") != -1) {
    config.url = config.url.toLowerCase();
  }

  store.commit('setValidationErrors', []);
  return config;
});