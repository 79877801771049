<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement">
          <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id" :key="filter.ProjectId" />
        </div>
        <div class="filter-item" v-if="trackSettings.EnableProjectManagement && filter.ProjectId != null">
          <TaskSelectbox :projectId="filter.ProjectId" @changed="(id) => filter.ProjectTaskId = id" :key="filter.ProjectId" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager') && workTimes && workTimes.Items.some(x => x.Employee.Blocked == true)">
          <el-tooltip class="item" effect="dark" content="Toggle on to show blocked employees" placement="top">
            <el-switch v-model="filter.IncludeBlockedEmployees" active-text=""></el-switch>
          </el-tooltip>
        </div>
      </div>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5" v-if="!workTimes">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10" v-if="workTimes" v-loading="loading == 'getData'">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Total calculated time:</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge red no-cursor">{{ $parseTime($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), "TotalMins")) }}</span>
        </div>
      </div>

      <div class="progress-container">
        <div class="progress-item">
          <div class="progress-overview green">
            <i class="fas fa-bolt"></i>
            <div class="progress-info">
              <h6>Working</h6>
              <span>Total {{ $parseTime($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), "Working")) }}</span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12" :percentage="parseInt($percentage($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Working'), $sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'TotalMins')))" :color="'#45C4A0'"></el-progress>
          </div>
        </div>
        <div class="progress-item">
          <div class="progress-overview blue">
            <i class="fas fa-mug-hot"></i>
            <div class="progress-info">
              <h6>On break</h6>
              <span>Total {{ $parseTime($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), "OnBreak")) }}</span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12" :percentage="parseInt($percentage($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'OnBreak'), $sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'TotalMins')))" :color="'#54A8C7'"></el-progress>
          </div>
        </div>

        <div class="progress-item">
          <div class="progress-overview orange">
            <i class="fas fa-bed"></i>
            <div class="progress-info">
              <h6>Idle</h6>
              <span>Total {{ $parseTime($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), "Idle")) }}</span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12" :percentage="parseInt($percentage($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Idle'), $sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'TotalMins')))" :color="'#f78b77'"></el-progress>
          </div>
        </div>

        <div class="progress-item">
          <div class="progress-overview purple">
            <i class="fas fa-keyboard"></i>
            <div class="progress-info">
              <h6>Activity level</h6>
              <span v-if="$sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Working') > 0"><span>avg. <b>{{ parseInt($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'ActivityLevel') / $sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Working')) }}</b> sec. per min. </span></span>
              <span v-else><span>avg. <b>0</b> sec. per min. </span></span>
            </div>
          </div>
          <div class="progress-bar">
            <el-progress :text-inside="false" :stroke-width="12" :percentage="$sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Working') > 0 ? Math.round($percentage(($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'ActivityLevel') / $sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Working')), 60)) : 0" :color="$getAcitivtyLevelBadgeColor(Math.round($percentage(($sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'ActivityLevel') / $sum(workTimes.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'Working')), 60)))"></el-progress>
          </div>

        


        </div>


      </div>
    </div>

    <template v-if="workTimes && teams">
     <template  v-for="item in currentTeams" >
      <div class="card p-5 mb-10" :key="item.ID" v-loading="loading == 'getData'">
        <div class="card-header" :class="activeList.some(x => x == item.ID) ? '' : 'hr'">
        <div class="card-title">
          <h3><i class="fas fa-circle" :style="'color: ' + item.Color"></i> {{ item.Title }}</h3>
        </div>
        <div class="card-actions">
          <span class="big-badge blue" @click="toggleActiveList(item.ID)">{{item.EmployeeCount}} Employee</span>
        </div>
      </div>

      <div class="activity-table" :class="activeList.some(x => x == item.ID) ? 'hidden-list' : ''">
        <el-table :data="workTimes.Items.filter(x => x.Employee.TeamId == item.ID).filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true)" >

          <el-table-column label="Employee" min-width="250">
            <template slot-scope="scope">
              <EmployeeTablePreview :employee="scope.row.Employee" />
            </template>
          </el-table-column>

          <el-table-column label="Activity level" min-width="100" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :class="$getActivityLevelBadge($percentage(scope.row.ActivityLevel / scope.row.Working, 60))" v-if="scope.row.ActivityLevel != null && scope.row.ActivityLevel != 0">%{{ $percentage(scope.row.ActivityLevel / scope.row.Working, 60) }}</span>
              <span class="badge" style="opacity:.3" v-else>%0</span>
            </template>
          </el-table-column>

          <el-table-column label="Working hours" min-width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :style="scope.row.Working == 0 ? 'opacity:.3':''">{{ $parseTime(scope.row.Working) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Break time" min-width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :style="scope.row.OnBreak == 0 ? 'opacity:.3' : ''">{{ $parseTime(scope.row.OnBreak) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Idle times" min-width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :style="scope.row.Idle == 0 ? 'opacity:.3' : ''">{{ $parseTime(scope.row.Idle) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Late clock-in" min-width="150" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge green" v-if="scope.row.LateClockInCount == 0" style="opacity: .3;">{{ $parseTime(scope.row.LateClockIn) }}</span>
              <el-tooltip v-else class="item" effect="dark" :content="'Total ' + scope.row.LateClockInCount + ' times.'" placement="top">
               <span class="badge orange">{{ $parseTime(scope.row.LateClockIn) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>

        </el-table>
      </div>

    </div>
      </template>

       <div class="d-flex mb-5 justify-content-center">
        <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
          Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
        </el-button>
        <div class="d-flex align-items-center">
          <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
        </div>
       </div>

    </template>



  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import TaskSelectbox from '@/components/form/TaskSelectbox.vue';

import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';

export default {
    name: 'WorkTimesIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      ProjectSelectbox,
      TaskSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker,
    },

    data(){
        return {
            meta: {
              title: 'Work times',
              desc:'You can reach your organization work times insights here.'
            },
            loading:null,
            activeList: [],
            filter: {
              TeamId:null,
              EmployeeId:null,
              ProjectId:null,
              DateFilter:1,
              ProjectTaskId: null,
              StartDate:null,
              EndDate:null,
              IncludeBlockedEmployees:false
            }
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      
      await this.$store.dispatch("getSubscription");
      await this.$store.dispatch("getTeams");

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      await this.getData();

    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("workTimes", this.filter);
        this.loading = null;
      },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("workTimesExport", this.filter);
        if(res != null)
        {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      toggleActiveList(item) {
        if (this.activeList.some(x => x == item))
        {
            this.activeList = this.activeList.filter(x => x != item);
        } else {
            this.activeList.push(item);
        }
      },
      tableRowClassName({row, rowIndex}) {
        if (rowIndex === 1) {
          return 'orange-row';
        } else if (rowIndex === 3) {
          return 'green-row';
        }
        return '';
      },
    },
    computed: {
      account() {
          return this.$store.getters.getAccount;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
      organization() {
          return this.account.CurrentOrganization;
      },
      trackSettings() {
          return this.organization.TrackSettings;
      },
      currentTeams() {
        var result = [];
        if(!this.workTimes)
        {
          return result;
        }
        for (let i = 0; i < this.workTimes.Items.filter(x => this.filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true).length; i++) {
          const element = this.workTimes.Items.filter(x => this.filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true)[i];
          if(!result.some(x => x.ID == element.Employee.TeamId))
          {
            result.push(this.teams.Items.find(x => x.ID == element.Employee.TeamId));
          }
        }
        return result;
      },
      workTimes() {
        return this.$store.getters.getWorkTimes;
      },
      teams() {
        return this.$store.getters.getTeams;
      },
      employees() {
        return this.$store.getters.getEmployees;
      }
    },
    watch: {
      'filter.StartDate'() {
        this.getData();
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }
        this.getData();
      },
      'filter.EmployeeId'() {
        this.getData();
      },
      'filter.ProjectId'() {
        this.filter.ProjectTaskId = null;
        this.getData();
      },
      'filter.ProjectTaskId'() {
        this.getData();
      },
    },
}
</script>
