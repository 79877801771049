<template>
  <div class="container mt-10" id="pageTopToScroll">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
    
    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :clearable="true" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager')">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item">
          <LeaveRightTypeSelectbox :nullable="true" :selectedId="filter.Type" @changed="(id) => filter.Type = id" />
        </div>
      </div>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>

      <el-button round icon="fas fa-plus" @click="addModal = true">Add</el-button>
    </div>

    <div class="card p-5" v-if="!leaveRights">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5" id="activityHistoryTable" v-else>
      <div class="activity-table" >

        <NoData v-if="leaveRights.TotalCount == 0" />
        <el-table :data="leaveRights.Items" v-if="leaveRights.TotalCount > 0" v-loading="loading == 'getData'" ref="tab" @row-click="expand" :row-class-name="'cursor-pointer'">

          <el-table-column label="Employee" min-width="250">
            <template slot-scope="scope">
              <EmployeeTablePreview :employee="scope.row.Employee" />
            </template>
          </el-table-column>

          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="d-flex">
                <div class="f-flex flex-column" v-if="props.row.CreatedByEmployee != null">
                  <p>Created by: </p>
                  <EmployeeTablePreview :employee="props.row.CreatedByEmployee" />
                  </div>
              </div>
              <p class="mt-2" v-if="props.row.Notes != null && props.row.Notes != ''">Notes: {{ props.row.Notes }}</p>
            </template>
          </el-table-column> 

          <el-table-column label="Type" class-name="text-center" width="120">
            <template slot-scope="scope">
              <el-tooltip placement="left"  :disabled="![3,4].some(x => x == scope.row.Type)" :content="$getEnumTitle('LeaveRightType', scope.row.Type)">
                <span class="badge" :class="getBadgeBg(scope.row.Type)">{{ $getEnumTitle('LeaveRightType', scope.row.Type) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="Count" class-name="text-center" width="150">
            <template slot-scope="scope">
              <span class="badge">{{ scope.row.Count }} day(s)</span>
            </template>
          </el-table-column>

          <el-table-column label="Created at" width="150" class-name="text-center">
            <template slot-scope="scope">
              <span>{{ moment(scope.row.CreateDate).format("DD.MM.yyyy HH:mm") }}</span>
            </template>
          </el-table-column>
          
          <el-table-column width="60">
            <template slot-scope="scope" >
              <el-tooltip class="item" effect="dark" :content="'Delete'" placement="top">
                <span>
                  <el-button type="danger" icon="fas fa-trash" size="small" @click.stop="deleteLeaveRight(scope.row.ID)" circle :loading="loading == 'delete-' + scope.row.ID"></el-button>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>

          
        </el-table>
      </div>

      <Pagination
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="leaveRights.TotalCount"  
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />
    </div>

      <el-dialog :close-on-click-modal="false" class="medium" title="Add leave right" :visible.sync="addModal" center modal-append-to-body destroy-on-close>
      <LeaveRightAddUpdateModal @leaveRightCreated="getData" @closeDialog="addModal = false" v-if="addModal" />
    </el-dialog>

    
    <div class="d-flex mb-5 justify-content-center mt-5" v-if="leaveRights && leaveRights.TotalCount > 0">
      <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
        Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
      </el-button>
      <div class="d-flex align-items-center">
        <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
      </div>
      </div>

  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import moment from "moment/min/moment-with-locales";

import Pagination from '@/components/Pagination';
import SearchInput from '@/components/form/SearchInput.vue';

import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import LeaveRightTypeSelectbox from '@/components/form/LeaveRightTypeSelectbox.vue';


import LeaveRightAddUpdateModal from './LeaveRightAddUpdateModal.vue';


export default {
    name: 'LeaveSettingsIndex',
    components: {
        Breadcrumb,
        Pagination,
        SearchInput,
        TeamSelectbox,
        EmployeeSelectbox,
        DateTypeFilterSelectbox,
        LeaveRightAddUpdateModal,
        LeaveRightTypeSelectbox
    },
    data(){
        return {
          loading:null,
          moment,
          meta: {
            title: 'Leave rights',
            desc:'You can manage your employee\'s leave rights here.'
          },
          addModal:false,
          filter: {
            SearchTerm:null,
            TeamId:null,
            EmployeeId:null,
            Page: 1,
            PageSize:12,
            StartDate:null,
            EndDate:null,
            Type:null,
          },
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.$store.dispatch("getSubscription");
      await this.getData();

    },
    methods: {
      getBadgeBg(status) {
        switch (status) {
          case 0:
            return "blue";
          case 1:
            return "orange";
          case 2:
            return "yellow";
          default:
            break;
        }
      },
      expand(row, isExpanded) {
        if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
        {
          this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
        }
        else
        {
          this.$refs.tab.store.states.expandRows.push(row);
        }
      },
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("leaveRights", this.filter)
        this.loading = null;
      },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("leaveRightsExport", this.filter);
        if(res != null)
        {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      async deleteLeaveRight(id) {
         this.$confirm('You are about to delete this leave right. This action can not be undo, are you sure about this?', 'Confirmation', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          this.loading = 'delete-' + id ;
          await this.$store.dispatch("deleteLeaveRight", {Id: id});
          //await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
    },
    computed: {
      leaveRights() {
        return this.$store.getters.getLeaveRights;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
    },
    watch: {
      'filter.StartDate'() {
        if (this.filter.Page != 1) {
          this.filter.Page = 1;
        }
        else {
          this.getData();
        }
      },
      'filter.TeamId'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Type'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.EmployeeId'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.SearchTerm'() {
        this.filter.Page = 1;
        this.getData();
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("pageTopToScroll");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("pageTopToScroll");
      }
    }
}
</script>
