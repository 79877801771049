<template>
      <el-date-picker @change="selectionChanged" clearable @clear="clearSelection"
            v-model="id"
            type="date"
            format="dd MMMM yyyy"
            :placeholder="pickerPlaceholder">
          </el-date-picker>
</template>

<script>

export default {
    name: 'DatePicker',
    props: ["selectedId", "placeholder"],
    data(){
        return {
          id:null,
          pickerPlaceholder: "Pick a date"
        }
    },
    mounted() {
      this.id = this.selectedId;
      if(this.placeholder != null)
      {
        this.pickerPlaceholder = this.placeholder;
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      }
    }
}
</script>
