<template>
    <div class="login-page">
        
        <div class="card login-card shadow-md">
            <div class="login-hero">
                <div class="login-text">
                    <h1>Sign up</h1>
                    <p>You dont have a account? Signup now, its free.</p>
                </div>
            </div>

            <div class="login-form">
                <div class="form-header" v-if="invitation == null">
                    <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="dark-logo"/>
                    <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="white-logo"/>
                    <h3>Create an account</h3>
                    <p>Your 7 days trial will be started automatically. No credit card required.</p>
                </div>
                <div class="form-header" v-else>
                    <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="dark-logo"/>
                    <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="white-logo"/>
                    <h3>{{ invitation.Organization.Title }}</h3>
                    <p>You are invited you to join {{ invitation.Organization.Title }}'s {{ invitation.Team.Title }}</p>
                </div>

                <el-form :label-position="'top'" label-width="100px" :model="formData">
                    <el-form-item label="Name">
                        <el-input v-model="formData.FirstName" type="text" placeholder="Jane" clearable></el-input>
                        <ValidationErrorItem validationKey="FirstName" />
                    </el-form-item>
                    <el-form-item label="Surname">
                        <el-input v-model="formData.LastName" type="text" placeholder="Doe"  clearable></el-input>
                        <ValidationErrorItem validationKey="LastName" />
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="formData.Email" type="email" placeholder="someone@example.com" clearable></el-input>
                        <ValidationErrorItem validationKey="Email" />
                    </el-form-item>
                    <el-form-item label="Password">
                        <el-input v-model="formData.Password" type="password" placeholder="******" show-password></el-input>
                        <ValidationErrorItem validationKey="Password" />
                    </el-form-item>
                    <el-form-item label="Password (Retype)">
                        <el-input v-model="formData.PasswordRetry" type="password" placeholder="******" show-password></el-input>
                        <ValidationErrorItem validationKey="PasswordRetry" />
                    </el-form-item>
                    <el-form-item>
                        <div class="form-aggreements">
                            <el-checkbox v-model="formData.Aggrements"> I've ready and accept worktivity <a href="javascript:;" @click="termsDialog=true">terms of use</a> and <a href="javascript:;" @click="privacyDialog=true">privacy policy</a>.</el-checkbox>
                        </div>
                    </el-form-item>

                    <el-form-item class="text-center" v-if="validateMe" style="min-height:73px">
                        <cfturnstile
                        sitekey="0x4AAAAAAAHfPtMmKhNgJWQa"
                        @verify="verify"
                        />
                        <ValidationErrorItem validationKey="CaptchaToken" />
                    </el-form-item>

                    
                    
                    <el-form-item class="login-button">
                        <el-button type="primary" :loading="loading == 'signUp'" @click="signUp" round>Create account</el-button>
                    </el-form-item>
                </el-form>

                <div class="forgot-button">
                    <el-button type="text" @click="$router.push({ name: 'forgotpassword' })">Forgot Password?</el-button>
                </div>
                <div class="seperator">
                    <span>or</span>
                </div>
                <div class="signup-button">
                    <span> Are you already have an account? <el-button type="text" @click="$router.push({ name: 'login' })">Sign in</el-button> </span>
                </div>
                <div class="d-flex justify-content-center">
                    <label for="theme" class="theme">
                        <span class="theme__toggle-wrap">
                            <input type="checkbox" class="theme__toggle" id="theme" role="switch" v-model="$store.state.darkMode" name="theme" value="dark" />
                            <span class="theme__icon">
                            <span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span>
                            </span>
                        </span>
                    </label>
                </div>
            </div>

            <el-dialog
            class="policy-dialog-register"
            title="Terms & Conditions"
            :visible.sync="termsDialog"
            width="30%">
            
            <iframe src="https://useworktivity.com/legal/terms-conditions?policyView=1" frameborder="0" style="width:100%;height:300px;"></iframe>

            <span slot="footer" class="dialog-footer">
                <el-button @click="termsDialog = false">Close</el-button>
            </span>
            </el-dialog>

            <el-dialog
            class="policy-dialog-register"
            title="Privacy Policy"
            :visible.sync="privacyDialog"
            width="30%">
            
            <iframe src="https://useworktivity.com/legal/privacy-policy?policyView=1" frameborder="0" style="width:100%;height:300px;"></iframe>

            <span slot="footer" class="dialog-footer">
                <el-button @click="privacyDialog = false">Close</el-button>
            </span>
            </el-dialog>

        </div>
    </div>
</template>

<script>

import Turnstile from 'cfturnstile-vue2'

export default {
    name: 'RegisterIndex',
    components: {
        'cfturnstile': Turnstile
    },
    data(){
        return {
            meta: {
                title: 'Create an account',
                desc: 'You dont have a account? Signup now, its free.'
            },
            termsDialog:false,
            privacyDialog:false,
            loading:null,
            invalidInvitationCode:false,
            validateMe:true,
            formData: {
                InvitationCode:null,
                FirstName: null,
                LastName: null,
                Email: null,
                Password: null,
                PasswordRetry: null,
                Aggrements: false,
                CaptchaToken:null
            },
            invitation:null
        }
    },
    async mounted() {
        this.$setMeta(this.meta);
        
        if(this.$route.query.invitationCode){
            this.formData.InvitationCode = this.$route.query.invitationCode;
            await this.checkInvitationCode();
        }
    },
    methods: {
        verify(token) {
            this.formData.CaptchaToken = token;
        },
        async signUp() {
            if(!this.formData.Aggrements)
            {
                this.$message.info("Please confirm the aggreements before you proceed.");
                return;
            }

            this.loading = 'signUp';
            var res = await this.$store.dispatch("signUp", this.formData);
            if(res != null)
            {
                var acc = await this.$store.dispatch("getAccount");

                this.$gtag.event('sign_up', {
                    method: 'Email'
                    //'user_id': acc.User.ID, 
                    // 'user_data': {
                    //     'email': acc.User.Email,
                    //     'address': {
                    //         'first_name': acc.User.FirstName,
                    //         'last_name': acc.User.LastName,
                    //     }
                    // }
                });

                this.$router.push({name: 'my-activities'});
            }
            else
            {
                this.validateMe = false;
                setTimeout(() => {
                    this.validateMe = true;
                },100);
            }
            this.loading = null;
        },
        async checkInvitationCode() {

            

            this.loading = 'signUp';
            var res = await this.$store.dispatch("checkInvitationCode", this.formData);
            if(res == null)
            {
                this.invalidInvitationCode = true;
            }
            else
            {
                this.formData.FirstName = res.Employee.Invitation.FirstName;
                this.formData.LastName = res.Employee.Invitation.LastName;
                this.formData.Email = res.Employee.Invitation.Email;
                this.invitation = res;
            }
            this.loading = null;
        }
    }
}
</script>
