<template>
  <div id="app" :class="$store.state.darkMode ? 'darkmode' : ''">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  async created(){
    var themeControl = localStorage.getItem("darkMode");
    if(themeControl != null && themeControl != undefined)
    {
      this.$store.state.darkMode = (themeControl == "true");
      this.switchDarkMode();
    }
    else
    {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$store.state.darkMode = true;
        this.switchDarkMode();
      }
    }
  },
  methods: {
    switchDarkMode() {
      if (this.$store.state.darkMode == true) {
        document.body.classList.add('dark-body');
        localStorage.setItem("darkMode", true);
      } else {
        document.body.classList.remove('dark-body');
        localStorage.setItem("darkMode", false);
      }
    }
  },
  watch: {
    '$store.state.darkMode'() {
      this.switchDarkMode();
    }
  }
}
</script>

<style lang="less">
  @import url("./assets/css/style.less");
  @import url("./assets/css/responsive.less");
</style>
