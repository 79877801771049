<template>
    <div class="account-wrapper" v-if="account && enums && timer">
        <div id="navbar" class="navbar-wrapper">
            <NavBar />
        </div>
        <div id="abody" class="account-body">
            <div class="sidebar-wrapper" :class="showMenu ? '' : 'hidden'" v-click-outside="onClickOutside">
                <SideBar />
            </div>
            <vue-page-transition name="fade" class="content-wrapper">
                <router-view></router-view>
            </vue-page-transition>
        </div>

        <el-drawer class="profile-drawer" :visible.sync="showProfileDrawer" type="primary" :direction="'rtl'" :withHeader="false" append-to-body destroy-on-close :show-close="false" :close-on-press-escape="false" size="60%" :before-close="handleProfileDrawerClose" >
            <TeamMemberProfile v-if="showProfileDrawer" />
        </el-drawer>

    </div>
    <div v-else>    
        <div class="loading-screen">
            <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="dark-logo"/>
            <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="white-logo"/>
            <p v-html="motto"></p>
        </div>
    </div>
</template>

<script>
import NavBar from './partials/NavBar';
import SideBar from './partials/SideBar';
import TeamMemberProfile from './partials/TeamMemberProfile';
import vClickOutside from 'v-click-outside'

export default {
    name: 'AccountMain',
    components: {
        NavBar,
        SideBar,
        TeamMemberProfile
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
        return {
            timer:false,
            mottos: [
                "Discipline and focus for excellence in productivity. 💪",
                "Aim for productivity with small steps, achieve big results. 🚀",
                "Make the most of your time, productivity leads to success. ⏱️",
                "Work smarter, faster, and more efficiently - productivity sets you apart. 🌟",
                "Productivity is the invaluable fuel for your journey to success. 🔥"
            ],
            motto: null,
            accountDrawer: true,
        }
    },
    async created() {
        await Promise.all([
        await this.$store.dispatch("getEnums"),
        await this.$store.dispatch("getAccount")
        ]);
        
    },
    mounted() {
        this.motto = this.mottos[Math.floor(Math.random() * this.mottos.length)]
        setTimeout(() => {
            this.timer = true;
            this.$nextTick(() => {
                var navbar = document.getElementById("navbar");
                var scrollbody = document.getElementById("app");
                var abody = document.getElementById("abody");

                scrollbody.addEventListener('scroll',(event) => {
                    try {
                        if (navbar != null && scrollbody.scrollTop > navbar.offsetTop) {
                            navbar.classList.add("sticky");
                            abody.classList.add("ptf");
                        } else {
                            navbar.classList.remove("sticky");
                            abody.classList.remove("ptf");
                        }
                    } catch (error) {
                        
                    }
                });
            });
        }, 1000 * 1);

        
    },
    methods: {
        getProfileSize() {
            return window.innerWidth < 762 ? '100%' : '60%';
        },
        handleProfileDrawerClose() {
            this.$store.commit("setProfileEmployeeId", {id:null, showDrawer: false});
        },
        onClickOutside (event) {
            if (this.showMenu) {
                console.log('Clicked outside. Event: ', event);
                this.$store.dispatch('getShowMenu', false);
            }
        }
    },
    computed: {
      showProfileDrawer() {
        return this.$store.getters.getProfileShowDrawer;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      enums() {
        return this.$store.getters.getEnums;
      },
      showMenu() {
            return this.$store.getters.getShowMenu;
        },
    },
}
</script>

<style lang="less">
    .loading-screen {
        width:100%;
        height: 100vh;
        margin:0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
            margin-top:30px;
            text-align: center;
        }
        img {
            width:200px;
        }
    }
</style>