<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="card p-5 mb-10">
      <div class="organisation-settings-wrapper">
        <el-form :label-position="'top'" label-width="100px" :model="formData" 
          @submit.native.prevent="false" 
          @keyup.enter.native="createOrganization"
          >
          <el-form-item label="What is your organization name?" required>
            <el-input v-model="formData.Title" placeholder="Acme .Inc" required clearable></el-input>
            <ValidationErrorItem validationKey="Title" />
          </el-form-item>

          <el-form-item label="What is your organization's industry?" required>
            <IndustrySelectbox @changed="(id) => formData.Industry = id" />
            <ValidationErrorItem validationKey="Industry" />
            </el-form-item>

          <el-form-item label="What is your main expectation from using Worktivity?" required>
          <OrganizationExpectationSelectbox @changed="(id) => formData.Expectation = id" />
          <ValidationErrorItem validationKey="Expectation" />
          </el-form-item>


          <el-form-item class="tracking-buttons">
            <el-button :loading="loading == 'create-organization'" type="primary" icon="fas fa-check" round @click="createOrganization">Create organization</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import axios from "axios"

import OrganizationExpectationSelectbox from '@/components/form/OrganizationExpectationSelectbox.vue';
import IndustrySelectbox from '@/components/form/IndustrySelectbox.vue';

export default {
    name: 'CreateOrganizationIndex',
    components: {
      Breadcrumb,
      OrganizationExpectationSelectbox,
      IndustrySelectbox
    },

    data(){
      return {
        meta: {
          title: 'Create new organization',
          desc:'You can create a new organization here.'
        },
        loading:null,
        formData: {
          Title: null,
          Expectation:null,
          Industry:null
        }
      }
    },
    mounted() {
      this.$setMeta(this.meta);
    },
    methods: {
      async createOrganization() {
        this.loading = 'create-organization';
        var res = await this.$store.dispatch("createOrganization",this.formData);

        if(res != null)
        {
          var token = "Bearer " + res.Token;
          localStorage.setItem("token", token);
          axios.defaults.headers.common.Authorization = token;
          window.location.href = '/app/account/manage-subscription';
        }

        this.loading = null;
      }
    }
}
</script>
