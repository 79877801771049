<template>
    <div class="custom-userselect" @click.stop="show == null ? show = id : show = null" :style="drawer == true ? 'align-items: flex-start; justify-content: flex-start; width: 100%; position: relative; max-width:500px;' : ''">
      <div class="selected-users-box">
        <el-tooltip effect="dark"  placement="top" :content="getTooltipTitle">
           <div v-if="selectedIds.length > 0">
            <img :src="getEmployeeById(item) != null ? $imageus(getEmployeeById(item).User.Image,'50','50','cover') : ''" alt="" v-for="item in getIds" :key="item">
        </div>
        </el-tooltip>
        
        <el-tooltip effect="dark" content="Add assignee" placement="top">
          <div class="add-user-item">
            <i class="fas fa-plus"></i>
          </div>
        </el-tooltip>
      </div>
      <div class="select-user-box shadow-lg" :class="position" v-if="show == id" v-click-outside="onClickOutside" @click.stop>
        <el-input placeholder="Search..." prefix-icon="el-icon-search" size="medium" v-model="search"> </el-input>
        <div class="user-list">
          <div class="user-list-item" v-for="item in search != null && search != '' ? filteredItems : employees.Items.filter(x => x.User != null && x.Blocked != true)" :key="item.ID">
            <div class="user-item" v-if="item.User != null" @click.stop="toggleAssign(item.ID)">
              <div class="user-picture">
                <img :src="$imageus(item.User.Image,'50','50','cover')" alt="">
              </div>
              <div class="user-details">
                <span class="user-name">{{ item.User.FirstName + ' ' }}<b>{{ item.User.LastName }}</b></span>
                <span><i class="fas fa-circle" :style="'color: ' + teams.Items.find(x => x.ID == item.TeamId)?.Color"></i>{{ teams.Items.find(x => x.ID == item.TeamId)?.Title }}</span>
              </div>
            </div>
            <div class="user-actions" v-if="selectedIds.some(x => x == item.ID)">
              <el-button type="primary" size="mini" @click.stop="toggleAssign(item.ID)">Assigned</el-button>
            </div>
            <div class="user-actions" v-else>
              <el-button type="secondary" size="mini" @click.stop="toggleAssign(item.ID)">Assign</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
    name: 'UserSelect',
    props: ["ids","id","position", "drawer"],
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
      return {
        search: '',
        show: null,
        selectedIds:[],
        filteredItems:[]
      };
    },
    
    async beforeMount() {
      if(this.ids)
      {
        var activeEmployeeIds = this.employees.Items.filter(x => x.Blocked != true).map(x => x.ID);
        var tempSelectedIds = JSON.parse(JSON.stringify(this.ids));
        tempSelectedIds = tempSelectedIds.filter(x => activeEmployeeIds.some(y => y == x));
        this.selectedIds = tempSelectedIds;
      }

      if(!this.teams || !this.employees)
      {
        await this.$store.dispatch("getTeams");
        await this.$store.dispatch("getEmployees");
      }
    },
    methods: {
      async toggleAssign(id) {
        var index = this.selectedIds.findIndex(x => x == id);
        if(index != -1)
        {
          this.selectedIds = this.selectedIds.filter(x => x != id);
        }
        else
        {
          this.selectedIds.push(id);
        }

        await this.$store.dispatch("updateTaskAssignees", {
          Id:this.id,
          AssigneeIds: this.selectedIds
        });

        this.$emit("changed", this.selectedIds);
      },
      
      onClickOutside (event) {
            if (this.show) {
                //console.log('Clicked outside. Event: ', event);
                this.show = null
            }
        },
        getEmployeeById (id) {
          return this.employees.Items.find(x => x.Blocked != true && x.ID == id);
        }
    },
    computed: {
      getTooltipTitle() {
        var result = '';
        for (let i = 0; i < this.selectedIds.length; i++) {
          var employee = this.getEmployeeById(this.selectedIds[i]);
          if(employee != null)
          {
            result += employee.User.FirstName + ' ' + employee.User.LastName;
          }

          if(i < this.selectedIds.length - 1)
          {
            result += ', ';
          }
        }
        return result;
      },
      getIds() {
        if(this.selectedIds.length > 3)
        {
          return this.selectedIds.slice(this.selectedIds.length - 3, this.selectedIds.length);
        }
        else
        {
          return this.selectedIds;
        }
      },
      employees() {
        return this.$store.getters.getTaskEmployees;
      },
      teams() {
        return this.$store.getters.getTaskTeams;
      }
    },
    watch: {
      'search'() {
        const searchRegex = new RegExp(this.$toSearchRegex(this.search));
        console.log(this.employees);

        this.filteredItems = this.employees.Items.filter((item) =>
          item.User != null && 
          item.Blocked != true &&
          (
            searchRegex.test(item.User.FirstName) ||
            searchRegex.test(item.User.LastName) ||
            searchRegex.test(item.User.FirstName + ' ' + item.User.LastName) ||
            searchRegex.test(item.User.Title)
          )
        );
      },
     }
}

</script>
