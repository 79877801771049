<template>
   <div class="timesheet-user-card cursor-pointer" @click.stop='$store.commit("setProfileEmployeeId", { id: employee.Id, showDrawer: true })'>
    <div class="user-image" style="position:relative">
      <el-avatar :size="36" :src="employee.User != null ? employee.User.Image : employee.Image" :alt="employee.User.FirstName" />
      <i class="fas fa-lock text-red locked-employee-table-preview" v-if="employee.Blocked == true"></i>
    </div>
    <div class="user-info">
      <el-tooltip :disabled="!$isNotNullOrWhiteSpace(employee.EmployeeCode)" :content="employee.EmployeeCode" placement="top">
        <div class="user-name">
          {{ employee.User != null ? employee.User.FirstName : employee.FirstName }}
          <b>{{ employee.User != null ? employee.User.LastName : employee.LastName }}</b> 
        </div>
      </el-tooltip>
      <div class="user-team">
        <i class="fas fa-circle" :style="'color:' + employee.Team.Color + ';'"></i>
        {{ employee.Team.Title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EmployeeTablePreview',
    props: ["employee"],
    
    data() {
      return {
        
      };
    },
}

</script>
