<template>
      <el-select v-model="id" filterable placeholder="All teams" multiple="" v-if="teams" @change="selectionChanged" clearable @clear="clearSelection">
        <el-option
          v-for="item in teams.Items"
          :key="item.ID"
          :label="item.Title"
          :value="item.ID">
          <div class="d-flex align-items-center">
            <span><i class="fas fa-circle" :style="'color: ' + item.Color"></i> {{ item.Title }}</span>
          </div>
        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'TeamSelectbox',
    props: ["selectedId"],
    data(){
        return {
          id:[],
        }
    },
    mounted() {
      if(this.selectedId)
      {
        this.id = this.selectedId;
      }
      

      if(this.teams == null)
      {
        this.getData();
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
      async getData() {
        await this.$store.dispatch("getTeams");
      }
    },
    computed: {
      teams() {
        return this.$store.getters.getTeams;
      }
    }
}
</script>
