
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-blue p-5 mb-3">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <span>It may take up to 15 minutes for the changes you have made to synchronize.</span>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save">

        <el-form-item label="" required>
          <el-switch
            v-model="formData.EnableCustomTrackSettings"
            active-text="Enable custom track settings"
            inactive-text="">
          </el-switch>
          <ValidationErrorItem validationKey="EnableCustomTrackSettings" />
        </el-form-item>

        <el-form-item label="" required v-if="formData.EnableCustomTrackSettings">
          <el-switch
            v-model="formData.EnableScreenshots"
            active-text="Enable screenshots"
            inactive-text="">
          </el-switch>
          <ValidationErrorItem validationKey="EnableScreenshots" />
        </el-form-item>

        <el-form-item label="" required v-if="formData.EnableScreenshots">
          <el-switch
            v-model="formData.BlurScreenshots"
            :disabled="sub.Subscription == null || sub.Subscription.Plan.ScreenshotBlur != 0"
            active-text="Blur screenshots"
            inactive-text="">
          </el-switch>
          <span class="badge badge-sm blue cursor-pointer  ml-2" @click="goToBilling" v-if="sub.Subscription == null || sub.Subscription.Plan.ScreenshotBlur != 0">UPGRADE NOW</span>
          <ValidationErrorItem validationKey="BlurScreenshots" />
        </el-form-item>

        <el-form-item label="" required v-if="formData.EnableCustomTrackSettings">
          <el-switch
            v-model="formData.EnableTimelapseVideos"
            :disabled="sub.Subscription == null || sub.Subscription.Plan.TimelapseVideos != 0"
            active-text="Enable timelapse videos"
            inactive-text="">
          </el-switch>
          <span class="badge badge-sm blue cursor-pointer  ml-2" @click="goToBilling" v-if="sub.Subscription == null || sub.Subscription.Plan.TimelapseVideos != 0">UPGRADE NOW</span>
          <ValidationErrorItem validationKey="EnableTimelapseVideos" />
        </el-form-item>

        <el-form-item label="" required v-if="formData.EnableCustomTrackSettings">
          <el-switch
            v-model="formData.EnableGhostMode"
            :disabled="sub.Subscription == null || sub.Subscription.Plan.GhostMode != 0"
            active-text="Enable background mode"
            inactive-text="">
          </el-switch>
          <span class="badge badge-sm blue cursor-pointer  ml-2" @click="goToBilling" v-if="sub.Subscription == null || sub.Subscription.Plan.GhostMode != 0">UPGRADE NOW</span>
          <ValidationErrorItem validationKey="EnableGhostMode" />
        </el-form-item>

        <div class="card bg-blue p-5 mt-3" v-if="formData.EnableGhostMode">
          <div class="card-alert">
            <i class="fas fa-info-circle"></i>
            <div>
              <span>The use of this feature may vary according to the legal conditions in your country.</span>
            </div>
          </div>
        </div>

        <el-form-item label="" required v-if="formData.EnableCustomTrackSettings">
          <el-switch
            v-model="formData.EnableAutoClockIn"
            :disabled="sub.Subscription == null || sub.Subscription.Plan.AutoClockIn != 0"
            active-text="Enable auto clock-in"
            inactive-text="">
          </el-switch>
          <span class="badge badge-sm blue cursor-pointer  ml-2" @click="goToBilling" v-if="sub.Subscription == null || sub.Subscription.Plan.AutoClockIn != 0">UPGRADE NOW</span>
          <ValidationErrorItem validationKey="EnableAutoClockIn" />
        </el-form-item>

        <el-form-item label="Screen capture interval" required v-if="formData.EnableScreenshots">
          <el-select v-model="formData.ScreenCaptureIntervalMins" clearable placeholder="Select...">
            <el-option v-for="item in 60" :key="item" :value="item">
              <span style="float: left">Every {{ item }} minutes</span>
            </el-option>
          </el-select>
          <ValidationErrorItem validationKey="captureInterval" />
        </el-form-item>
      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          Save changes
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EditTrackSettingsModal',
    props: ["data"],
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            EmployeeId: null,
            EnableCustomTrackSettings: false,
            EnableScreenshots: false,
            BlurScreenshots: false,
            EnableTimelapseVideos: false,
            EnableGhostMode:false,
            EnableAutoClockIn:false,
            ScreenCaptureIntervalMins: 5
          }
        }   
    },
    async mounted() {
      if(this.data)
      {
        this.formData = this.data;
      }

      await this.$store.dispatch("getSubscription");
    },
    methods: {
      goToBilling() {
        this.$router.push({ name: 'billing' })
      },
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("updateEmployeeTrackSettings", this.formData);
       
        this.loading = null;
        if(res != null)
        {
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
    },
    watch: {
      'formData.EnableCustomTrackSettings'() {
        if(this.formData.EnableCustomTrackSettings == false)
        {
          this.formData.EnableScreenshots = false;
          this.formData.BlurScreenshots = false;
          this.formData.EnableTimelapseVideos = false;
          this.formData.EnableGhostMode = false;
          this.formData.EnableAutoClockIn = false;
          this.formData.ScreenCaptureIntervalMins = 5
        }
      },
      'formData.EnableScreenshots'() {
        if(this.formData.EnableScreenshots == false)
        {
          this.formData.BlurScreenshots = false,
          this.formData.ScreenCaptureIntervalMins = 5
        }
      }
    }
}
</script>

<style>

</style>