<template>
      <el-select v-model="id" remote :remote-method="remoteMethod" :loading="loading == 'getData'" filterable placeholder="All tasks" v-if="tasks" @change="selectionChanged" clearable @clear="clearSelection">
        <el-option
          v-for="item in tasks.Items"
          :key="item.ID"
          :label="item.Title"
          :value="item.ID">
           <div class="d-flex align-items-center">
            <el-tooltip class="item" effect="dark" :content="item.Title" placement="right">
              <span style="text-overflow: ellipsis;overflow: hidden;width: 200px; white-space: nowrap;">{{ item.Title }}</span>
            </el-tooltip>

            
          </div>
        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'TaskSelectbox',
    props: ["projectId"],
    data(){
        return {
          id:null,
          tasks:{
            Items:[]
          },
          loading:null,
          filter: {
            Page: 1,
            PageSize:15,
            SearchTerm:null,
            ExcludeTimeTracked:true,
            ProjectId:null
          }
        }
    },
    mounted() {
      this.filter.ProjectId = this.projectId;
      this.getData();
    },
    methods: {
      async remoteMethod(term) {
        this.loading = 'getData';
        if (term !== '') {
          this.filter.SearchTerm = term;
        } else {
           this.filter.SearchTerm = null;
        }
        await this.getData();
        this.loading = null;
      },
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
      async getData() {
        var res = await this.$store.dispatch("searchTasks", this.filter);
        if(res != null)
        {
          this.tasks = res;
        }
      }
    }
}
</script>
