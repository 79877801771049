<template>
  <div class="task-wrapper d-flex flex-column justify-content-between h-100" style="height: 100vh;">

    <div class="task-body" style="height: calc(100vh-112px); overflow-y: auto;">
      <div class="task-header d-flex pb-2 mb-5">
        <div>
          <el-button type="info" size="mini" @click="markAsComplete" :loading="loading == 'markAsComplete'"
            :key="'task-status-' + (task != null ? task.Status : 'null')"
            v-if="task != null && task.Id != null && task.Status != 1">Mark as complete</el-button>

          <el-button type="success" size="mini" @click="markAsTodo" :loading="loading == 'markAsTodo'"
            :key="'task-status-' + (task != null ? task.Status : 'null')"
            v-if="task != null && task.Id != null && task.Status == 1">Completed</el-button>


          <el-tooltip v-if="task != null && task.Id != null" class="item" effect="dark"
            content="Click to copy current task link to share your team members." placement="bottom">
            <el-button icon="fa fa-copy" type="secondary" size="mini"
              @click="$copyToClipboard('https://app.useworktivity.com/app/project-management/tasks?viewTask=' + task.Id)"></el-button>
          </el-tooltip>

          <el-tooltip v-if="task != null && task.Id != null" class="item" effect="dark" content="Sync data"
            placement="top">
            <el-button size="mini" type="link" icon="fas fa-sync" @click="refreshContent"
              :loading="loading == 'getData'"></el-button>
          </el-tooltip>

          <el-tooltip v-if="task != null && task.Id != null" class="item" effect="dark"
            content="Click to delete this task." placement="bottom">
            <el-button icon="fa fa-trash" type="danger" size="mini" @click="deleteTask"
              :loading="loading == 'deleteTask'"></el-button>
          </el-tooltip>

        </div>
        <div class="modal-close-icon" v-on:click="closeMe"><i class="fa fa-times"></i></div>
      </div>
      <div class="task-details">
        <div>
          <div class="d-flex mb-3 justify-content-between">
            <p class="task-drawer-label">Project <span class="text-danger">*</span></p>
            <div class="w-100">
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-if="!mountCompleted" />
              <template v-else>
                <ProjectSelectbox :selectedId="task.ProjectId" @changed="(id) => task.ProjectId = id"
                  :key="'project-id-'+task.ProjectId" />
              </template>
              <ValidationErrorItem validationKey="ProjectId" />
            </div>
          </div>

          <div class="d-flex mb-3 justify-content-between">
            <p class="task-drawer-label">Task <span class="text-danger">*</span></p>
            <div class="w-100">
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-if="!mountCompleted" />
              <template v-else>
                <el-input type="text" v-model="task.Title"  @change="taskTitleBlur" :placeholder="task.Id == null ? 'Fill task details and press enter...' : '...'"
                  :disabled="task.ProjectId == null"></el-input>
              </template>
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-else />
              <ValidationErrorItem validationKey="Title" />
            </div>
          </div>
          <div class="d-flex mb-3 justify-content-end" v-if="task.Id == null && task.ProjectId != null">
            <el-button type="primary">Create task</el-button>
          </div>

          <div class="d-flex mb-3 justify-content-between" v-if="task.Id != null">
            <p class="task-drawer-label">Due date</p>
            <div class="w-100">
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-if="!mountCompleted" />
              <template v-else>
                <el-date-picker :disabled="task.Id == null" v-model="task.DueDate" type="datetime"
                  format="dd.MM.yyyy HH:mm" placeholder="Select date and time">
                </el-date-picker>
              </template>
              <ValidationErrorItem validationKey="DueDate" />
            </div>
          </div>

          <div class="d-flex mb-3 justify-content-between" v-if="task.Id != null">
            <p class="task-drawer-label">Status</p>
            <div class="w-100">
              <ProjectTaskStatusSelectbox :selectedId="task.Status" @changed="(id) => task.Status = id"
                :key="'status-id-'+task.Status" v-if="mountCompleted" />
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-else />
            </div>
          </div>

          <div class="d-flex mb-3 justify-content-between" v-if="task.Id != null">
            <p class="task-drawer-label">Priority</p>
            <div class="w-100">
              <ProjectTaskPrioritySelectbox :selectedId="task.Priority" @changed="(id) => task.Priority = id"
                :key="'priority-id-'+task.Priority" v-if="mountCompleted" />
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-else />
            </div>
          </div>

          <div class="d-flex mb-3 justify-content-between" v-if="task.Id != null">
            <p class="task-drawer-label">Assignee(s)</p>
            <div class="w-100">
              <UserSelect :ids="task.AssigneeIds" @changed="resetComments" :id="task.Id" :drawer="true"
                :position="'top'" :key="task.Id + '-assignees'" v-if="mountCompleted" />
              <el-skeleton class="task-input-skeleton" :rows="1" animated v-else />
            </div>
          </div>

          <div class="d-flex mb-3 justify-content-between" v-if="task.Id != null">
            <p class="task-drawer-label">Description</p>
          </div>
          <div class="mb-3" v-if="mountCompleted && task.Id != null">
            <CustomQuillEditor :key="rederQuill" :content="task.Details" @blurred="(data) => task.Details = data" />
            <ValidationErrorItem validationKey="Details" />
          </div>
        </div>
      </div>

      <div class="task-attachments" v-if="task.Id != null">
        <p class="d-flex justify-content-between badge comments-heading-section">Attachment(s) <label
            class="el-button el-button--mini" :class="taskFiles.length ? 'el-button--warning' : 'el-button--primary'"
            style="margin-right: -9px;">
            {{ taskFiles.length ? taskFiles.length + ' file uploading... ' + uploadPercent + '%' : 'Add Attachment' }}
            <input :disabled="taskFiles.length > 0" accept=".jpg,.png,.ico,.pdf,.doc,.docx,.xls,.xlsx,.csv,.gif"
              type="file" style="opacity: 0; width: 1px; height: 1px" multiple @change="onFileChange">
          </label></p>
        <div class="attachment area">
          <p class="text-gray mt-2 p-2" v-if="task.Attachments.length == 0">No attachments found</p>
          <ul class="mt-3 file-list" v-else>
            <li v-for="file in (viewMoreFile ? task.Attachments : task.Attachments.slice(0, 5))">
              <img @click="downloadImage(file)" style="width: 32px; margin-right: 10px;" :src="'/img/fileicons/' + getFileExtension(file)
                + '.png'" alt="">
              <div @click="downloadImage(file)" style="display: flex; flex: 1; flex-direction: column;">
                <p>{{ file.FilenameOrj }}</p>
                <p style="font-size: 85%; opacity: 0.7;">{{ moment(file.CreateDate).fromNow() }}</p>
              </div>
              <div style="position: relative" class="d-flex">

                <div @click="downloadImage(file)" class="delete-file text-blue">
                  <i class="fas fa-link"></i>
                </div>

                <div class="delete-file" @click="deleteFile(file)">
                  <i class="fas fa-trash"></i>
                </div>


              </div>
            </li>
            <li v-if="task.Attachments.length > 5" @click="viewMoreFile = !viewMoreFile"
              style="justify-content: center;">
              <p class="text-center" style="display: block; text-align :center">
                {{viewMoreFile == false ? 'Show other '+(task.Attachments.length-5)+' file(s)' : 'Hide ' +
                (task.Attachments.length-5) + ' file(s)'}}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class=" task-comments mt-3" v-if="task.Id != null">

        <p class="mb-2 d-flex justify-content-between badge comments-heading-section">
          Comment(s)
          <el-switch type="primary" v-model="listCommentsForm.ShowChangelogs" active-text="Show logs" />
        </p>

        <div class="comment area">
          <p class="text-gray mt-2 p-2" v-if="comments.Items.length == 0">No comments found</p>

          <div class="comments-list" v-for="(comment, commentIndex) in comments.Items">
            <div class="comment-item" v-if="comment.Changelog == false">

              <img :src="comment.Employee.User != null ? comment.Employee.User.Image : comment.Employee.Image"
              @click='$isInRole("Owner,Coowner") ? $store.commit("setProfileEmployeeId", { id: comment.Employee.Id, showDrawer: true }) : {}'
                :class="$isInRole('Owner,Coowner') ? 'cursor-pointer' : ''" />
              <div class="comment-body">

                <div class="mb-2 d-flex justify-content-between">
                  <p class="comment-author" :class="$isInRole('Owner,Coowner') ? 'cursor-pointer' : ''" @click='$isInRole("Owner,Coowner") ? $store.commit("setProfileEmployeeId", { id: comment.Employee.Id, showDrawer: true }) : {}'>
                    {{ comment.Employee.FirstName + ' ' + comment.Employee.LastName }}</p>
                  <i class="fa fa-trash delete-comment" @click="deleteComment(comment)"
                    v-if="comment.EmployeeId == currentEmployeeId"></i>
                </div>

                <p class="comment-text" v-html="comment.Title"></p>
                <div class="comment-footer" style="justify-content: space-between; align-items: end;">
                  <div class="comment-files mt-2" style="display: flex; flex: 1; flex-direction: row; flex-wrap: wrap;">
                    <div class="d-flex align-items-center" v-for="file in comment.Attachments"
                      style="background-color: rgba(0,0,0,.1); padding: 2px 5px; border-radius: 8px; cursor: pointer; margin-right: 10px;">
                      <img @click="downloadImage(file)" style="width: 22px; margin-right: 5px; height: auto" :src="'/img/fileicons/' + getFileExtension(file)
                + '.png'" alt="">
                      <div @click="downloadImage(file)"
                        style="display: flex; flex: 1; flex-direction: column; font-size: 12px">
                        <p>{{ file.FilenameOrj }}</p>
                      </div>
                    </div>
                  </div>
                  <span class="comment-date">{{ moment(comment.CreateDate).format('DD.MM.yyyy HH:mm:ss') }}</span>
                </div>
              </div>
            </div>
            <div class="comment-changelog" v-else>
              <p class="d-flex justify-content-between">
                <span>
                  → <span class="text-underline" :class="$isInRole('Owner,Coowner') ? 'cursor-pointer' : ''"
                   @click='$isInRole("Owner,Coowner") ? $store.commit("setProfileEmployeeId", { id: comment.Employee.Id, showDrawer: true }) : {}'>{{
                    comment.Employee.FirstName }}</span>
                  <el-popover trigger="hover" style="cursor: pointer;"
                    v-if="comment.ExtraData != null && comment.ExtraData != null && parseExtraData(comment.ExtraData).Filenames"
                    placement="top-start" title="File list" width="350">
                    <span slot="reference"> {{ parseChangelog(comment) }}</span>
                    <div class="d-flex align-items-center mb-2"
                      v-for="file in parseExtraData(comment.ExtraData).Filenames"
                      style="background-color: rgba(0,0,0,.1); padding: 2px 5px; border-radius: 8px; cursor: pointer; margin-right: 10px;">
                      <img style="width: 22px; margin-right: 5px; height: auto" :src="'/img/fileicons/' + getFileExtension({FilenameOrj: file})
                + '.png'" alt="">
                      <div style="display: flex; flex: 1; flex-direction: column; font-size: 12px">
                        <p>{{ file }}</p>
                      </div>
                    </div>
                  </el-popover>
                  <span v-else> {{ parseChangelog(comment) }}</span>
                </span>
                <span class="comment-date">{{ moment(comment.CreateDate).format('DD.MM.yyyy HH:mm:ss') }}</span>
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center pt-4 pb-4" v-if="comments.TotalCount > comments.Items.length">
            <el-button class="btn btn-primary" size="mini" :loading="loading == 'getComments'" type="primary"
              @click="getComments">Load more <i class="fa fa-angle-down"></i></el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="task-comment-create d-flex justify-content-between" v-if="task.Id != null">
      <div>
        <el-tooltip class="item" effect="dark" content="Add attachment" placement="top">
          <label style="margin-left: 0px; margin-right: 10px;" class="el-button el-button--default is-circle"
            :disabled="loading == 'saveComment'">
            <i class="fas fa-plus"></i>
            <input :disabled="addUpdateComment.Attachments.length > 0"
              accept=".jpg,.png,.ico,.pdf,.doc,.docx,.xls,.xlsx,.csv,.gif" type="file"
              style="opacity: 0; width: 1px; height: 1px; position: absolute" multiple @change="onFileChangeComment">
          </label>
        </el-tooltip>
      </div>
      <div class="task-comment-input-wrapper w-100 pr-2" style="position: relative">

        <div v-if="commentTaggedUsers.length" class="mt-2 mb-2">
          <el-tag @close="removeTaggedUser(user)" size="mini" v-for="user in commentTaggedUsers" :key="user.ID" closable
            :type="'info'">
            {{user.User.Title}}
          </el-tag>
        </div>


        <at @insert="userTagged" :filterMatch="filterUsers" :members="employees" v-model="addUpdateComment.Title">
          <div contenteditable class="el-textarea__inner"></div>

          <template v-slot:embeddedItem="s">
            <span v-if="s.current.User">
              <span class="tagged-user" :data-user-id="s.current.ID">{{ s.current.User.Title }}</span>
            </span>
          </template>

          <template slot="item" slot-scope="item">

            <div class="mention-user-item">
              <div class="mention-user-picture" :style="{backgroundImage: 'url(' + item.item.User.Image + ')'}"></div>
              <div class="mention-user-details">
                <span class="mention-user-name">{{ item.item.User.FirstName }} <b>{{ item.item.User.LastName
                    }}</b></span>
                <span class="mention-user-team">
                  <i class="fas fa-circle" :style="{color: item.item.Team.Color}"></i>
                  {{ item.item.Team.Title }}
                </span>
              </div>

            </div>
          </template>
        </at>

        <div class="d-flex align-items-center justify-content-end mt-2" v-if="addUpdateComment.Attachments.length">
          <label>
            <p style="cursor: pointer;">{{ addUpdateComment.Attachments.length ? (addUpdateComment.Attachments.length +
              ' file(s) selected ') :
              ("Add Attachment") }}</p>

          </label>

          <span v-if="addUpdateComment.Attachments.length > 0" style="cursor: pointer; margin-left: 10px;"
            @click="addUpdateComment.Attachments = []"><i class="fas fa-times-circle"></i></span>

        </div>



      </div>
      <div class="">
        <el-button :icon="loading == 'saveComment' ? 'el-icon-loading' : 'el-icon-s-promotion'" circle
          @click="saveComment" :disabled="loading == 'saveComment'"></el-button>





      </div>
    </div>

  </div>
</template>

<script>

import moment from "moment/min/moment-with-locales";
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';

import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import ProjectTaskPrioritySelectbox from '@/components/form/ProjectTaskPrioritySelectbox.vue';
import ProjectTaskStatusSelectbox from '@/components/form/ProjectTaskStatusSelectbox.vue';
import MultipleEmployeeSelectbox from '@/components/form/MultipleEmployeeSelectbox.vue';
import UserSelect from '@/components/UserSelect';
import axios from 'axios'
import At from 'vue-at'

import $ from 'jquery'

import slugify from '@sindresorhus/slugify';

export default {
  name: 'TaskDetail',
  props: {
    data: Object
  },
  components: {
    DateTypeFilterSelectbox,
    DatePicker,
    ProjectSelectbox,
    UserSelect,
    ProjectTaskPrioritySelectbox,
    ProjectTaskStatusSelectbox,
    MultipleEmployeeSelectbox,
    At
  },
  data() {
    return {
      viewMoreFile: false,
      moment,
      mountCompleted: false,
      loading: 'init',
      rederQuill: false,
      debouncer: null,
      filter: {

      },
      addUpdateComment: {
        Title: null,
        ProjectTaskId: (this.data ? this.data.ID : null),
        TaggedUsers: [],
        Attachments: []
      },
      comments: {
        Items: [],
        PageCount: 0,
        TotalCount: 0
      },
      task: {
        Id: null,
        ProjectId: null,
        Title: null,
        Details: null,
        Source: 0,
        OrderNo: 1,
        AssigneeIds: [],
        Priority: null,
        DueDate: null,
        Status: 0,
        ExternalTaskId: null,
        NonBillable: false,
        Attachments: []
      },
      listCommentsForm: {
        TaskId: null,
        PageSize: 50,
        Page: 1,
        SearchTerm: null,
        ShowChangelogs:false,
      },
      editor: null,
      mimeTypes: "image/gif,text/csv,image/jpeg,image/png,image/x-icon,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel".split(","),
      uploadStatus: null,
      uploadPercent: 0,
      taskFiles: []
    }
  },
  async mounted() {
    var self = this;
    var canSee = this.$isInRole('Owner,Coowner');

    setTimeout(async () => {
      $("body").on("click", ".comment-tagged-user", function () {
        if(canSee)
        {
          self.$store.commit("setProfileEmployeeId", { id: $(this).data("comment-tagged-user-id"), showDrawer: true })
        }
      })

      this.loading = 'init';

      var div = document.getElementsByClassName("widget-visible");

      if (div) {
        div = div[0];
        if(div)
        {
          var iframe = div.querySelector("iframe");
          if (iframe) {
            iframe.style.display = "none";
          }
        }
      }


      if (this.data != null && this.data != undefined) {
        this.listCommentsForm.TaskId = this.data.ID
        this.task.Id = this.data.ID;

        await Promise.all([
          this.$store.dispatch("getProjects"),
          this.$store.dispatch("getEmployees"),
          this.getData(),
          this.getComments()
        ]);
      }

      this.mountCompleted = true;
      this.loading = null;
      this.rederQuill = !this.rederQuill;

      const editor = document.querySelector('[contentEditable]')
      if(editor)
      {
        editor.addEventListener('paste', this.handlePaste)
      }
      


      this.$socket.on("presence-user-" + this.account.User.ID + "-newCommentCreated", data => {
          if (data != null && data != undefined) {
              var tempData = data.data;
              if(this.comments != null)
              {
                var index = this.comments.Items.findIndex(x => x.ID == tempData.Id);
                if(index == -1 && tempData.Item.ProjectTaskId == this.task.Id)
                {
                  this.comments.Items.unshift(this.getMentionedHtml(tempData.Item));
                }
              }
          }
      });
    }, 100);

  },
  beforeDestroy() {

    this.$socket.off("presence-user-" + this.account.User.ID + "-newCommentCreated");

    try {
      var div = document.getElementsByClassName("widget-visible");
      if (div) {
        div = div[0];
        if(div)
        {
          var iframe = div.querySelector("iframe");
          if (iframe) {
            iframe.style.display = "block";
          }
        }
      }
    } catch (error) {
      
    }
  },
  methods: {
    async taskTitleBlur() {
      if (this.task.Id == null) {
        //create task
        var res = await this.$store.dispatch("addUpdateTask", {
          ProjectId: this.task.ProjectId,
          Title: this.task.Title,
          Source: 0,
          OrderNo: 1,
          AssigneeIds: [],
          Status: 0,
        });
        if (res != null) {
          this.task.Id = res.Item.ID;
          this.task.Status = 0
          this.listCommentsForm.TaskId = res.Item.ID;
          this.resetComments();

          const editor = document.querySelector('[contentEditable]')
          if(editor)
          {
            editor.addEventListener('paste', this.handlePaste)
          }
        }
      }
      else {
        //update task
        var res = await this.$store.dispatch("updateTaskTitle", {
          Id: this.task.Id,
          Title: this.task.Title
        });
        if (res != null) {
          await this.resetComments();
        }
      }
    },
    refreshContent() {
      this.getData();
      this.resetComments();
    },

    downloadImage(file) {
      window.open(file.Url)
    },
    getFileExtension(file) {
      if (file.FilenameOrj == null)
        return "_blank";
      return file.FilenameOrj.split('.')[file.FilenameOrj.split('.').length - 1];
    },
    onFileChange(event) {

      var files = Array.from(event.target.files)

      if (files.length > 5) {
        this.$message.error("You can upload a maximum of 5 files at a time.");
        return;
      }


      if (files.filter(k => this.mimeTypes.some(x => x == k.type) === false).length > 0) {

        this.$message.error("You can upload " + $("[type='file']").attr("accept") + ' files');
        return;

      }

      if (files.some(k => k.size / 1000 / 1000 > 5).length > 0) {

        this.$message.error("Each file must be a maximum size of 5 MB.");
        return;

      }

      this.taskFiles = files;


    },
    onFileChangeComment(event) {

      var files = Array.from(event.target.files)

      if (files.length > 5) {
        this.$message.error("You can upload a maximum of 5 files at a time.");
        return;
      }


      if (files.filter(k => this.mimeTypes.some(x => x == k.type) === false).length > 0) {

        this.$message.error("You can upload " + $("[type='file']").attr("accept") + ' files');
        return;

      }

      if (files.some(k => k.size / 1000 / 1000 > 5).length > 0) {

        this.$message.error("Each file must be a maximum size of 5 MB.");
        return;

      }

      this.addUpdateComment.Attachments = files;


    },
    handlePaste(e) {
      e.preventDefault()

      const text = (e.clipboardData || window.clipboardData).getData('text')
      const selection = window.getSelection()

      if (selection.rangeCount) {
        selection.deleteFromDocument()
        selection.getRangeAt(0).insertNode(document.createTextNode(text))
      }
    },
    filterUsers(user, chunk, at) {

      return slugify(user.User.Title, { customReplacements: [['ü', 'u'], ['Ü', 'u'], ['ö', 'o'], ['Ö', 'o']] }).search(slugify(chunk, { customReplacements: [['ü', 'u'], ['Ü', 'u'], ['ö', 'o'], ['Ö', 'o']] })) > -1
        || slugify(user.Team.Title, { customReplacements: [['ü', 'u'], ['Ü', 'u'], ['ö', 'o'], ['Ö', 'o']] }).search(slugify(chunk, { customReplacements: [['ü', 'u'], ['Ü', 'u'], ['ö', 'o'], ['Ö', 'o']] })) > -1
        || slugify(user.User.Email, { customReplacements: [['ü', 'u'], ['Ü', 'u'], ['ö', 'o'], ['Ö', 'o']] }).search(slugify(chunk, { customReplacements: [['ü', 'u'], ['Ü', 'u'], ['ö', 'o'], ['Ö', 'o']] })) > -1
    },
    removeTaggedUser(user) {

      $("[data-user-id=" + user.ID + "]").parent().remove();

      this.addUpdateComment.Title = $(".el-textarea__inner").html();
    },
    userTagged() {


    },
    deleteTask() {
      this.loading = 'deleteTask';

      this.$confirm('You are about to delete a task. This action can not be undo, are you sure about this?', 'Warning', {
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        var res = await this.$store.dispatch("deleteTask", { Id: this.task.Id });
        if (res != null) {
          this.closeMe();
        }
        this.loading = null;
      }).catch(() => {
        this.loading = null;
      });
    },
    deleteFile(item) {
      this.loading = 'delete-' + item.ID;

      this.$confirm('You are about to delete a file. This action can not be undo, are you sure about this?', 'Warning', {
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        var res = await this.$store.dispatch("deleteFile", { Id: item.Id });
        if (res != null) {
          var index = this.task.Attachments.findIndex(x => x.Id == item.Id);
          if (index != -1) {
            this.task.Attachments.splice(index, 1);
            this.resetComments();
          }
        }
        this.loading = null;
      }).catch(() => {
        this.loading = null;
      });
    },
    deleteComment(item) {
      this.loading = 'delete-' + item.ID;

      this.$confirm('You are about to delete a task. This action can not be undo, are you sure about this?', 'Warning', {
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(async () => {
        var res = await this.$store.dispatch("deleteComment", { Id: item.ID });
        if (res != null) {
          var index = this.comments.Items.findIndex(x => x.ID == item.ID);
          if (index != -1) {
            this.comments.Items.splice(index, 1);
          }
        }
        this.loading = null;
      }).catch(() => {
        this.loading = null;
      });
    },
    async markAsTodo() {
      this.loading = 'markAsTodo';
      this.task.Status = 0;
      this.loading = null;
    },
    async markAsComplete() {
      this.loading = 'markAsComplete';
      this.task.Status = 1;
      this.loading = null;
    },
    closeMe() {
      this.$emit('closeDialog');
    },
    parseExtraData(data) {
      return JSON.parse(data);
    },
    parseChangelog(comment) {
      var title = comment.Title;

      if (title.indexOf("task due date to") != -1) {
        var regex = /\"(.*?)\"/;
        var regexFindResult = title.match(regex);
        if (regexFindResult != null) {
          regexFindResult = regexFindResult[1];
          var extraData = JSON.parse(comment.ExtraData);

          title = title.replace(regex, "\"" + this.moment(extraData.DueDate).format('DD.MM.yyyy HH:mm') + "\"");
        }

      }
      return title;
    },
    async getData() {
      this.loading = "getData";
      var getTaskResult = await this.$store.dispatch("getTask", { Id: this.data.ID });
      if (getTaskResult == null) {
        this.closeMe();
      }
      else {
        getTaskResult.Item.Id = getTaskResult.Item.ID;
        this.task = getTaskResult.Item;
      }

      this.loading = null;
    },
    getMentionedHtml(k) {
      {

        let title = k.Title;

        k.Mentions.map(y => {


          title = title.replaceAll("[" + y.Id + "]", "<span class='comment-tagged-user' data-comment-tagged-user-id='" + y.Id + "'>" + y.User.FirstName + " " + y.User.LastName + "</span>")

        })

        return {
          ...k,
          Title: title
        }
      }
    },
    async getComments(reset = false) {
      this.loading = "getComments";
      var commentsResult = await this.$store.dispatch("listComments", this.listCommentsForm);
      if (commentsResult == null) {
        this.closeMe();
      }
      else {
        this.listCommentsForm.Page += 1;

        if (reset == true) {
          this.comments.Items = [];
        }

        if (commentsResult.Items.length > 0) {
          this.comments.Items.push(...commentsResult.Items.map(k => this.getMentionedHtml(k)));
        }
        this.comments.PageCount = commentsResult.PageCount;
        this.comments.TotalCount = commentsResult.TotalCount;
      }

      this.loading = null;
    },
    async saveComment() {


      if (this.addUpdateComment.Attachments.length > 0) {


        var promises = [];

        var uploadFiles = [];


        this.addUpdateComment.Attachments.map(k => {
          promises.push(k.arrayBuffer());
        })

        await Promise.all(promises).then(responses => {

          responses.map((k, index) => {

            uploadFiles.push({
              data: Array.from(new Uint8Array(k)),
              Type: this.addUpdateComment.Attachments[index].type,
              Filename: this.addUpdateComment.Attachments[index].name
            })

          })
        })

      }

      var comment = $('<div/>').html(this.addUpdateComment.Title);


      comment.find('.tagged-user').each(function (index) {
        $(this).html("[" + $(this).data("user-id") + "]");
      });
      comment.find("span").contents().unwrap();
      comment.find("span").remove()
      comment = comment.html();
      comment = comment.replaceAll("</div><div>", "<br>")
      comment = comment.replaceAll("<div>", "<br>")
      comment = comment.replaceAll("</div>", "")
      comment = comment.replaceAll("&nbsp;", " ")
      comment = comment.replaceAll("  ", " ")
      comment = comment.replaceAll("<br>", "\n")

      comment = comment.replace(/(\r\n|\r|\n){2,}/g, '$1\n');

      comment = comment.trim();



      this.loading = "saveComment";
      var saveCommentResult = await this.$store.dispatch("addUpdateComment", {
        ...this.addUpdateComment,
        Title: comment,
        MentionIds: this.commentTaggedUsers.map(k => k.ID),
        Attachments: uploadFiles
      });
      if (saveCommentResult != null) {
        this.addUpdateComment.Title = null;
        $('.el-textarea__inner').html("");
        this.addUpdateComment.Attachments = [];
      }

      this.loading = null;
    },
    resetComments() {
      setTimeout(() => {
        this.listCommentsForm.Page = 1;
        this.getComments(true);
      }, 500);
    },
    filterMatch(name, chunk, at) {
      return name.toLowerCase().indexOf(chunk.toLowerCase()) > -1
    }
  },
  computed: {
    commentTaggedUsers() {
      if (this.addUpdateComment.Title == null)
        return []
      return this.employees.filter(x => this.addUpdateComment.Title.indexOf('data-user-id="' + x.ID + '"') != -1);
    },
    currentEmployeeId() {
      return this.account.Employings.Items.find(x => x.OrganizationId == this.organization.ID).ID;
    },
    customers() {
      return this.$store.getters.getCustomers;
    },
    account() {
      return this.$store.getters.getAccount;
    },
    organization() {
      return this.account.CurrentOrganization;
    },
    trackSettings() {
      return this.organization.TrackSettings;
    },
    employees() {
      return this.$store.getters.getTaskEmployees.Items;
    },
  },
  watch: {
    'taskFiles': function () {

      if (this.taskFiles.length == 0)
        return;


      var promises = [];

      var uploadFiles = [];


      this.taskFiles.map(k => {
        promises.push(k.arrayBuffer());
      })

      Promise.all(promises).then(responses => {

        responses.map((k, index) => {

          uploadFiles.push({
            data: Array.from(new Uint8Array(k)),
            Type: this.taskFiles[index].type,
            Filename: this.taskFiles[index].name
          })

        })


        axios.post("project/AddTaskAttachments", { ProjectTaskId: this.task.Id, Attachments: uploadFiles }, {
          onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.uploadPercent = percentCompleted;
          }
        }).then(async res => {

          this.taskFiles = [];

          var getTaskResult = await this.$store.dispatch("getTask", { Id: this.data.ID });

          this.task.Attachments = getTaskResult.Item.Attachments;

          this.resetComments()

        })


      })




    },
    'task.Priority'() {
      if (this.mountCompleted) {
        this.$store.dispatch("updateTaskPriority", {
          Id: this.task.Id,
          Priority: this.task.Priority
        });
        this.resetComments();
      }
    },
    'task.Status'() {
      if (this.mountCompleted) {
        this.$store.dispatch("updateTaskStatus", {
          Id: this.task.Id,
          Status: this.task.Status
        });
        this.resetComments();
      }
    },
    'task.DueDate'() {
      if (this.mountCompleted) {
        window.clearTimeout(this.debouncer);
        this.debouncer = setTimeout(async () => {
          this.$store.dispatch("updateTaskDuedate", {
            Id: this.task.Id,
            DueDate: this.task.DueDate
          });
          this.resetComments();
        }, 500);
      }
    },
    'task.ProjectId'() {
      if (this.mountCompleted) {
        if (this.task.Id != null) {
          this.$store.dispatch("updateTaskProject", {
            Id: this.task.Id,
            ProjectId: this.task.ProjectId
          });
          this.resetComments();
        }

      }
    },
    'listCommentsForm.ShowChangelogs'() {
      this.resetComments();
    },
    'task.Details'() {
      if (this.mountCompleted) {
        window.clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
          this.$store.dispatch("updateTaskDetails", {
            Id: this.task.Id,
            Details: this.task.Details
          });
          this.resetComments();
        }, 500);
      }
    },

  }
}
</script>

<style lang="less">
.mention-user-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 5px;

  .mention-user-picture {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
    height: 30px;
  }


  .mention-user-details {
    display: flex;
    flex-direction: column;



    .mention-user-name {
      font-size: 14px;
    }

    .mention-user-team {
      font-size: 12px;
    }

    i {
      font-size: 14px;
    }
  }


}


.dark-body {
  .atwho-view {
    background-color: #343f52 !important;
    border: none;
  }


  .el-popover {
    background-color: #343f52 !important;
    border: 1px solid #3a465b;
  }

  .el-popover__title {
    color: white;
  }

  .el-popper[x-placement^=top] .popper__arrow::after {
    border-top-color: #5a667a !important;
  }


  .el-popper[x-placement^=top] .popper__arrow {
    border-top-color: #5a667a !important;
  }


}



.mention-selected {
  background-color: rgba(0, 0, 0, .1)
}

.v-popper__arrow-container {
  display: none
}


.atwho-view {

  min-width: 250px !important;
  max-width: 250px !important;
  max-height: 200px !important;
  overflow-y: auto;
  bottom: 10px;

  .atwho-li {
    height: 40px !important;
  }

  .atwho-cur {
    background: rgba(0, 0, 0, .1) !important;
    color: unset !important
  }
}

.tagged-user {
  font-weight: bold;
}



.comment-tagged-user {
  font-weight: bold;
  cursor: pointer;

}


.atwho-wrap {
  .el-textarea__inner {
    max-height: 150px;
    min-height: 40px;
    overflow: auto;
    resize: none;
    padding-left: 20px;
  }
}


.add-file-button {
  position: absolute;
  right: 10px;
  top: -25px;
  color: inherit;
  text-decoration: none;
  color: white;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}


.file-list {
  li {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 5px 7px;
    background: rgba(0, 0, 0, .1);
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
  }
}

.delete-file {

  color: rgb(226, 98, 107);
  font-size: 12px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>