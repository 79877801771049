import axios from "axios";

const store = {
  state: () => ({
    activityLogs:null
  }),
  getters: {
    getActivityLogs(state) {
      return state.activityLogs;
    }
  },
  mutations: {
    setActivityLogs(state, payload) {
      state.activityLogs = payload;
    }
  },
  actions: {
    async activityLogs(store, payload) {
      var res = await axios.post("/activitylogs/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setActivityLogs", res.data.Data);
      return res.data.Data;
    }
  },
}

export default store;