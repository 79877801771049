<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
     
    <div class="card p-7 mb-10 bg-blue">
      <div class="card-header">
        <div class="card-title">
          <h3>Hello {{ account.User.FirstName }},</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">
        <li>Thank you for using Worktivity.</li>
        <template v-if="organization.Subscription == null">
          <li >You don't have an active subscription.</li>
          <li>Please contact your account holder to start a new subscription.</li>
        </template>
        <template v-else>
          <li v-if="organization.Subscription.IsTrial">Your free trial is expired on <b>{{ moment(organization.Subscription.EndDate).format('DD MMMM yyyy HH:mm') }}</b>.</li>
          <li v-else>Your subscription is expired on <b>{{ moment(organization.Subscription.EndDate).format('DD MMMM yyyy HH:mm') }}</b>.</li>
          <li>Please contact your account holder to start a new subscription.</li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment/min/moment-with-locales";

import Breadcrumb from '../partials/Breadcrumb';

export default {
    name: 'SubscriptionOverIndex',
    components: {
      Breadcrumb,
    },

    data(){
      return {
        moment,
        meta: {
          title: 'Subscription over',
          desc:'You can manage your subscription here.'
        },
      }
    },
    async mounted() {
      this.$setMeta(this.meta);
    },
    methods: {
      
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
    },
}
</script>
