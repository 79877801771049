<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :clearable="true" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager')">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item">
          <TimeEntryStatusSelectbox :nullable="true" :selectedId="filter.Status" @changed="(id) => filter.Status = id" />
        </div>
      </div>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>

      <el-button round icon="fas fa-plus" @click="addModal = true">Add</el-button>
    </div>

    <div class="card p-5" v-if="!timeEntries">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10" v-if="timeEntries" id="currentTable">

      <NoData v-if="timeEntries.Items.length == 0" />
      <div class="timesheet-container" v-if="timeEntries.Items.length > 0">

        <el-table :data="timeEntries.Items" style="width: 100%" ref="tab" @row-click="expand" :row-class-name="'cursor-pointer'" v-loading="loading == 'getData'">
          
          <el-table-column label="Employee" min-width="250">
                <template slot-scope="scope">
                  <EmployeeTablePreview :employee="scope.row.Employee" />
                </template>
              </el-table-column>

           <el-table-column type="expand">
            <template slot-scope="props">
              <p>Status: {{ $getEnumTitle('EmployeeActivityLogStatus',props.row.LogStatus) }}</p>
              <p>Productivity: {{ $getEnumTitle('ProductivityStatus', props.row.ProductivityStatus) }}</p>
              <p>Reason: {{ props.row.Reason }}</p>
              <p v-if="props.row.Status == 2">Rejection reason: {{ props.row.RejectionReason }}</p>
            </template>
          </el-table-column>  
          
          <el-table-column label="Start time" width="180">
            <template slot-scope="props">
              <span class="badge cursor-pointer">{{moment(props.row.StartDate).format('DD MMM yyyy - HH:mm')}}</span>
            </template>
          </el-table-column>

          <el-table-column label="End time" width="180">
            <template slot-scope="props">
             <span class="badge cursor-pointer">{{moment(props.row.EndDate).format('DD MMM yyyy - HH:mm')}}</span>
            </template>
          </el-table-column>

          <el-table-column label="Duration" width="150">
            <template slot-scope="props">
              
              <el-tooltip class="item" effect="dark" :content="$getEnumTitle('EmployeeActivityLogStatus', props.row.LogStatus) + ' / ' + $getEnumTitle('ProductivityStatus', props.row.ProductivityStatus)" placement="top">
                <span class="badge cursor-pointer" :class="getBadgeBg(props.row.ProductivityStatus)">{{ $parseTime(props.row.TotalMinutes) }}</span>
              </el-tooltip>

            </template>
          </el-table-column>

          <el-table-column label="Status" width="120">
            <template slot-scope="props">
              <span class="badge" :class="getStatusBg(props.row.Status)"><i v-show="props.row.Status == 0" class="fas fa-clock"></i> {{ $getEnumTitle('TimeEntryStatus', props.row.Status) }}</span>
            </template>
          </el-table-column>

          <el-table-column width="140">
            <template slot-scope="scope" >
              <el-row :key="scope.row.ID" style="display:flex;justify-content:space-between">

                <template v-if="$isInRole('Owner,Coowner,Manager')">
                  <el-tooltip class="item" effect="dark" content="Approve" placement="top" >
                    <span>
                      <el-button type="success" icon="fas fa-check" size="small" circle @click.stop="approveTimeEntry(scope.row)" :loading="loading == 'approve-' + scope.row.ID" :disabled="scope.row.Status == 1 || scope.row.Status != 0"></el-button>
                    </span>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Reject" placement="top" v-if="$isInRole('Owner,Coowner,Manager')">
                    <span>
                      <el-button type="warning" icon="fas fa-times" size="small" circle @click.stop="rejectTimeEntry(scope.row.ID)" :loading="loading == 'reject-' + scope.row.ID" :disabled="scope.row.Status == 2 || scope.row.Status != 0"></el-button>
                    </span>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                    <span>
                      <el-button type="danger" icon="fas fa-trash" size="small" circle @click.stop="deleteTimeEntry(scope.row.ID)" :loading="loading == 'delete-' + scope.row.ID"></el-button>
                    </span>
                  </el-tooltip>
                </template>

                <template v-if="$isInRole('Employee')">
                  <el-tooltip class="item" effect="dark" content="Only your managers can approve this time entry." placement="top" >
                    <span>
                      <el-button type="success" icon="fas fa-check" size="small" circle disabled></el-button>
                    </span>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Only your managers can reject this time entry." placement="top">
                    <span>
                      <el-button type="warning" icon="fas fa-times" size="small" circle disabled></el-button>
                    </span>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" :content="scope.row.Status == 0 ? 'Delete' : 'You can not delete this time entry after is approved. Only your managers can delete it.'" placement="top">
                    <span>
                      <el-button type="danger" icon="fas fa-trash" size="small" @click.stop="deleteTimeEntry(scope.row.ID)" circle :disabled="scope.row.Status != 0"></el-button>
                    </span>
                  </el-tooltip>
                </template>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

      </div>

      <Pagination 
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="timeEntries.TotalCount"  
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />

    </div>

     <el-dialog :close-on-click-modal="false" class="medium" title="Add manual time entry" :visible.sync="addModal" center modal-append-to-body destroy-on-close>
      <TimeEntryAddOrUpdateModal @timeEntryCreated="getData" @closeDialog="addModal = false" v-if="addModal" />
    </el-dialog>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import TimeEntryStatusSelectbox from '@/components/form/TimeEntryStatusSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import moment from "moment/min/moment-with-locales";

import Pagination from '@/components/Pagination';

import TimeEntryAddOrUpdateModal from './TimeEntryAddOrUpdateModal.vue';

export default {
    name: 'ManuelTimeEntriesIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker,
      TimeEntryStatusSelectbox,
      TimeEntryAddOrUpdateModal,
      Pagination
    },

    data(){
      return {
        moment,
        loading:null,
        meta: {
          title: 'Manual time entries',
          desc: 'Requests for working hours that cannot be spent in front of the computer.'
        },
        addModal:false,
        filter: {
          TeamId:null,
          EmployeeId:null,
          DateFilter:null,
          StartDate:null,
          EndDate:null,
          IncludeEmployees:true,
          Page:1,
          PageSize:12,
          Status:null
        }
      }
    },
    methods: {
      getBadgeBg(status)
      {
        switch (status) {
          case 1:
            return "green";
             case 2:
            return "blue";
             case 3:
            return "orange";
          default:
            break;
        }
      },
      getStatusBg(status)
      {
        switch (status) {
          case 0:
            return "blue";
             case 1:
            return "green";
             case 2:
            return "yellow";
          default:
            break;
        }
      },
      changeStatus() {

      },
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("getTimeEntires", this.filter)
        this.loading = null;
      },
      async approveTimeEntry(item) {

        this.$confirm('You are about to approve this time entry. Employee will be marked as ' + this.$getEnumTitle("EmployeeActivityLogStatus",item.LogStatus) + ' and ' + this.$getEnumTitle("ProductivityStatus",item.ProductivityStatus) + ' in this duration. This action can not be undo, are you sure about this?', 'Confirmation', {
          confirmButtonText: 'Yes, approve',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(async () => {
          
          this.loading = 'approve-' + item.ID ;
          await this.$store.dispatch("approveTimeEntry", {Id: item.ID});
          await this.getData();
          this.loading = null;

        }).catch(() => {
          this.loading = null;
        });
        
      },
      async rejectTimeEntry(id) {
        this.$prompt('Reason', '', {
          confirmButtonText: 'Reject',
          title:'Confirmation',
          inputPattern: /[a-zA-Z0-9]/,
          inputErrorMessage: "Please specify...",
          cancelButtonText: 'Cancel'
        }).then(async ({ value }) => {
          this.loading = 'reject-' + id;
          await this.$store.dispatch("rejectTimeEntry", {Id: id, RejectionReason: value});
          await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
      async deleteTimeEntry(id) {
         this.$confirm('You are about to delete this time entry. This action can not be undo, are you sure about this?', 'Confirmation', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          this.loading = 'delete-' + id ;
          await this.$store.dispatch("deleteTimeEntry", {Id: id});
          await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
      expand(row, isExpanded) {
        if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
        {
          this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
        }
        else
        {
          this.$refs.tab.store.states.expandRows.push(row);
        }
      },
    },
    async mounted() {
      this.$setMeta(this.meta);

      await this.$store.dispatch("getEmployees");
      await this.$store.dispatch("getTeams");

      // this.filter.StartDate = this.$getToday()[0];
      // this.filter.EndDate = this.$getToday()[1];

      await this.getData();
    },
    watch: {
      'filter.StartDate'() {
        if (this.filter.Page != 1) {
          this.filter.Page = 1;
        }
        else {
          this.getData();
        }
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }

        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.EmployeeId'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Status'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("currentTable");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("currentTable");
      }
    },
    computed: {
       employees() {
        return this.$store.getters.getEmployees
      },
      timeEntries() {
        return this.$store.getters.getTimeEntries;
      }
    }
}
</script>
