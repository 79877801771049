import axios from "axios";

const store = {
  state: () => ({
    timeEntries:null,
    pendingTimeEntries:null
  }),
  getters: {
    getTimeEntries(state) {
      return state.timeEntries;
    },
  },
  mutations: {
    setTimeEntries(state, payload) {
      state.timeEntries = payload;
    },
  },
  actions: {
    async getTimeEntires(store, payload) {
      var res = await axios.post("/TimeEntry/List", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setTimeEntries", res.data.Data);
      return res.data.Data;
    },
    async createTimeEntry(store, payload) {
      var res = await axios.post("/TimeEntry/Create", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async approveTimeEntry(store, payload) {
      var res = await axios.post("/TimeEntry/Approve", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async rejectTimeEntry(store, payload) {
      var res = await axios.post("/TimeEntry/Reject", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async deleteTimeEntry(store, payload) {
      var res = await axios.post("/TimeEntry/Delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    }
  }
}

export default store;