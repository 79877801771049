<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="activity-filter">
      <div class="filter-item purple" :class="activeTab == 'payroll' ? 'isactive' : ''" @click="activeTab = 'payroll'">
        <i class="fas fa-calculator"></i>
        <span>Payroll calculator</span>
      </div>
      <div class="filter-item green" :class="activeTab == 'history' ? 'isactive' : ''" @click="activeTab = 'history'">
        <i class="fas fa-history"></i>
        <span>Payment history</span>
      </div>
    </div>

    <template v-if="activeTab == 'payroll'">
      <div class="page-filter-area">
        <div class="filter-options">
          <div class="filter-item" style="max-width:220px;">
            <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => {
              filter.StartDate = dates.StartDate;
              filter.EndDate = dates.EndDate
            }" />
          </div>
          
          <div class="filter-item">
            <MultipleActivityLogStatusSelectbox :filterStatuses="[1, 2, 3]" :selectedId="filter.Statuses" @changed="(id) => filter.Statuses = id" />
          </div>
          
          <div class="filter-item">
            <MultipleProductivityStatusSelectbox :selectedId="filter.ProductivityStatuses" @changed="(id) => filter.ProductivityStatuses = id" />
          </div>
          
          <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
            <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
          </div>
          <div class="filter-item">
            <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
          </div>
          <div class="filter-item" v-if="trackSettings.EnableProjectManagement">
            <ProjectSelectbox :selectedId="filter.ProjectId" @changed="(id) => filter.ProjectId = id" :key="filter.ProjectId" />
          </div>
          <div class="filter-item" v-if="trackSettings.EnableProjectManagement && filter.ProjectId != null">
            <TaskSelectbox :projectId="filter.ProjectId" @changed="(id) => filter.ProjectTaskId = id" :key="filter.ProjectId" />
          </div>
          <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager') && payroll && payroll.Items.some(x => x.Employee.Blocked == true)">
            <el-tooltip class="item" effect="dark" content="Toggle on to show blocked employees" placement="top">
              <el-switch v-model="filter.IncludeBlockedEmployees" active-text=""></el-switch>
            </el-tooltip>
          </div>

        </div>

        <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
          <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
        </el-tooltip>
      </div>

      <div class="card p-5 mb-10" v-if="payroll" v-loading="loading == 'getData'">
          <div class="progress-container total-counter pt-0">
            <div class="progress-item">
              <div class="progress-overview green">
                <i class="fas fa-clock"></i>
                <div class="progress-info">
                  <h6>Total hours</h6>
                  <span>{{ $parseTime($sum(payroll.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'TotalMins')) }}</span>
                </div>
              </div>
            </div>
            
            <div class="progress-item">
              <div class="progress-overview orange">
                <i class="fas fa-money-bill"></i>
                <div class="progress-info">
                  <h6>Price per hour</h6>
                  <span>
                    {{ $formatMoney(pricePerHourAvg, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                    avg.</span>
                </div>
              </div>
            </div>

            <div class="progress-item">
              <div class="progress-overview purple">
                <i class="fas fa-wallet"></i>
                <div class="progress-info">
                  <h6>Total spent</h6>
                  <span>
                    {{ $formatMoney($sum(payroll.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'TotalSpent'), $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                    </span>
                </div>
              </div>
            </div>

            <div class="progress-item">
              <div class="progress-overview blue">
                <i class="fas fa-wallet"></i>
                <div class="progress-info">
                  <h6>Total unpaid</h6>
                  <span>
                    {{ $formatMoney($sum(payroll.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true), 'TotalPayable'), $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                    </span>
                </div>
              </div>
            </div>
          </div>

        <div class="activity-table" v-if="payroll">
          <el-table :data="payroll.Items.filter(x => filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true)" >
            <el-table-column label="Employee" min-width="250">
                <template slot-scope="scope">
                  <EmployeeTablePreview :employee="scope.row.Employee" />
                </template>
              </el-table-column>

            <el-table-column label="Total time" width="200">
              <template slot-scope="scope">
                <span class="badge" :style="scope.row.TotalMins == 0 ? 'opacity:.3' : ''">{{ $parseTime(scope.row.TotalMins) }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Hourly pay rate" width="150">
              <template slot-scope="scope">

                <el-tooltip :content="'Avg pay rate is: ' + (scope.row.TotalMins != 0 ? scope.row.AvgPayRate.toFixed(2) : scope.row.Employee.PayRate.toFixed(2)) + '/hr. Click to change the employee pay rate for the current filter criteria. For unpaid records only.'" placement="top" :class="scope.row.AvgPayRate != scope.row.Employee.PayRate && scope.row.TotalMins != 0 ? 'blue': ''" v-if="scope.row.TotalPayable > 0">
                  <span class="badge cursor-pointer" @click="changeEmployeePayRate(scope.row)" :style="scope.row.Employee.PayRate == 0 ? 'opacity:.3' : ''">
                  <i :class="loading == 'pay-rate-' + scope.row.ID ? 'fa fa-spinner fa-spin' : 'fa fa-pen'" style="font-size:10px;"></i>
                  {{ $formatMoney(scope.row.Employee.PayRate, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                </span>
                </el-tooltip>
                <el-tooltip content="There is no pending payment. If you want to change the employee's pay rate, you should visit the employees page." placement="top" v-else>
                  <span class="badge cursor-pointer" style="opacity:.3">
                  <i class="fa fa-pen" style="font-size:10px;"></i>
                  {{ $formatMoney(scope.row.Employee.PayRate, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                </span>
                </el-tooltip>

              </template>
            </el-table-column>

            <el-table-column label="Total spent" width="150">
              <template slot-scope="scope">
                <span class="badge" :style="(scope.row.TotalSpent) == 0 ? 'opacity:.3' : ''">
                  {{ $formatMoney(scope.row.TotalSpent, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Total unpaid" width="150">
              <template slot-scope="scope">
                <span class="badge" :style="(scope.row.TotalPayable) == 0 ? 'opacity:.3' : ''">
                  {{ $formatMoney(scope.row.TotalPayable, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="" width="75">
              <template slot="header">
                <el-tooltip class="item" effect="dark" content="Mark all as paid" placement="top">
                  <el-button :disabled="!payroll.Items.some(x => x.TotalPayable > 0)" :loading="markAsAllPaidLoading" type="primary" icon="fas fa-check-double" size="small" @click="markAsAllPaid()"></el-button>
                </el-tooltip>
              </template>
              
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Mark as paid" v-if="scope.row.TotalPayable != 0" placement="top">
                  <el-button :disabled="scope.row.TotalPayable == 0" :loading="loading == 'mark-as-paid-' + scope.row.ID" type="primary" icon="fas fa-check" size="small" circle @click="markAsPaid(scope.row)" :key="loading + scope.row.ID"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card p-5 mb-10" v-if="!payroll">
        <el-skeleton :rows="12" animated />
      </div>

      <div class="d-flex mb-5 justify-content-center" v-if="payroll">
        <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
          Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
        </el-button>
        <div class="d-flex align-items-center">
          <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
        </div>
      </div>

    </template>

    <template v-if="activeTab == 'history'">
      <div class="page-filter-area">
        <div class="filter-options">
          <div class="filter-item" style="max-width:220px;">
            <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => {
              historyFilter.StartDate = dates.StartDate;
              historyFilter.EndDate = dates.EndDate
            }" />
          </div>
          <div class="filter-item">
            <TeamSelectbox :selectedId="historyFilter.TeamId" @changed="(id) => historyFilter.TeamId = id" :key="historyFilter.TeamId" />
          </div>
          <div class="filter-item">
            <EmployeeSelectbox :selectedId="historyFilter.EmployeeId" @changed="(id) => historyFilter.EmployeeId = id" @changedTeam="(id) => historyFilter.TeamId = id" :key="historyFilter.EmployeeId" />
          </div>

          <div class="filter-item" v-if="trackSettings.EnableProjectManagement">
            <ProjectSelectbox :selectedId="historyFilter.ProjectId" @changed="(id) => historyFilter.ProjectId = id" :key="historyFilter.ProjectId" />
          </div>
          <div class="filter-item" v-if="trackSettings.EnableProjectManagement && historyFilter.ProjectId != null">
            <TaskSelectbox :projectId="historyFilter.ProjectId" @changed="(id) => historyFilter.ProjectTaskId = id" :key="historyFilter.ProjectId" />
          </div>
        </div>

        <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
          <el-button type="link" round icon="fas fa-sync" @click="getDataHistory" :loading="loading == 'getDataHistory'"></el-button>
        </el-tooltip>
      </div>

      <div class="card p-5 mb-10" id="payrollHistoryTable" v-if="history" v-loading="loading == 'getDataHistory'">

        <div class="activity-table">
          <NoData v-if="history.TotalCount == 0" />
          <el-table :data="history.Items" ref="tab" @row-click="expand" :row-class-name="'cursor-pointer'" v-else>
            <el-table-column label="Employee" min-width="250">
              <template slot-scope="scope">
                <EmployeeTablePreview :employee="scope.row.Employee" />
              </template>
            </el-table-column>
            
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="d-flex">
                  <div class="f-flex flex-column" v-if="props.row.PaidByEmployee != null">
                    <p>Paid by: </p>
                   <EmployeeTablePreview :employee="props.row.PaidByEmployee" />
                   </div>

                   <div class="f-flex flex-column ml-5" v-if="props.row.CancelledByEmployee != null">
                    <p>Cancelled by: </p>
                   <EmployeeTablePreview :employee="props.row.CancelledByEmployee" />
                   </div>
                </div>
              </template>
            </el-table-column> 
            

            <el-table-column label="Period" width="250">
              <template slot-scope="scope">
                <span class="badge">
                  {{ moment(scope.row.StartDate).format('DD MMM yyyy') }} / {{ moment(scope.row.EndDate).format('DD MMM yyyy') }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Paid at" width="170">
              <template slot-scope="scope">
                <span class="badge">
                  {{ moment(scope.row.CreateDate).format('DD MMM yyyy HH:mm') }} 
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Paid price" width="150">
              <template slot-scope="scope">
                <span class="badge" :style="scope.row.PaidPrice == 0 ? 'opacity:.3' : ''">
                  {{ $formatMoney(scope.row.PaidPrice, $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="" width="55" class-name="text-center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Cancel this payment" placement="top" v-if="scope.row.IsCancelled != true">
                  <span>
                    <el-button
                    :loading="loading == 'mark-as-unpaid-' + scope.row.ID" type="danger" icon="fas fa-times" size="small" circle @click.stop="markAsUnPaid(scope.row)" :key="'unpaid' + loading + scope.row.ID"></el-button>
                  </span>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="This payment is cancelled" placement="top" v-else>
                  <span>
                    <el-button
                    disabled type="danger" icon="fas fa-times" size="small" circle></el-button>
                  </span>
                </el-tooltip>

              </template>
            </el-table-column>
          </el-table>
        </div>

         <Pagination 
          :Page="historyFilter.Page" 
          :PageSize="historyFilter.PageSize" 
          :Total="history.TotalCount"  
          @sizeChanged="(args) => { historyFilter.PageSize = args }"
          @pageChanged="(args) => { historyFilter.Page = args }"
          />
          
      </div>
      <div class="card p-5 mb-10" v-if="!history">
        <el-skeleton :rows="12" animated />
      </div>

      <div class="d-flex mb-5 justify-content-center" v-if="history">
        <el-button :loading="loading == 'exportXlsxHistory'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsxHistory">
          Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
        </el-button>
        <div class="d-flex align-items-center">
          <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
        </div>
      </div>

    </template>

  </div>
  
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import MoneyInput from "@/components/form/MoneyInput.vue";

import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import TaskSelectbox from '@/components/form/TaskSelectbox.vue';

import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';


import MultipleProductivityStatusSelectbox from '@/components/form/MultipleProductivityStatusSelectbox.vue';
import MultipleActivityLogStatusSelectbox from '@/components/form/MultipleActivityLogStatusSelectbox.vue';
import moment from "moment/min/moment-with-locales";
import Pagination from '@/components/Pagination';


export default {
    name: 'PayrollCalculatorIndex',
    components: {
      Breadcrumb,
      MoneyInput,
      TeamSelectbox,
      ProjectSelectbox,
      Pagination,
      TaskSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker,
      MultipleProductivityStatusSelectbox,
      MultipleActivityLogStatusSelectbox
    },

    data(){
        return {
          moment,
          meta: {
            title: 'Payroll calculator',
            desc:'You can calculate the payments of your freelance or project-based team members based on their working hours here.'
          },
          activeTab:'payroll',
          loading:null,
          markAsAllPaidLoading:false,
          filter: {
            TeamId: null,
            EmployeeId: null,
            ProjectId: null,
            DateFilter: 1,
            ProjectTaskId: null,
            StartDate: null,
            EndDate: null,
            ProductivityStatuses:[1],
            Statuses:[1],
            IncludeBlockedEmployees:false
          },
          historyFilter: {
            TeamId: null,
            EmployeeId: null,
            ProjectId: null,
            DateFilter: 1,
            ProjectTaskId: null,
            StartDate: null,
            EndDate: null,
            Page: 1,
            PageSize:12,
          }
        }
    },
    async mounted() {
      this.$setMeta(this.meta);

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      this.historyFilter.StartDate = this.$getToday()[0];
      this.historyFilter.EndDate = this.$getToday()[1];

      await this.$store.dispatch("getSubscription");
      await this.getData();
    },
    methods: {
      expand(row, isExpanded) {
        if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
        {
          this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
        }
        else
        {
          this.$refs.tab.store.states.expandRows.push(row);
        }
      },
      changeEmployeePayRate(item) {
        this.loading = 'pay-rate-' + item.ID;
        this.$prompt('You can use this to adjust your employee\'s hourly rate based on the filter criteria you\'ve set for past revisions. Remember that you can edit the current rate from the "Employees" screen. The edit here only affects unpaid past records.', 'Hourly pay rate', {
          confirmButtonText: 'Change',
          type:'info',
          cancelButtonText: 'Cancel',
          inputPlaceholder: 'ex: 1350.50',
          //inputType: '',
          inputValue:item.Employee.PayRate
        }).then(async ({ value }) => {
          var res = await this.$store.dispatch("payrollChangePayRate", { 
            EmployeeId: item.ID, 
            Filter: this.filter,
            PayRate:parseFloat(value)
          });
          await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("payrollExportXlsx", this.filter);
        if (res != null) {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      async exportXlsxHistory() {
        this.loading = 'exportXlsxHistory';
        var res = await this.$store.dispatch("payrollHistoryExportXlsx", this.historyFilter);
        if (res != null) {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      async getData() {
        this.loading = 'getData';
          await this.$store.dispatch("payroll", this.filter);
        this.loading = null;
      },
      async getDataHistory() {
        this.loading = 'getDataHistory';
        await this.$store.dispatch("payrollHistory", this.historyFilter);
        this.loading = null;
      },
      async markAsAllPaid() {
        this.markAsAllPaidLoading = true;

        this.$confirm('You are about to mark all items as paid in the current result. Please do not close your page before the operation is completed. It usually takes 2-4 minutes. This action cannot be undone. Are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, mark as paid',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {

          for (let i = 0; i < this.payroll.Items.length; i++) {
            const item = this.payroll.Items[i];

            this.loading = 'mark-as-paid-' + item.ID;
            if(item.TotalPayable > 0)
            {
              var res = await this.$store.dispatch("payrollMarkAsPaid", { 
                EmployeeId: item.ID, 
                Filter: this.filter,
                PaidPrice:item.TotalPayable
              });
            }
            this.loading = null;
          }
           await this.getData();
          this.$message.success("All items marked as paid successfully.");
          this.markAsAllPaidLoading = false;
        }).catch(() => {
            this.markAsAllPaidLoading = false;
        });
      },
      async markAsPaid(item) {
        this.loading = 'mark-as-paid-' + item.ID;

        this.$confirm('You are about to marked as paid the current result. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, mark as paid',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          var res = await this.$store.dispatch("payrollMarkAsPaid", { 
            EmployeeId: item.ID, 
            Filter: this.filter,
            PaidPrice:item.TotalPayable
           });

          if(res != null)
          {
            item.TotalPayable = 0;
            this.getData();
          }

          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
      async markAsUnPaid(item) {
        this.loading = 'mark-as-unpaid-' + item.ID;

        this.$confirm('You are about to cancel this payment. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, cancel',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          var res = await this.$store.dispatch("payrollMarkAsUnPaid", { 
            Id: item.ID
           });

          if(res != null)
          {
            item.IsCancelled = true;
            this.getDataHistory();
          }

          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });

        
      }
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      trackSettings() {
        return this.organization.TrackSettings;
      },
      payroll() {
        return this.$store.getters.getPayroll;
      },
      history() {
        return this.$store.getters.getPayrollHistory;
      },
      pricePerHourAvg() {
        if(!this.payroll)
          return "...";

        var price = 0;
        for (let i = 0; i < this.payroll.Items.filter(x => this.filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true).length; i++) {
          const element = this.payroll.Items.filter(x => this.filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true)[i];
          price += element.Employee.PayRate;
        }

        return price / this.payroll.Items.filter(x => this.filter.IncludeBlockedEmployees ? true : x.Employee.Blocked != true).length;
      },
    },
    watch: {
      'activeTab'() {
        if(this.activeTab == 'payroll')
        {
          this.getData();
        }
        else
        {
          this.getDataHistory();
        }
      },
      'filter.StartDate'() {
        this.getData();
      },
      'filter.TeamId'() {
        if (this.filter.TeamId != null) {
          if (this.filter.EmployeeId != null) {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if (emp.TeamId != this.filter.TeamId) {
              this.filter.EmployeeId = null;
            }
          }
        }
        this.getData();
      },
      'filter.EmployeeId'() {
        this.getData();
      },
      'filter.ProjectId'() {
        this.filter.ProjectTaskId = null;
        this.getData();
      },
      'filter.ProjectTaskId'() {
        this.getData();
      },
      'filter.Statuses'() {
        this.getData();
      },
      'filter.ProductivityStatuses'() {
        this.getData();
      },

      //History filter
      'historyFilter.StartDate'() {
        if(this.historyFilter.Page > 1)
        {
          this.historyFilter.Page = 1;
        }
        else
        {
          this.getDataHistory();
        }
      },
      'historyFilter.TeamId'() {
        if (this.historyFilter.TeamId != null) {
          if (this.historyFilter.EmployeeId != null) {
            var emp = this.employees.Items.find(x => x.ID == this.historyFilter.EmployeeId);
            if (emp.TeamId != this.historyFilter.TeamId) {
              this.historyFilter.EmployeeId = null;
            }
          }
        }
        this.getDataHistory();
      },
      'historyFilter.EmployeeId'() {
        if(this.historyFilter.Page > 1)
        {
          this.historyFilter.Page = 1;
        }
        else
        {
          this.getDataHistory();
        }
      },
      'historyFilter.ProjectId'() {
        this.filter.ProjectTaskId = null;
        if(this.historyFilter.Page > 1)
        {
          this.historyFilter.Page = 1;
        }
        else
        {
          this.getDataHistory();
        }
      },
      'historyFilter.ProjectTaskId'() {
        if(this.historyFilter.Page > 1)
        {
          this.historyFilter.Page = 1;
        }
        else
        {
          this.getDataHistory();
        }
      },
      'historyFilter.Page'() {
        this.getDataHistory();
        this.$toTop("payrollHistoryTable");
      },
      'historyFilter.PageSize'() {
        if(this.historyFilter.Page > 1)
        {
          this.historyFilter.Page = 1;
        }
        else
        {
          this.getDataHistory();
        }
        this.$toTop("payrollHistoryTable");
      },


    },
}
</script>
