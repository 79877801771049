import axios from "axios";

const store = {
  state: () => ({
    timelapseVideos:null,
  }),
  getters: {
    getTimelapseVideos(state) {
      return state.timelapseVideos;
    }
  },
  mutations: {
    setTimelapseVideos(state, payload) {
      state.timelapseVideos = payload;
    }
  },
  actions: {
    async timelapseVideos(store, payload) {
      var res = await axios.post("/timelapsevideos/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setTimelapseVideos", res.data.Data);
      return res.data.Data;
    },
    async deleteTimelapseVideo(store, payload) {
      var res = await axios.post("/timelapsevideos/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.state.timelapseVideos.Items = store.state.timelapseVideos.Items.filter(x => x.ID != payload.Id);
      return res.data.Data;
    }
  }
}

export default store;