import axios from "axios";

const store = {
  state: () => ({
    leaveRequests:null,
    leaveRights:null,
  }),
  getters: {
    getLeaveRequests(state) {
      return state.leaveRequests;
    },
    getLeaveRights(state) {
      return state.leaveRights;
    },
  },
  mutations: {
    setLeaveRequests(state, payload) {
      state.leaveRequests = payload;
    },
    setLeaveRights(state, payload) {
      state.leaveRights = payload;
    },
  },
  actions: {
    async leaveRights(store, payload) {
      var res = await axios.post("/leavemanagement/ListLeaveRights", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      store.commit("setLeaveRights", res.data.Data);
      return res.data.Data;
    },
    async leaveRightsExport(store, payload) {
      var res = await axios.post("/leavemanagement/ExportLeaveRights", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async getLeaveRight(store, payload) {
      var res = await axios.post("/leavemanagement/GetLeaveRight", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async getEmployeeLeaveRight(store, payload) {
      var res = await axios.post("/leavemanagement/GetEmployeeLeaveRight", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async addUpdateLeaveRight(store, payload) {
      var res = await axios.post("/leavemanagement/AddUpdateLaveRight", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async deleteLeaveRight(store, payload) {
      var res = await axios.post("/leavemanagement/DeleteLeaveRight", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      var index = store.state.leaveRights.Items.findIndex(x => x.ID == payload.Id);
      if(index != -1)
      {
        store.state.leaveRights.Items.splice(index, 1);
      }

      return res.data.Data;
    },
    async leaveRequests(store, payload) {
      var res = await axios.post("/leavemanagement/ListLeaveRequests", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      store.commit("setLeaveRequests", res.data.Data);
      return res.data.Data;
    },
    async leaveRequestsExport(store, payload) {
      var res = await axios.post("/leavemanagement/ExportLeaveRequests", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async getLeaveRequest(store, payload) {
      var res = await axios.post("/leavemanagement/GetLeaveRequests", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async addUpdateLeaveRequest(store, payload) {
      var res = await axios.post("/leavemanagement/AddUpdateLeaveRequest", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async deleteLeaveRequest(store, payload) {
      var res = await axios.post("/leavemanagement/DeleteLeaveRequest", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      var index = store.state.leaveRequests.Items.findIndex(x => x.ID == payload.Id);
      if(index != -1)
      {
        store.state.leaveRequests.Items.splice(index, 1);
      }

      return res.data.Data;
    },
    async approveLeaveRequest(store, payload) {
      var res = await axios.post("/leavemanagement/ApproveLeaveRequest", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async rejectLeaveRequest(store, payload) {
      var res = await axios.post("/leavemanagement/RejectLeaveRequest", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;