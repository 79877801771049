<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />


      <div class="card p-7 mb-5 bg-blue"  v-if="isNewAccount">
        <div class="card-header">
          <div class="card-title">
            <h3>Welcome to worktivity!</h3>
          </div>
        </div>

        <ul class="unordered-list mb-0 mt-3">
          <li>Please do not forget to set up your organization's track settings by  <router-link :to="{name: 'settings', query:{activeTab: 'track-settings'}}">clicking here</router-link>.</li>
          <li>You can start to invite your employees by clicking <router-link :to="{name: 'employees', query:{a: 1}}">clicking here</router-link>.</li>
          <li>You can download worktivity desktop agent by <a href="https://useworktivity.com/download" target="_blank">clicking here</a>.</li>
        </ul>
      </div>

      <MissingOrganizationInfo />

      <div class="card p-5" v-if="!workTimes">
        <el-skeleton :rows="16" animated />
      </div>
      <div class="card p-5" v-if="workTimes">
        <div class="overview-stats" v-dragscroll.x>
          <div class="stats-item purple">
            <div class="icon-badge"><i class="fas fa-user-friends"></i></div>
            <div class="stats-count">
              <span>{{ workTimes.Items.length }}</span>
            </div>
            <p>Employees</p>
          </div>

          <div class="stats-item green">
            <div class="icon-badge"><i class="fas fa-bolt"></i></div>
            <div class="stats-count">
              <span>{{ workTimes.Items.filter(x => x.Status == 1 || x.Status == 0).length }}</span>
            </div>
            <p>Working</p>
          </div>

          <div class="stats-item blue">
            <div class="icon-badge"><i class="fas fa-mug-hot"></i></div>
            <div class="stats-count">
              <span>{{ workTimes.Items.filter(x => x.Status == 2).length }}</span>
            </div>
            <p>On break</p>
          </div>

          <div class="stats-item orange">
            <div class="icon-badge"><i class="fas fa-bed"></i></div>
            <div class="stats-count">
              <span>{{ workTimes.Items.filter(x => x.Status == 3).length }}</span>
            </div>
            <p>Idle</p>
          </div>

          <div class="stats-item red">
            <div class="icon-badge"><i class="fas fa-door-open"></i></div>
            <div class="stats-count">
              <span>{{ workTimes.Items.filter(x => x.Status == 4).length }}</span>
            </div>
            <p>Stopped work</p>
          </div>

          <div class="stats-item yellow">
            <div class="icon-badge"><i class="fas fa-hourglass-start"></i></div>
            <div class="stats-count">
              <span>{{ workTimes.Items.filter(x => x.Status == 5).length }}</span>
            </div>
            <p>Yet to start</p>
          </div>

        </div>

        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            
            <div class="custom-list">
              <div class="list-header">
                <div class="list-title">
                  <el-tooltip class="item" effect="dark" content="It is calculated according to the today clock-in / clock-out of your employees" placement="top">
                    <i class="fas fa-info-circle"></i>
                  </el-tooltip>
                  <span>Clock-in / Clock-out</span>
                </div>
                <div class="list-action cursor-pointer" @click="$router.push({name:'work-times'})">
                  <span>see all</span><i class="fas fa-long-arrow-alt-right"></i>
                </div>
              </div>
              <div class="list-idn">
                <span>Name</span>
                <span>Last detected</span>
              </div>
              <div class="list-body">

                <div class="list-item" v-for="item in workTimes.Items" :key="item.id">

                  <el-tooltip :disabled="!$isNotNullOrWhiteSpace(item.Employee.EmployeeCode)" :content="item.Employee.EmployeeCode" placement="top">
                        <div class="lcol cursor-pointer" @click='$store.commit("setProfileEmployeeId", { id: item.Employee.Id, showDrawer: true })' v-if="item.Employee.User != null">
                    <el-avatar :size="25" :src="item.Employee.User.Image"></el-avatar>
                    <span>{{ item.Employee.User.FirstName }} <b>{{ item.Employee.User.LastName }}</b></span>
                  </div>
                  <div class="lcol" v-else>
                    <el-avatar :size="25" :src="item.Employee.Image"></el-avatar>
                    <span>{{ item.Employee.FirstName }} <b>{{ item.Employee.LastName }}</b></span>
                  </div>
                </el-tooltip>

                  

                  <div class="lcol">

                    <el-tooltip class="item" effect="dark" content="Clock-in / clock-out" placement="top">
                      <span class="badge gray" style="min-width:100px;">
                          <i class="fas fa-clock mr-1 text-primary"></i>
                          <span v-if="item.ClockIn != null">{{moment(item.ClockIn).format('HH:mm')}}</span>
                          <span v-else class="text-gray">--:--</span>
                          <span class="text-gray ml-1 mr-1">/</span>
                          <span v-if="item.ClockOut != null">{{moment(item.ClockOut).format('HH:mm')}}</span>
                          <span v-else class="text-gray">--:--</span>
                      </span>
                    </el-tooltip>

                  </div>
                </div>

              </div>
            </div>
          
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            
            <div class="custom-list" v-if="recentlyUsedApps">
              <div class="list-header">
                <div class="list-title">
                  <el-tooltip class="item" effect="dark" content="It is calculated based on the apps your employees have used for the last 10 minutes." placement="top">
                    <i class="fas fa-info-circle"></i>
                  </el-tooltip>
                  <span>Recently used apps</span>
                </div>
                <div class="list-action cursor-pointer" @click="$router.push({name:'apps-summary'})">
                  <span>see all</span><i class="fas fa-long-arrow-alt-right"></i>
                </div>
              </div>
              <div class="list-idn">
                <span>Application</span>
                <span>Using</span>
              </div>
              <div class="list-body">

                <template v-if="recentlyUsedApps.Items.length > 0">
                  <div class="list-item" v-for="item in recentlyUsedApps.Items" :key="item.ActivityApp.ID">
                    <div class="lcol">
                      <el-avatar :size="25" :src="item.ActivityApp.Icon" shape="square"></el-avatar>
                      <div>
                        <span class="application">{{ item.ActivityApp.Name }} 
                          <b class="cursor-pointer" v-if="item.ActivityApp.Url" @click="$openLink(item.ActivityApp.Url)">{{ item.ActivityApp.Url }}</b>
                        </span>
                        <el-tooltip class="ai-item" effect="dark" :content="'The Worktivity AI model automatically identified this application as suitable for the '+ item.ActivityApp.AICategory +' category.'" placement="bottom" v-if="item.ActivityApp.AICategory != null">
                          <p><i class="fa fa-info-circle"></i> {{ item.ActivityApp.AICategory }}</p>
                        </el-tooltip>
                      </div>
                    </div>
                  
                    <div class="lcol">
                      <span class="badge gray"><i class="fas fa-user-friends mr-1 text-primary"></i> {{ item.EmployeeCount }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <NoData />
                </template>

              </div>
            </div>
          </el-col>
        </el-row>

      </div>

      <div class="card p-5 mt-5" >

        <div class="card-header hr">
          <div class="card-title">
            <h3>Apps usage by AI categorization</h3>
          </div>
          <div class="card-actions">
            <el-tooltip class="item" effect="dark" content="Categories are automatically detected by the Worktivity's AI." placement="top">
                <span class="big-badge blue"><i class="fas fa-info-circle text-blue"></i> Today</span>
            </el-tooltip>
          </div>
        </div>
        <el-row :gutter="24" v-if="appsPieChart">
          <template v-if="appsPieChart.data.length > 0">
            <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
              <Pie :data="appsPieChart.chartData" />
            </el-col>
            <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
              
              <div class="custom-list">
                <div class="list-idn">
                  <span>Category</span>
                  <span></span>
                  <span>Total Minutes</span>
                </div>
                <div class="list-body">

                  <div class="list-item" v-for="item in appsPieChart.data" :key="item.Category">
                    <div class="lcol">
                      <span class="">{{ item.Category ?? 'Pending for categorization' }}</span>
                    </div>
                    <div style="display:flex;">
                      <div class="lcol mr-2" style="width:150px;">
                          <el-progress :color="$store.state.darkMode ? '#747ed1' : ''" :stroke-width="10" :percentage="parseInt($percentage(item.Duration, $sum(appsPieChart.data, 'Duration')))" style="width:100%;"></el-progress>
                        </div>
                      <div class="lcol justify-content-end d-flex" style="width:150px;">
                        <span class="badge gray">{{ $parseTime(item.Duration) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </template>
          <template v-else>
            <NoData />
          </template>
        </el-row>
        <el-row :gutter="24" v-else>
          <el-skeleton :rows="4" animated />
        </el-row>
      </div>

      

      <div class="card p-5 mt-5" >

        <div class="card-header hr">
          <div class="card-title">
            <h3>Websites by AI categorization</h3>
          </div>
          <div class="card-actions">
            <el-tooltip class="item" effect="dark" content="Categories are automatically detected by the Worktivity's AI." placement="top">
                <span class="big-badge blue"><i class="fas fa-info-circle text-blue"></i> Today</span>
            </el-tooltip>
          </div>
        </div>

        
        <el-row :gutter="24" v-if="websitesPieChart">
          
          <template v-if="websitesPieChart.data.length > 0">
            <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
            <div class="custom-list">
              <div class="list-idn">
                <span>Website</span>
                <span>Total Minutes</span>
              </div>
              <div class="list-body">
                <div class="list-item" v-for="(item,index) in websitesPieChart.data" :key="item.ActivityApp.Url + index">
                   <div class="lcol">
                    <span class="">{{ item.ActivityApp.Url }}</span>
                  </div>
                  <div style="display:flex;">
                        <div class="lcol mr-2" style="width:150px;">
                            <el-progress :color="$store.state.darkMode ? '#747ed1' : ''" :stroke-width="10" :percentage="parseInt($percentage(item.Duration, $sum(websitesPieChart.data, 'Duration')))" style="width:100%;"></el-progress>
                          </div>
                        <div class="lcol justify-content-end d-flex" style="width:150px;">
                          <span class="badge gray">{{ $parseTime(item.Duration) }}</span>
                        </div>
                      </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
            <Pie :data="websitesPieChart.chartData" />
          </el-col>
          </template>
          <template v-else>
            <NoData />
          </template>
        </el-row>
        <el-row :gutter="24" v-else>
          <el-skeleton :rows="3" animated />
        </el-row>
      </div>

      <div class="card p-5 mt-10 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>Recent screenshots</h3>
          </div>
          <div class="card-actions cursor-pointer" @click="$router.push({name:'screenshots'})">
            <span>see all</span><i class="fas fa-long-arrow-alt-right"></i>
          </div>
        </div>

        <NoData v-if="screenshots && screenshots.TotalCount == 0" />
        
        <div class="scr-carousel mt-3" v-if="screenshots">

          <CoolLightBox 
            :items="lightboxItems" 
            :index="screenshotLightboxIndex"
            @close="screenshotLightboxIndex = null">
          </CoolLightBox>
          
          <Flicking :options="{ align: 'prev', panel: '220px' }">
              <div class="scr-item cursor-pointer" v-for="(item,index) in screenshots.Items" :key="item.ID" @click="screenshotLightboxIndex = index">
                <div class="scr-image">
                  <img :src="item.Screenshot" alt="">
                </div>
                <div class="scr-hover">
                  <div class="scr-app">
                    <span><b>{{ item.OrganizationApp.ActivityApp.Name }}</b></span>
                    <span class="ai-category" v-if="item.OrganizationApp.ActivityApp.AICategory">{{ item.OrganizationApp.ActivityApp.AICategory }}</span>
                    <span v-if="item.Url != null">{{ item.Url }}</span>
                  </div>
                </div>
                 <div class="scr-text">
                    <h3>{{ item.User.Title }}</h3>
                    <span><i class="fas fa-calendar"></i> {{ moment(item.CreateDate).format('DD MMMM') }} <i class="fas fa-clock"></i> {{ moment(item.CreateDate).format('HH:mm') }}</span>
                </div>
              </div>
          </Flicking>
        </div>
      </div>

      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <template v-if="riskUserSettings">
        <div v-for="item in riskUserSettings.Items" class="card p-5 mb-5" :key="item.ID" :id="'risk-user-' + item.ID">
          <div class="card-header" :class="item.IsOpen ? 'hr' : ''" :style="item.IsOpen ? '' : 'margin-bottom:0px;'">
            <div class="card-title">
              <h3>{{ item.Title }}</h3>
            </div>
            <div class="card-actions">

              <el-tooltip effect="dark" placement="top" content="Click to customize this settings">
                <span class="text-gray mr-5" @click="customizeRiskUser(item)">
                <i class="fas fa-pen"></i>
              </span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" :content="$getRiskUserTooltipText(item)" placement="top">
                  <span @click="toggleRiskUser(item)" class="big-badge blue"><i class="fas fa-info-circle text-blue"></i> {{ item.Conditions.length }} condition(s) <i class="fas text-blue" :class="item.IsOpen ? 'fa-angle-down' : 'fa-angle-up'"></i></span>
              </el-tooltip>

            </div>
          </div>
          <div class="card-body" v-if="item.IsOpen" v-loading="item.Data == null" style="min-height: 100px;" :key="item.ID + '-' + item.IsOpen">
            <template v-if="item.Data != null">
              <template v-if="item.Data.Items.length > 0">

                <el-table :data="item.Data.Items" class="w-100">
          
                <el-table-column label="Employee" min-width="350">
                      <template slot-scope="scope">
                        <EmployeeTablePreview :employee="scope.row.Employee" />
                      </template>
                    </el-table-column>
                
                <el-table-column class-name="text-center" :label="'Condition ' + (conditionIndex + 1)" width="180" v-for="(condition, conditionIndex) in item.Conditions" :key="'condition' + conditionIndex">
                  <template slot-scope="props">
                    <el-tooltip :content="$getRiskUserConditionText(condition)" placement="top">
                      <span class="badge cursor-pointer">{{ $parseTime(item.Data.Items[props.$index].Values[conditionIndex]) }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
              </template>
              <template v-else>
                <NoData />
              </template>
            </template>
          </div>
        </div>
      </template>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import {Flicking} from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import moment from "moment/min/moment-with-locales";

import { dragscroll } from "vue-dragscroll";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

import MissingOrganizationInfo from '@/components/form/MissingOrganizationInfo.vue';


ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'OverviewIndex',
    directives: {
      dragscroll
    },
    components: {
      Breadcrumb,
      Flicking,
      CoolLightBox,
      Pie,
      MissingOrganizationInfo
    },

    data(){
        return {
          moment,
          chartColors: ["#8B4DFF","#FF92AE","#66CB9F","#F7936F","#F16063","#68DBF2","#A6B7D4","#FFEF5E", "#505780","#92AEFF"],
          items: [
        'https://cosmos-images2.imgix.net/file/spina/photo/20565/191010_nature.jpg?ixlib=rails-2.1.4&auto=format&ch=Width%2CDPR&fit=max&w=835',
        'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/nature-quotes-1557340276.jpg?crop=0.666xw:1.00xh;0.168xw,0&resize=640:*',
      ],
      index: null,
          meta: {
            title: 'Overview',
            desc: 'You can reach your today\'s overview reports here.'
          },
          screenshotLightboxIndex:null,
        }
    },
    async mounted() {
      this.$setMeta(this.meta);

      this.$store.dispatch("workTimes", { 
        DateFilter: 1,
        StartDate: this.$getToday()[0],
        EndDate: this.$getToday()[1]
       });
       
      this.$store.dispatch("recentlyUsedApps", { 
        DateFilter: 1,  
        StartDate: this.$getToday()[0],
        EndDate: this.$getToday()[1]
      });

      this.$store.dispatch("screenshots", { 
        IncludeOrganizationApps:true, 
        IncludeUsers:true, 
        Page: 1, 
        PageSize: 20, 
        DateFilter: 1,
        StartDate: this.$getToday()[0],
        EndDate: this.$getToday()[1]
      });

      this.$store.dispatch("websites", { 
        DateFilter: 1,
        StartDate: this.$getToday()[0],
        EndDate: this.$getToday()[1]
      });

      this.$store.dispatch("apps", { 
        DateFilter: 1,
        StartDate: this.$getToday()[0],
        EndDate: this.$getToday()[1]
      });

      if(this.$isInRole('Owner,Coowner'))
      {
        await this.getRiskUserSettings();
        // if(this.riskUserSettings.Items.length > 0) {
        //   this.toggleRiskUser(this.riskUserSettings.Items[0]);
        // }
      }
    },
    methods: {
      customizeRiskUser(item) {
        this.$router.push({name: 'settings', query: {
          reportId: item.ID
        }});
      },
      async getRiskUserSettings() {
        var res = await this.$store.dispatch("riskUserSettings", {
          Page:1,
          PageSize:1000,
        });
      },
      async toggleRiskUser(item) {

        if(!item.IsOpen)
        {
          this.$store.dispatch("getRiskUserSettingsData", {
            Id:item.ID,
          });
        }
        {
          item.Data = null;
        }

        item.IsOpen = !item.IsOpen;
      },

    },
    computed: {
      riskUserSettings() {
        return this.$store.getters.getRiskUserSettings;
      },
      apps() {
        return this.$store.getters.getApps;
      },
      appsPieChart() {
        if(!this.$store.getters.getApps)
        {
          return null;
        }
        
        var temp = [];
        for (let i = 0; i < this.$store.getters.getApps.Items.length; i++) {
          const app = this.$store.getters.getApps.Items[i];

          var index = temp.findIndex(x => x.Category == app.OrganizationApp.ActivityApp.AICategory);

          if(index != -1){
            temp[index].Duration = temp[index].Duration += app.TotalMins;
          }
          else
          {
            temp.push({
              Category: app.OrganizationApp.ActivityApp.AICategory,
              Duration: app.TotalMins
            });
          }
        }

        temp = temp.sort((a, b) => (a.Duration < b.Duration) ? 1 : -1);

        var result = {
          data: temp,
          chartData: (temp.length > 5 ? temp.slice(0,5) : temp)
        }

        for (let i = 0; i < result.chartData.length; i++) {
          result.chartData[i].Color = (i > this.chartColors.length ? this.chartColors[0] : this.chartColors[i])
        }

        result.chartData = {
          labels: result.chartData.map(x => ((x.Category == null ? "Not Categorized" : x.Category))),
          datasets: [
            {
              backgroundColor: result.chartData.map(x => (x.Color)),
              data: result.chartData.map(x => (x.Duration))
            }
          ]
        };

        return result;
      },
      websitesPieChart() {
        if(!this.$store.getters.getWebsites)
        {
          return null;
        }
        
        var temp = [];
        for (let i = 0; i < this.$store.getters.getWebsites.Items.length; i++) {
          const app = this.$store.getters.getWebsites.Items[i];
          
          var index = temp.findIndex(x => x.Category == app.OrganizationApp.ActivityApp.AICategory);
          if(index != -1){
            temp[index].Duration = temp[index].Duration += app.TotalMins;
          }
          else
          {
            temp.push({
              Category: app.OrganizationApp.ActivityApp.AICategory,
              Duration: app.TotalMins,
              ActivityApp: app.OrganizationApp.ActivityApp
            });
          }
        }

        temp = temp.sort((a, b) => (a.Duration < b.Duration) ? 1 : -1);

        var result = {
          data: temp,
          chartData: (temp.length > 5 ? temp.slice(0,5) : temp)
        }

        for (let i = 0; i < result.chartData.length; i++) {
          result.chartData[i].Color = (i > this.chartColors.length ? this.chartColors[0] : this.chartColors[i])
        }

        result.chartData = {
          labels: result.chartData.map(x => ((x.Category == null ? "Not Categorized" : x.Category))),
          datasets: [
            {
              backgroundColor: result.chartData.map(x => (x.Color)),
              data: result.chartData.map(x => (x.Duration))
            }
          ]
        };

        return result;
      },
      websites() {
        return this.$store.getters.getWebsites;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      workTimes() {
        var work = this.$store.getters.getWorkTimes;
        if(work)
        {
          work.Items = work.Items.filter(x => x.Employee.Blocked != true);
        }

        return work;
      },
      screenshots() {
        return this.$store.getters.getScreenshots
      },
      activityLogs() {
        return this.$store.getters.getActivityLogs
      },
      lightboxItems() {
        return this.screenshots.Items.map(x => ({
          title: x.User.Title,
          description: x.OrganizationApp.ActivityApp.Name + '' + (x.Url != null ? ' | ' + x.Url : ''),
          src: x.Screenshot
        }));
      },
      recentlyUsedApps() {
        return this.$store.getters.getRecentlyUsedApps;
      },
      isNewAccount() {
        return (this.moment(this.organization.CreateDate).toDate() > this.moment(new Date()).add(-10, 'm').toDate())
      }
    }
}
</script>

