<template>
      <el-select v-model="id" filterable placeholder="All customers" v-if="customers" @change="selectionChanged" clearable @clear="clearSelection">
        <el-option
          v-for="item in customers.Items"
          :key="item.ID"
          :label="item.Name + ' ' + item.Surname"
          :value="item.ID">
          <div class="d-flex justify-content-between">
            <span>{{ item.Title ?? item.Name + ' ' + item.Surname }}</span> 
            <span v-if="item.Email != null" style="font-size:11px; font-style:italic;">{{ item.Email }}</span>
            
          </div>
        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'CustomerSelectbox',
    props: ["selectedId"],
    data(){
        return {
          id:null,
        }
    },
    mounted() {
      this.id = this.selectedId;

      if(this.customers == null)
      {
        this.getData();
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
      async getData() {
        await this.$store.dispatch("getCustomers");
      }
    },
    computed: {
      customers() {
        return this.$store.getters.getCustomers;
      }
    }
}
</script>
