import axios from "axios";

const store = {
  state: () => ({
    showDrawer:false,
    employeeId:null,
    employee:null,
    workTimes:null,
    productivity:null,
    activityLogs:null,
    screenshots:null,
    tasks:null,
    activityStats:null,
    activeProfileDate:null
  }),
  getters: {
    getProfileShowDrawer(state) {
      return state.showDrawer;
    },
    getActiveProfileDate(state) {
      return state.activeProfileDate;
    },
    getProfileEmployeeId(state) {
      return state.employeeId;
    },
    getProfileEmployee(state) {
      return state.employee;
    },
    getProfileWorkTimes(state) {
      return state.workTimes;
    },
    getProfileProductivity(state) {
      return state.productivity;
    },
    getProfileActivityLogs(state) {
      return state.activityLogs;
    },
    getProfileScreenshots(state) {
      return state.screenshots;
    },
    getProfileTasks(state) {
      return state.tasks;
    },
    getActivityStats(state) {
      return state.activityStats;
    },
  },
  mutations: {
    setProfileEmployee(state, payload) {
      state.employee = payload;
    },
    setProfileWorkTimes(state, payload) {
      state.workTimes = payload;
    },
    setProfileProductivity(state, payload) {
      state.productivity = payload;
    },
    setProfileActivityLogs(state, payload) {
      state.activityLogs = payload;
    },
    setProfileScreenshots(state, payload) {
      state.screenshots = payload;
    },
    setProfileTasks(state, payload) {
      state.tasks = payload;
    },
    setActivityStats(state, payload) {
      state.activityStats = payload;
    },
    setProfileShowDrawer(state, payload) {
      state.showDrawer = payload;
      if(payload == false)
      {
        state.activeProfileDate = null;
        state.employee = null,
        state.workTimes = null,
        state.productivity = null,
        state.activityLogs = null,
        state.screenshots = null,
        state.tasks= null
      }
    },
    setProfileEmployeeId(state, payload) {
      state.employeeId = payload.id;
      if(payload.date)
      {
        state.activeProfileDate = payload.date;
      }
      if(payload.showDrawer != null)
      {
        if(payload.showDrawer)
        {
          state.showDrawer = true;
        }
        else {
          state.activeProfileDate = null;
          state.showDrawer = false;
          state.employee = null,
          state.workTimes = null,
          state.productivity = null,
          state.activityLogs = null,
          state.screenshots = null,
          state.tasks= null
        }
      }
    },
  },
  actions: {
    async getProfileEmployee(store, payload) {
      var res = await axios.post("/employee/list", {
        EmployeeIds: [store.state.employeeId],
        Page:1,
        PageSize:1,
        IncludeUsers:true,
        IncludeTeams:true,
      });
      if(res == null || res.data.HasError)
      {
        return null;
      }

      if(res.data.Data.Items.lenght == 0)
      {
        store.commit("setProfileEmployeeId", {
          id:null,
          showDrawer:false,
        });
      }

      store.commit("setProfileEmployee", res.data.Data.Items[0]);
      return res.data.Data;
    },
    async removeScreenshotFromState(store, payload) {
      store.state.screenshots.Items = store.state.screenshots.Items.filter(x => x.ID != payload.Id);
      return true;
    },
    async getProfileProductivity(store, payload) {
      var res = await axios.post("/insights/productivity", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProfileProductivity", res.data.Data);
      return res.data.Data;
    },
    async getProfileWorkTimes(store, payload) {
      var res = await axios.post("/insights/workTimes", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProfileWorkTimes", res.data.Data);
      return res.data.Data;
    },
    async activityEmployeeStatistics(store, payload) {
      var res = await axios.post("/insights/activityStatistics", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      // var now = new Date(payload.StartDate);
      // var dateStart = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // const dateEnd = new Date(dateStart);

      // dateEnd.setDate(dateEnd.getDate() + 1);
      // console.log(dateStart, dateEnd);

      // while(dateStart < dateEnd)
      // {
      //    dateStart.setTime(dateStart.getTime() + 1 * 60000);

      //    var index = res.data.Data.Items.findIndex(x => new Date(x.CreateDate).toISOString() == dateStart.toISOString())
      //    if(index != -1)
      //    {
      //     console.log("var");
      //     //console.log(dateStart.toISOString());
      //    }
      //    else
      //    {
      //     res.data.Data.Items.push({
      //       CreateDate: dateStart.toISOString(),
      //       productivity:null,
      //       Status:null
      //     });
      //    }
      // }

      // res.data.Data.Items.sort((a, b) => new Date(a.CreateDate) - new Date(b.CreateDate));

      // for (let i = 0; i < res.data.Data.Items.length; i++) {
      //   const element = res.data.Data.Items[i];
      //   //console.log(element.CreateDate);
      // }


      store.commit("setActivityStats", res.data.Data);
      return res.data.Data;
    },
    async getProfileActivityLogs(store, payload) {
      var res = await axios.post("/activityLogs/List", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      if(payload.Page > 1)
      {
        store.state.activityLogs.Items.push(...res.data.Data.Items);
      }
      else
      {
        store.commit("setProfileActivityLogs", res.data.Data);
      }
      return res.data.Data;
    },
    //
    async getProfileScreenshots(store, payload) {
      var res = await axios.post("/screenshots/List", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      if(payload.Page > 1)
      {
        store.state.screenshots.Items.push(...res.data.Data.Items);
      }
      else
      {
        store.commit("setProfileScreenshots", res.data.Data);
      }
      return res.data.Data;
    },
  },
}

export default store;