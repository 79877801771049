<template>
  <div class="container mt-10" id="timelapseVideosPage">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :selected="$getToday()" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId"  />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager')">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => {filter.Page = 1; getData()}" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
    </div>

    <div class="card p-5" v-if="!timelapseVideos">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5 mb-10" id="timelapseVideosTable" v-if="timelapseVideos">

      <CoolLightBox :items="lightboxItems" :index="timelapseVideosLightboxIndex" @close="timelapseVideosLightboxIndex = null">
      </CoolLightBox>

        <NoData v-if="timelapseVideos.TotalCount == 0" />


      <div class="screensohts-container" v-if="timelapseVideos.TotalCount > 0" v-loading="loading == 'getData'">
        <div v-for="(item,index) in timelapseVideos.Items" :key="item.ID" >

          <div class="scr-item">
            <div class="scr-image">
              <img :src="item.Url" alt="">
            </div>
            <div class="scr-hover">
              <div class="scr-app">
                <span><b>{{ item.Employee.User.Title }} </b></span>
                <span>{{ item.Title }}</span>
                <el-row class="mt-2">
                  <el-button type="info" icon="fas fa-play" size="mini" circle @click="timelapseVideosLightboxIndex = index"></el-button>
                  <el-button type="danger" icon="fas fa-trash" size="mini" v-if="item.HasDeletePermission" @click="deleteTimelapseVideo(item)" :loading="loading == 'delete-' + item.ID" circle></el-button>
                </el-row>
              </div>
            </div>
            <div class="scr-text timelapse-video-text">
              <div class="timelapse-info">
                <h3>{{ item.Employee.User.Title }}</h3>
              <span><i class="fas fa-calendar"></i> {{ moment(item.CreateDate).format('DD MMMM') }} <i class="fas fa-clock"></i> {{ moment(item.CreateDate).format('HH:mm') }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Pagination 
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="timelapseVideos.TotalCount"  
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />

    </div>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import Pagination from '@/components/Pagination';
import moment from "moment/min/moment-with-locales";

import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
    name: 'TimelapseVideosIndex',
    components: {
      Breadcrumb,
      TeamSelectbox,
      EmployeeSelectbox,
      DateTypeFilterSelectbox,
      DatePicker,
      Pagination,
      CoolLightBox
    },
    data(){
        return {
          timelapseVideosLightboxIndex:null,
          moment,
          loading:null,
          filter: {
            TeamId:null,
            EmployeeId:null,
            DateFilter:3,
            StartDate:null,
            EndDate:null,
            Page: 1,
            PageSize:24,
            IncludeUsers:true,
            IncludeEmployees:true,
          },
        }
    },
    mounted() {
      this.$setMeta(this.meta);

      this.filter.StartDate = this.$getToday()[0];
      this.filter.EndDate = this.$getToday()[1];

      this.getData();
    },
    methods: {
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("timelapseVideos", this.filter)
        this.loading = null;
      },
      async deleteTimelapseVideo(item) {
        this.loading = 'delete-' + item.ID;

        this.$confirm('You are about to delete a timelapse video. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          await this.$store.dispatch("deleteTimelapseVideo",{ Id: item.ID });
          await this.getData();
        }).catch(() => {
                
        });

        this.loading = null;
      }
    },
    computed: {
      meta() {
        return {
          title: 'Timelapse videos',
          desc:'We found ' + (this.timelapseVideos != null ? this.timelapseVideos.TotalCount : '...') + ' timelapse videos in your account.'
        };
      },
      timelapseVideos() {
        return this.$store.getters.getTimelapseVideos
      },
      lightboxItems() {
        return this.timelapseVideos.Items.map(x => ({
          title: x.Employee.User.Title,
          description: x.Title,
          src: x.VideoUrl,
          thumb:x.Url
        }));
      },
      employees() {
        return this.$store.getters.getEmployees
      },
    },
    watch: {
      'filter.StartDate'() {
        if (this.filter.Page != 1) {
          this.filter.Page = 1;
        }
        else {
          this.getData();
        }
      },
      'filter.TeamId'() {
        if(this.filter.TeamId != null)
        {
          if(this.filter.EmployeeId != null)
          {
            var emp = this.employees.Items.find(x => x.ID == this.filter.EmployeeId);
            if(emp.TeamId != this.filter.TeamId)
            {
              this.filter.EmployeeId = null;
            }
          }
        }

        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.EmployeeId'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("timelapseVideosPage");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("timelapseVideosPage");
      }
    }
}
</script>
