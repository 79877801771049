<template>
  <div class="container mt-10" id="invoicesPage">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="page-filter-area">
      <div class="filter-options">
        <div class="filter-item">
          <SearchInput :initialValue="filter.SearchTerm" @changed="(term) => filter.SearchTerm = term" />
        </div>

        <div class="filter-item">
          <CustomerSelectbox :selectedId="filter.CustomerId" @changed="(id) => filter.CustomerId = id" :key="filter.CustomerId" />
        </div>

        <div class="filter-item">
          <InvoiceStatusSelectbox :selectedId="filter.Status" @changed="(id) => filter.Status = id" :key="filter.Status" :nullable="true"/>
        </div>
        
      </div>
      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="() => {filter.Page = 1; getData()}" :loading="loading == 'getData'"></el-button>
      </el-tooltip>
      
    </div>

    <div class="card p-5" v-if="!invoices">
      <el-skeleton :rows="12" animated />
    </div>
    <template v-if="invoices">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{ invoices.TotalCount }} invoices(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-file-invoice" @click="() => {invoiceAddUpdateModalShow = true;}" round>Create invoice</el-button>
          </div>
        </div>

        <div class="activity-table">
          <NoData v-if="invoices.Items.length == 0" />
          <el-table :data="invoices.Items" v-if="invoices.Items.length > 0" v-loading="loading == 'getData'"  class="tasks-table">

            <el-table-column label="Invoice No" width="130">
              <template slot-scope="scope">
                <p class="text-purple">{{ scope.row.InvoiceNo }}</p>               
              </template>
            </el-table-column>

            <el-table-column label="Customer">
              <template slot-scope="scope">
                <p>{{ scope.row.Customer.Title }}</p>               
              </template>
            </el-table-column>

            <el-table-column label="Issued" width="130">
              <template slot-scope="scope">
                <p>
                  {{ moment(scope.row.Issued).format('DD MMM yyyy') }}
                </p>
              </template>
            </el-table-column>

            <el-table-column label="Total" width="130">
              <template slot-scope="scope">
                <p>
                  {{ $formatMoney(scope.row.Total, $getEnumTitle('CurrencyType', scope.row.Currency)) }}
                </p>
              </template>
            </el-table-column>

            <el-table-column label="Paid" width="130">
              <template slot-scope="scope">
                <p>
                  {{ $formatMoney(scope.row.Paid, $getEnumTitle('CurrencyType', scope.row.Currency)) }}
                </p>              
              </template>
            </el-table-column>


             <el-table-column label="Status" class-name="text-center" width="130">
              <template slot-scope="scope">
                <span class="badge" :class="getStatusBadgeBg(scope.row.Status)">{{ $getEnumTitle('OrganizationCustomerInvoiceStatus', scope.row.Status) }}</span>

              </template>
            </el-table-column>
            
            <el-table-column width="100" class-name="text-center">
              <template slot-scope="scope">
                <el-row>

                  <el-dropdown class="mr-3">
                      <el-button icon="fas fa-ellipsis-h" size="small" circle></el-button>
                      <el-dropdown-menu slot="dropdown">

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="editInvoice(scope.row)">
                          <span>
                            <i class="fas fa-file-invoice"></i>
                            View invoice
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="recordPayment(scope.row)" :disabled="[1,4].some(x => x == scope.row.Status)">
                          <span>
                            <i class="fas fa-dollar-sign"></i>
                            Record payment
                          </span>
                        </el-dropdown-item>


                        <el-dropdown-item class="d-flex align-items-center gap-05" @click.native="markAsSent(scope.row)" v-if="scope.row.Status == 1">
                          <span>
                            <i class="fas fa-check"></i>
                            Mark as awaiting
                          </span>
                        </el-dropdown-item>

                        <el-dropdown-item class="d-flex align-items-center gap-05 text-red" @click.native="deleteInvoice(scope.row)">
                          <span>
                            <i class="fas fa-trash"></i>
                            Delete invoice 
                          </span>
                        </el-dropdown-item>

                      </el-dropdown-menu>
                    </el-dropdown>
                </el-row>
              </template>
            </el-table-column>

          </el-table>

          <Pagination 
            :Page="filter.Page" 
            :PageSize="filter.PageSize" 
            :Total="invoices.TotalCount"  
            @sizeChanged="(args) => { filter.PageSize = args }"
            @pageChanged="(args) => { filter.Page = args }"
            />
            
        </div>

      </div>
    </template>

    <el-dialog :close-on-click-modal="false" class="medium" title="Create invoice" :visible.sync="invoiceAddUpdateModalShow" center modal-append-to-body destroy-on-close>
      <InvoiceAddUpdateModal @invoiceCreated="invoiceCreated" @closeDialog="invoiceAddUpdateModalShow = false" :data="createInvoice" v-if="invoiceAddUpdateModalShow"/>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="medium" title="Record payment" :visible.sync="invoiceRecordPaymentModalShow" center modal-append-to-body destroy-on-close>
      <InvoiceRecordPaymentModal @paymentRecordCreated="paymentRecordCreated" @closeDialog="invoiceRecordPaymentModalShow = false" :data="recordPaymentInvoice" v-if="invoiceRecordPaymentModalShow"/>
    </el-dialog>

  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import moment from "moment/min/moment-with-locales";

import Pagination from '@/components/Pagination';
import SearchInput from '@/components/form/SearchInput.vue';
import CustomerSelectbox from '@/components/form/CustomerSelectbox.vue';
import InvoiceStatusSelectbox from '@/components/form/InvoiceStatusSelectbox.vue';


import InvoiceAddUpdateModal from './InvoiceAddUpdateModal.vue';
import InvoiceRecordPaymentModal from './InvoiceRecordPaymentModal.vue';

export default {
    name: 'TasksIndex',
    components: {
        Breadcrumb,
        Pagination,
        SearchInput,
        InvoiceAddUpdateModal,
        CustomerSelectbox,
        InvoiceStatusSelectbox,
        InvoiceRecordPaymentModal
    },
    data(){
        return {
          loading:null,
          moment,
          
          invoiceRecordPaymentModalShow:false,
          recordPaymentInvoice:null,
          invoiceAddUpdateModalShow:false,
          createInvoice: {
            Id:null,
            CustomerId:null,
            Currency: 0
          },
          meta: {
            title: 'Invoices',
            desc:'You can manage your invoices here.'
          },
          filter: {
            SearchTerm:null,
            CustomerId:null,
            Status:null,
            Page: 1,
            PageSize:24,
          },
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      this.getData();
    },
    methods: {
      recordPayment(item) {
        this.recordPaymentInvoice = item;
        this.invoiceRecordPaymentModalShow = true;
      },
      paymentRecordCreated() {
        this.getData();
      },
      getStatusBadgeBg(status) {
        switch (status) {
          case 1: //Draft
            return "gray";
          case 2: //Sent
            return "blue";
          case 3: //Partially paid
            return "orange";
          case 4: //Paid
            return "green";
          default:
            break;
        }
      },
      async invoiceCreated(item) {
        this.$router.push({name:'update-invoice', query: {Id:item.ID}});
      },
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("customerInvoiceList", this.filter)
        this.loading = null;
      },
      editInvoice(item) {
        this.$router.push({name:'update-invoice', query: {Id:item.ID}});
      },
      previewInvoice(item) {
        this.loading = 'delete-' + item.ID;
        this.loading = null;
      },
      markAsSent(item) {
        this.loading = 'mark-as-send-' + item.ID;
          this.$confirm('You are about to mark this invoice as "awaiting." You cannot edit this invoice anymore. This action cannot be undone. Are you sure about this?', 'Warning', {
            confirmButtonText: 'Yes, mark as awaiting',
            cancelButtonText: 'Cancel',
            type: 'danger'
          }).then(async () => {
            await this.$store.dispatch("customerInvoiceMarkAsSent",{ Id: item.ID });
            await this.$store.dispatch("customerInvoiceList",this.filter);
          }).catch(() => {
              
          });
        this.loading = null;
      },
      deleteInvoice(item) {
        this.loading = 'delete-' + item.ID;
        this.$confirm('You are about to delete an invoice. This action cannot be undone, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          await this.$store.dispatch("customerInvoiceDelete",{ Id: item.ID });
          await this.$store.dispatch("customerInvoiceList",this.filter);
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
    },
    computed: {
      invoices() {
        return this.$store.getters.getCustomerInvoices;
      },
    },
    watch: {
      'filter.CustomerId'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Status'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.SearchTerm'() {
        this.filter.Page = 1;
        this.getData();
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("invoicesPage");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("invoicesPage");
      }
    }
}
</script>
