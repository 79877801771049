
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-green p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-check-circle"></i>
          <div>
            <p>Do you have a deal?</p>
            <p>Please redeem your code in the form below and submit it, and see what happens.</p>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="cancelSubscription">

        <el-form-item label="Your redeem code" required>
          <el-input v-model="formData.RedeemCode" type="text" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="RedeemCode" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" icon="fas fa-check-circle" @click="redeem" :loading="loading == 'redeem'">Redeem now</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'RedeemCodeModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            RedeemCode:null,
          }
        }   
    },
    methods: {
      async redeem() {
        this.loading = 'redeem';
        var res = await this.$store.dispatch("redeemCode", this.formData);
        if(res != null)
        {
          await this.$store.dispatch("getSubscription");
          this.$store.dispatch("getPlans");
          this.formData.RedeemCode = null;
          this.$emit("closeDialog");
        }
        this.loading = null;
      }
    }
}
</script>

