<template>
  <quill-editor 
    v-model="details" 
    :options="editorOption" 
    ref="myQuillEditor" 
    @ready="onEditorReady($event)"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @change="onEditorChange($event)"
    ></quill-editor>
</template>

<script>

import { quillEditor } from "vue-quill-editor";

export default {
    name: 'CustomQuillEditor',
    props: ["content", "placeholder"],
    components: {
        quillEditor
    },
    data(){
        return {
          details:null,
          editorOption: {
            modules: {
              toolbar: {
                container: [["bold", "italic", "underline", "strike"], [{ indent: "-1" }, { indent: "+1" }], [{ color: [] }, { background: [] }], ["clean"], ["link"]],
                handlers: {
                  
                }
              }
            }
          },
        }
    },
    mounted() {
      this.details = this.content;
    },
    methods: {
      onEditorReady(editor) {
        editor.root.setAttribute("data-placeholder", this.placeholder ?? '');
      },
      onEditorBlur(quill) {
        this.$emit("blurred", this.details);
        //console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        //console.log('editor focus!', quill)
      },
      onEditorChange({ quill, html, text }) {
        //console.log('editor change!', quill, html, text)
        this.$emit("changed", html);
      }
    }
}
</script>
