
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-blue p-5 mb-3" v-if="formData.EmployeeId == null">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <span>You have {{employeeUsageLeft}} employee usage left in your organization if you need more employees <el-button type="text" @click="$router.push({name:'manage-subscription'})">click here</el-button> to change your plan.</span>
          </div>
        </div>
      </div>

      <div class="card bg-blue p-5 mb-3" v-if="formData.EmployeeId != null && formData.User != null">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <span>If you edit the team or privileges of the employee, the employee session will be terminated.</span>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save" v-if="formData.EmployeeId != null || employeeUsageLeft > 0">
        <el-form-item required>

          

          <FormLabelItem title="Name" required :tooltip="formData.User != null ? 'You can not edit the employee\'s name. Only the employee can change their name from the profile page.'  : ''" />
          <el-input v-model="formData.FirstName" placeholder="John" required clearable :disabled="formData.User != null"></el-input>
          <ValidationErrorItem validationKey="FirstName" />
        </el-form-item>

        <el-form-item>
          <FormLabelItem title="Surname" required :tooltip="formData.User != null ? 'You cannot edit the employee\'s surname. Only the employee can change their surname from the profile page.' : ''" />
          <el-input v-model="formData.LastName" placeholder="Doe" required clearable :disabled="formData.User != null"></el-input>
          <ValidationErrorItem validationKey="LastName" />
        </el-form-item>

        <el-form-item>
          <FormLabelItem title="Email" required :tooltip="formData.User != null ? 'You cannot edit the employee\'s name. Only the employee can change their email from the profile page.' : ''" />
          <el-input v-model="formData.Email" type="email" placeholder="someone@example.com" required clearable :disabled="formData.User != null"></el-input>
          <ValidationErrorItem validationKey="Email" />
        </el-form-item>

        <el-form-item label="Team" required>
          <el-select v-model="formData.TeamId" clearable placeholder="Select...">
            <el-option
              v-for="item in teams.Items"
              :key="item.ID"
              :label="item.Title"
              :value="item.ID">
            </el-option>
          </el-select>
          <ValidationErrorItem validationKey="TeamId" />
        </el-form-item>

        <el-form-item>
          <FormLabelItem title="Role" required :tooltip="'Basically, co-owners have the same authorization as owners, while managers only see data for their team members. Employees can only see their own data and cannot access information about other employees.'" />

          <el-select v-model="formData.Role" clearable placeholder="Select...">
            <el-option
              v-for="item in $getEnums('TypeOfAuthority').filter(x => [3,4,5].some(y => y == x.Key))"
              :key="item.Key"
              :label="item.DisplayName"
              :value="item.Key">
            </el-option>
          </el-select>
          <ValidationErrorItem validationKey="Role" />
        </el-form-item>

        <el-form-item>
            <FormLabelItem title="Employee code" :tooltip="'When you have multiple employees with the same name or if you are integrated with different software, you can use this field to match employee records between both platforms.'" />
            <el-input v-model="formData.EmployeeCode" placeholder="ex: EM113344, optional" clearable></el-input>
            <ValidationErrorItem validationKey="EmployeeCode" />
          </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer" v-if="formData.EmployeeId != null || employeeUsageLeft > 0">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          {{ formData.EmployeeId != null ? 'Save changes' : 'Add employee' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EmployeesAddOrUpdateModal',
    props: ["data"],
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            User:null,
            EmployeeId:null,
            FirstName: null,
            LastName: null,
            Email: null,
            TeamId: null,
            Role: null,
            EmployeeCode:null,
          }
        }   
    },
    async mounted() {
      if(!this.teams)
      {
        await this.$store.dispatch("getTeams");
      }

      if(this.data)
      {
        this.formData = this.data;
      }
    },
    methods: {
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch(this.formData.EmployeeId == null ? "addEmployee" : "updateEmployee", this.formData);
       
        this.loading = null;
         if(res != null)
        {
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      teams() {
        return this.$store.getters.getTeams;
      },
      employees() {
        return this.$store.getters.getEmployees;
      },
      enums() {
        return this.$store.getters.getEnums;
      },
      account() {
            return this.$store.getters.getAccount;
      },
      organization() {
            return this.account.CurrentOrganization;
      },
      employeeUsageLeft() {
        return this.organization.Subscription.Users - this.employees.Items.filter(x => x.Blocked != true).length;
      }
    }
}
</script>

<style>

</style>