
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" v-loading="loading == 'init'">

        <div class="d-flex justify-content-between gap-1">
          <el-form-item label="Project" required="" class="w-100">
            <ProjectSelectbox :selectedId="formData.ProjectId" @changed="(id) => formData.ProjectId = id" :key="formData.ProjectId" />
            <ValidationErrorItem validationKey="ProjectId" />
          </el-form-item>

          <el-form-item label="Due date" class="w-100">
            <el-date-picker
                v-model="formData.DueDate"
                type="datetime"
                format="dd.MM.yyyy HH:mm"
                placeholder="Select date and time">
              </el-date-picker>
            <ValidationErrorItem validationKey="DueDate" />
          </el-form-item>
        </div>

        <el-form-item label="Task" required>
          <el-input v-model="formData.Title" placeholder="..." type="textarea" :autosize="{ minRows: 1, maxRows: 5 }" maxlength="500" clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Details">
          <CustomQuillEditor :key="rederQuill" :content="formData.Details" @changed="(data) => formData.Details = data" />
          <ValidationErrorItem validationKey="Details" />
        </el-form-item>

        <!-- <el-form-item label="Disable billing" v-if="trackSettings.EnableCostManagement">
          <el-switch v-model="formData.NonBillable" active-text="Disable billing for this task" />
          <ValidationErrorItem validationKey="NonBillable" />
        </el-form-item> -->

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save' || loading == 'init'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Add task' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import ProjectSelectbox from '@/components/form/ProjectSelectbox.vue';
import ProjectTaskPrioritySelectbox from '@/components/form/ProjectTaskPrioritySelectbox.vue';
import ProjectTaskStatusSelectbox from '@/components/form/ProjectTaskStatusSelectbox.vue';
import MultipleEmployeeSelectbox from '@/components/form/MultipleEmployeeSelectbox.vue';

export default {
    name: 'TasksAddUpdateModal',
    props: ["data"],
    components: {
        ProjectSelectbox,
        ProjectTaskPrioritySelectbox,
        ProjectTaskStatusSelectbox,
        MultipleEmployeeSelectbox
    },
    data(){
        return { 
          loading:null,
          rederQuill:false,
          formData: {
            Id:null,
            ProjectId:null,
            Title:null,
            Details:null,
            Source:0,
            OrderNo:1,
            AssigneeIds:[],
            Priority:null,
            DueDate:null,
            Status:0,
            ExternalTaskId: null,
            NonBillable:false
          }
        }   
    },
    async mounted() {
      this.loading = 'init';
      await Promise.all([
        await this.$store.dispatch("getProjects"),
        await this.$store.dispatch("getEmployees")
        ]);
      if(this.data)
      {
        this.formData = this.data;
      }
      this.loading = null;
      this.rederQuill = !this.rederQuill;
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("addUpdateTask", this.formData);
        this.loading = null;
        if(res != null)
        {
          this.$emit('taskCreated');
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      customers() {
        return this.$store.getters.getCustomers;
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      trackSettings() {
        return this.organization.TrackSettings;
      },
    }
}
</script>