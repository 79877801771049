
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save" v-loading="loading == 'init'">

        <el-form-item label="Company or Title" required>
          <el-input v-model="formData.Title" placeholder="Worktivity Inc." required clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <div class="d-flex justify-content-between gap-1">
          <el-form-item label="Name" class="w-100" required>
            <el-input v-model="formData.Name" placeholder="John" required clearable></el-input>
            <ValidationErrorItem validationKey="Name" />
          </el-form-item>

          <el-form-item label="Surname" class="w-100" required>
            <el-input v-model="formData.Surname" placeholder="Doe" required clearable></el-input>
            <ValidationErrorItem validationKey="Surname" />
          </el-form-item>
        </div>

        <div class="d-flex justify-content-between gap-1">
          <el-form-item label="Email" class="w-100">
            <el-input v-model="formData.Email" type="email" placeholder="someone@example.com" required clearable></el-input>
            <ValidationErrorItem validationKey="Email" />
          </el-form-item>

          <el-form-item label="Phone" class="w-100">
            <el-input v-model="formData.Phone" placeholder="+1 (555) 555-555" required clearable></el-input>
            <ValidationErrorItem validationKey="Phone" />
          </el-form-item>
        </div>
        
        <el-form-item label="Website">
          <el-input v-model="formData.Website" placeholder="https://someinc.com" required clearable></el-input>
          <ValidationErrorItem validationKey="Website" />
        </el-form-item>

        <el-form-item label="Address">
          <el-input v-model="formData.Address" placeholder="..." type="textarea" :rows="2" required clearable></el-input>
          <ValidationErrorItem validationKey="Address" />
        </el-form-item>

        <div class="d-flex justify-content-between gap-1">
          <el-form-item label="Tax office" class="w-100">
            <el-input v-model="formData.TaxOffice" placeholder="..." required clearable></el-input>
            <ValidationErrorItem validationKey="TaxOffice" />
          </el-form-item>

          <el-form-item label="Tax number" class="w-100">
            <el-input v-model="formData.TaxNumber" placeholder="..." required clearable></el-input>
            <ValidationErrorItem validationKey="TaxNumber" />
          </el-form-item>
        </div>


        <el-form-item label="Notes">
          <el-input v-model="formData.Notes" placeholder="..." type="textarea" :rows="3" required clearable></el-input>
          <ValidationErrorItem validationKey="Notes" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Add customer' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CustomersAddUpdateModal',
    props: ["data"],
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            Id:null,
            Name:null,
            Surname:null,
            Email:null,
            Website:null,
            Notes:null,
            Address:null,
            TaxOffice:null,
            TaxNumber:null,
            Title:null,
          }
        }   
    },
    async mounted() {
      this.loading = 'init';
      if(this.data)
      {
        this.formData = this.data;
      }

      this.loading = null;
    },
    methods: {
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("addUpdateCustomer", this.formData);
       
        this.loading = null;
        if(res != null)
        {
          await this.$store.dispatch("getCustomers");
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      
    }
}
</script>

<style>

</style>