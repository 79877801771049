import axios from "axios";

const store = {
  state: () => ({
    subscription:null,
    plans:null,
  }),
  getters: {
    getSubscription(state) {
      return state.subscription;
    },
    getPlans(state) {
      return state.plans;
    }
  },
  mutations: {
    setSubscription(state, payload) {
      state.subscription = payload;
    },
    setPlans(state, payload) {
      state.plans = payload;
    }
  },
  actions: {
    async getSubscription(store, payload) {
      var res = await axios.post("/subscription/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setSubscription", res.data.Data);
      return res.data.Data;
    },
    async getPlans(store, payload) {
      var res = await axios.post("/subscription/ListPlans", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setPlans", res.data.Data);
      return res.data.Data;
    },
    async startSubscription(store, payload) {

      if(payload.CardNumber)
      {
        payload.CardNumber = payload.CardNumber.replaceAll(" ", "");
        payload.CardNumber = payload.CardNumber.replaceAll("-", "");
      }
      
      var res = await axios.post("/subscription/startSubscription", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      
      return res.data.Data;
    },
    async cancelSubscription(store, payload) {
      var res = await axios.post("/subscription/cancelSubscription", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async redeemCode(store, payload) {
      var res = await axios.post("/subscription/RedeemCode", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async proceedDiscountCode(store, payload) {
      var res = await axios.post("/subscription/proceedDiscountCode", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;