import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    timesheet:null
  }),
  getters: {
    getTimesheet(state) {
      return state.timesheet;
    },
  },
  mutations: {
    setTimesheet(state, payload) {
      state.timesheet = payload;
    }
  },
  actions: {
    async getTimesheet(store, payload) {
      var res = await axios.post("/timesheet/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setTimesheet", res.data.Data);
      return res.data.Data;
    },
    async timesheetExport(store, payload) {
      var res = await axios.post("/timesheet/export", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
  }
}

export default store;