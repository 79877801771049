import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    affiliateOverview:null,
  }),
  getters: {
    getAffiliateOverview(state) {
      return state.affiliateOverview;
    }
  },
  mutations: {
    setAffiliateOverview(state, payload) {
      state.affiliateOverview = payload;
    }
  },
  actions: {
    async affiliateOverview(store, payload) {
      var res = await axios.post("/affiliate/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      store.commit("setAffiliateOverview", res.data.Data);
      return res.data.Data;
    },
    async joinAffiliate(store, payload) {
      var res = await axios.post("/affiliate/join", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("affiliateOverview", {});
      return res.data.Data;
    },
  }
}

export default store;