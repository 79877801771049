<template>
    <label class="el-form-item__label custom-form-label">
        <span><span class="text-danger" v-if="required != null">*</span> {{ title }}</span>
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip" placement="top">
            <i class="fas fa-info-circle text-blue"></i>
        </el-tooltip>
    </label>
</template>

<script>
export default {
    name: 'FormLabelItem',
    components: {

    },
    props:["title","required","tooltip"],
}
</script>
