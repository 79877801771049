<template>
  <div class="container mt-10 mb-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="card p-5" v-if="!teams && !workTimes">
        <el-skeleton :rows="12" animated />
    </div>

    <div class="activity-filter justify-content-between" v-dragscroll.x v-if="teams && workTimes">
      <div class="d-flex">
        <div class="filter-item purple" :class="filter.Statuses.length == 0 ? 'isactive' : ''" @click="filter.Statuses = []">
        <i class="fas fa-user-friends"></i>
        <span>All<b>{{ workTimes.Items.length }}</b></span>
      </div>
      <div class="filter-item green" :class="filter.Statuses.some(x => x == 0) ? 'isactive' : ''" @click="filter.Statuses = [0,1]">
        <i class="fas fa-bolt"></i>
        <span>Working<b>{{ workTimes.Items.filter(x => [0,1].some(y => y == x.Status)).length }}</b></span>
      </div>
      <div class="filter-item blue" :class="filter.Statuses.some(x => x == 2) ? 'isactive' : ''" @click="filter.Statuses = [2]">
        <i class="fas fa-mug-hot"></i>
        <span>On break<b>{{ workTimes.Items.filter(x => x.Status == 2).length }}</b></span>
      </div>
      <div class="filter-item orange" :class="filter.Statuses.some(x => x == 3) ? 'isactive' : ''" @click="filter.Statuses = [3]">
        <i class="fas fa-bed"></i>
        <span>Idle<b>{{ workTimes.Items.filter(x => x.Status == 3).length }}</b></span>
      </div>
      <div class="filter-item red" :class="filter.Statuses.some(x => x == 4) ? 'isactive' : ''" @click="filter.Statuses = [4]">
        <i class="fas fa-door-open"></i>
        <span>Stopped work<b>{{ workTimes.Items.filter(x => x.Status == 4).length }}</b></span>
      </div>
      <div class="filter-item yellow" :class="filter.Statuses.some(x => x == 5) ? 'isactive' : ''" @click="filter.Statuses = [5]">
        <i class="fas fa-hourglass-start"></i>
        <span>Yet to start<b>{{ workTimes.Items.filter(x => x.Status == 5).length }}</b></span>
      </div>
      </div>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>

    </div>

    <div class="card p-5"  v-if="teams && workTimes" v-loading="loading == 'getData'">
      <NoData v-if="workTimes.Items.filter(x => (filter.Statuses.length > 0 ? filter.Statuses.some(y => y == x.Status) : true)).length == 0" />

      <el-row :gutter="24">
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" v-for="item in workTimes.Items.filter(x => (filter.Statuses.length > 0 ? filter.Statuses.some(y => y == x.Status) : true))" :key="item.ID">
          <TeamMemberItem :member="item" />
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import TeamMemberItem from '../partials/TeamMemberItem';
import { dragscroll } from "vue-dragscroll";

export default {
    name: 'TeamIndex',
    directives: {
      dragscroll
    },
    components: {
      Breadcrumb,
      TeamMemberItem
    },
    data(){
        return {
          loading:null,
          filter: {
            Statuses: [],
          }
        }
    },
    async mounted() {
      await this.getData();
    },
    methods: {
      async getData() {
        this.loading = "getData";
        await Promise.all([
          this.$store.dispatch("getTeams"),
          this.$store.dispatch("workTimes", { 
            DateFilter: 1,
            StartDate: this.$getToday()[0],
            EndDate: this.$getToday()[1],
          })
        ]);
        this.loading = null;
      }
    },
    computed: {
      meta() {
        var metaInfo = {
          title: 'Team',
          desc: 'There are ' + (this.workTimes ? this.workTimes.Items.length : "...") + ' team members here.'
        };
        this.$setMeta(metaInfo);
        return metaInfo;
      },
      teams() {
        return this.$store.getters.getTeams;
      },
       workTimes() {
        var work = this.$store.getters.getWorkTimes;
        if(work)
        {
          work.Items = work.Items.filter(x => x.Employee.Blocked != true);
        }

        return work;
      },
    }
}
</script>
