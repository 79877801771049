<template>
      <el-select v-model="id" :clearable="clearable" filterable placeholder="All dates" @change="selectionChanged" @clear="clearSelection">
        <el-option
          v-for="item in dateFilterTypes"
          :key="item.Value"
          :label="item.Title"
          :value="item.Value">
        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'DateTypeFilterSelectbox',
    props: ["selectedId", "nullable"],
    data(){
        return {
          id:null,
          clearable:false,
          dateFilterTypes: [
            {
              Title: "Today",
              Value: 1
            },
            {
              Title: "Yesterday",
              Value: 2
            },
            {
              Title: "Last 3 days",
              Value: 3
            },
            {
              Title: "Last 7 days",
              Value: 4
            },
            {
              Title: "Last 30 days",
              Value: 5
            },
            {
              Title: "Custom date range",
              Value: 10
            }
          ]
        }
    },
    mounted() {
      this.id = this.selectedId;
      if(this.nullable)
      {
        this.clearable = this.nullable
      }
    },
    methods: {
      clearSelection() {
        this.id = null;
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
    }
}
</script>
