<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">
      
      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save">

        <el-form-item label="Employee" required v-if="$isInRole('Owner,Coowner')">
          <div class="filter-item">
            <EmployeeSelectbox placeholder="Select an employee..." :selectedId="formData.EmployeeId" @changed="(id) => formData.EmployeeId = id" :key="formData.EmployeeId" />
          </div>
          <ValidationErrorItem validationKey="EmployeeId" />
        </el-form-item>

        <div class="card bg-blue p-5 mb-3" v-if="$isInRole('Manager,Employee') && !employeeLeaveRights.Items.some(x => x.Count > 0)">
          <div class="card-alert">
            <i class="fas fa-info-circle"></i>
            <div>
              <p>You don't have any leave rights.</p>
            </div>
          </div>
        </div>


       <template v-if="employeeLeaveRights != null">
        <el-form-item label="Leave type" required>

            <el-select v-model="formData.Type" placeholder="Select...">
            <el-option
                v-for="item in $getEnums('LeaveRightType')"
                :key="item.Key"
                :label="item.DisplayName + ' ' + '(' + employeeLeaveRights.Items.find(x => x.Type == item.Key).Count + ' days left)'"
                :disabled="employeeLeaveRights.Items.find(x => x.Type == item.Key).Count <= 0"
                :value="item.Key">
                <span>
                  {{ item.DisplayName }} <span style="float:right">{{ '' + employeeLeaveRights.Items.find(x => x.Type == item.Key).Count + ' day(s) left' }}</span>
                </span>
              </el-option>
          </el-select>
          
          <!-- <el-radio-group v-model="formData.Type">
            <template v-for="item in $getEnums('LeaveRightType')" >
              <p :key="item.Key">
                <el-radio :label="item.Key" :key="item.Key" :disabled="employeeLeaveRights.Items.find(x => x.Type == item.Key).Count <= 0">
                {{ item.DisplayName }}
                {{ '(' + employeeLeaveRights.Items.find(x => x.Type == item.Key).Count + ')' }}
                </el-radio>
              </p>
            </template>
          </el-radio-group> -->
          <ValidationErrorItem validationKey="Type" />
        </el-form-item>

        <el-form-item label="Start date" class="w-100" required v-if="formData.Type != null">
          <el-date-picker
            v-model="formData.StartDate"
            type="date"
            format="dd.MM.yyyy"
            :picker-options="pickerOptions"
            placeholder="Select date">
          </el-date-picker>
          <ValidationErrorItem validationKey="StartDate" />
        </el-form-item>
        
        <el-form-item label="Duration (day)" required v-if="formData.Type != null">
          <el-input-number v-model="formData.Count" :min="0.5" :max="employeeLeaveRights.Items.find(x => x.Type == formData.Type).Count" :precision="1" :step="0.5" class="w-100" style="width:100%;"></el-input-number>
          <ValidationErrorItem validationKey="Reason" />
        </el-form-item>
        

        


        


        <div class="card bg-blue p-5 mb-3" v-if="offDayCalc != null">
          <div class="card-alert">
            <i class="fas fa-info-circle"></i>
            <div>
              <p>Return to work date: <b>{{ moment(offDayCalc.endDate).format('DD MMMM yyyy, dddd') }}</b></p>
              <p>Excluding <b>{{ offDayCalc.offDays }} days</b>  off, the employee will be considered on leave for <b>{{ formData.Count }} days</b>.</p>
            </div>
          </div>
        </div>

        <el-form-item label="Notes" v-if="formData.Type != null">
          <el-input v-model="formData.Notes" type="textarea" rows="3" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="Notes" />
        </el-form-item>
       </template>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          Add leave request
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import moment from "moment/min/moment-with-locales";

export default {
    name: 'LeaveRequestAddUpdateModal',
    props: ["data"],
    components: {
      EmployeeSelectbox
    },
    data(){
        return {
          moment,
          employeeLeaveRights:null,
          pickerOptions: {
          firstDayOfWeek:1,
          // disabledDate(time) {
          //   return time.getTime() > Date.now();
          // },
          shortcuts: [
            {
            text: 'Tomorrow',
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              start.setTime(start.getTime() + (3600 * 1000 * 24));
              picker.$emit('pick', start);
            },
          },
          {
            text: 'Today',
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              picker.$emit('pick', start);
            }
          }
        ]
        },
          loading:null,
          duration:null,
          formData: {
            Id:null,
            EmployeeId:null,
            Type:null,
            Count:1,
            Notes:null,
            StartDate:null,
          }
        }   
    },
    mounted() {
      if(this.data)
      {
        this.formData = this.data;
      }

      if(this.$isInRole('Employee,Manager'))
      {
        this.formData.EmployeeId = this.currentEmployeeId;
      }
    },
    methods: {
      setReason(reason) {
        debugger;
        this.formData.Reason = reason;
      },
      async save() {
        this.loading = 'save';
        
        if(this.offDayCalc != null)
        {
          this.formData.EndDate = this.offDayCalc.endDate;
          this.formData.OffDays = this.offDayCalc.offDays;
        }
        
        var res = await this.$store.dispatch("addUpdateLeaveRequest", this.formData);
        this.loading = null;
         if(res != null)
        {
          this.$emit('leaveRequestCreated');
          this.$emit('closeDialog');
        }
      },
      async getEmployeeLeaveRight() {

        this.employeeLeaveRights = null;
        this.loading = 'employee-leave-rights';

        if(this.formData.EmployeeId != null)
        {
          this.employeeLeaveRights = await this.$store.dispatch("getEmployeeLeaveRight", {
            Id: this.formData.EmployeeId
          });
        }

        this.loading = null;
      },
    },
    computed: {
      offDayCalc() {
        if(this.formData.StartDate == null)
        {
          return null;
        }

        var startDate = new Date(this.formData.StartDate);
        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + this.formData.Count);
        var offDays = 0;

        while (startDate <= endDate) {
            var dayOfWeek = startDate.getDay()
            if(dayOfWeek == 0)
            {
              dayOfWeek = 7;
            }

            if(!this.trackSettings.WorkingDays.some(x => x == dayOfWeek))
            {
              offDays += 1;
              endDate.setDate(endDate.getDate() + 1);
            }
            
            startDate.setDate(startDate.getDate() + 1);
        }
        
        return {
          offDays: offDays,
          endDate: endDate
        }
      },
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
      trackSettings() {
        return this.organization.TrackSettings;
      },
      currentEmployeeId() {
        return this.account.Employings.Items.find(x => x.OrganizationId == this.organization.ID).ID;
      },
    },
    watch: {
      'formData.EmployeeId'() {
        this.formData.Type = null;
        this.getEmployeeLeaveRight();
      },
      'formData.Type'() {
        this.formData.StartDate = null;
        this.formData.Count = 1;
      },
    }
}
</script>

<style>

</style>