<template>
    <nav class="navbar-menu" >
        <div class="logo">
             <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="cursor-pointer dark-logo" @click="$router.push({name:'my-activities'})" />
            <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="cursor-pointer white-logo" @click="$router.push({name:'my-activities'})" />
            

            <span id="navbutton" @click.stop="$store.dispatch('getShowMenu', !showMenu)" v-show="!showMenu">
                <i class="fas fa-bars"></i>
                <!-- <i class="fas fa-times" v-show="showMenu"></i> -->
            </span>
        </div>
        
        
        <div class="menu-container">
            <el-row class="download-btns d-flex">
                
                <a href="https://useworktivity.com/download" target="_blank" class="el-button is-round text-unset" round icon="fas fa-download">Download agent</a>
                <el-button type="primary" round icon="fas fa-plus" @click="goToAddEmployee" v-if="$isInRole('Owner,Coowner')">Add employee</el-button>
            </el-row>

            <label for="theme" class="theme">
                <span class="theme__toggle-wrap">
                    <input type="checkbox" class="theme__toggle" id="theme" role="switch" v-model="$store.state.darkMode" name="theme" value="dark" />
                    <span class="theme__icon">
                    <span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span
                    ><span class="theme__icon-part"></span>
                    </span>
                </span>
            </label>

            <div class="changelog-wrapper d-flex align-items-center mr-3"></div>
            

            <el-dropdown class="acc-menu" v-if="account">
                <span class="el-dropdown-link">
                    <div class="user-info">
                        <el-avatar class="acc-avatar" :size="30" :src="account.User.Image"></el-avatar>
                        <div class="user-text">
                            <span>{{ account.User.FirstName + ' ' + account.User.LastName }}</span>
                            <span v-if="account.CurrentOrganization != null">{{ account.CurrentOrganization.OrganizationNo }} - {{ account.CurrentOrganization.Title }}</span>
                            <span v-else>No organization</span>
                        </div>
                    </div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <template v-if="account.Employings">
                        <el-dropdown-item v-for="item in account.Employings.Items" :key="item.ID">
                        <template>
                            <div class="organisation-item" @click="switchOrganization(item.OrganizationId)">
                                <el-avatar class="organisation-avatar" :size="30" shape="square" :src="item.Organization.Image"></el-avatar>
                                <div class="organisation-text">
                                    <span>{{item.Organization.Title}}</span>
                                    <span>{{ item.Organization.OrganizationNo }} - {{ item.Team.Title }}</span>
                                </div>
                            </div>
                        </template>
                    </el-dropdown-item>
                    </template>
                    
                    <el-dropdown-item :divided="account.Employings != null" @click.native="$router.push({ name: 'create-organization' })">
                        <i class="fas fa-plus"></i> Create an organization
                    </el-dropdown-item>

                    <el-dropdown-item divided @click.native="$router.push({ name: 'account-settings' })">
                        <i class="fas fa-user-cog"></i> Settings
                    </el-dropdown-item>

                    <el-dropdown-item @click.native="$router.push({ name: 'billing' })">
                        <i class="fas fa-file-invoice"></i> Billing
                    </el-dropdown-item>

                    <el-dropdown-item @click.native="$router.push({ name: 'affiliate' })">
                        <i class="fas fa-coins"></i> Affiliate
                    </el-dropdown-item>

                    <el-dropdown-item divided @click.native="signOut"><i class="fas fa-sign-out-alt"></i> Sign-out</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        
    </nav>
</template>


<script>
export default {
    name: 'NavBar',
    components: {

    },
    data(){
        return {

        }
    },
    mounted() {
       var script = document.createElement('script');
           script.src = "https://cdn.headwayapp.co/widget.js";
           document.body.appendChild(script);
    },
    methods: {
        goToAddEmployee() {
            if(this.$route.name == "employees")
            {
                this.$root.$emit('openEmployeeModal');
            }
            else
            {
                this.$router.push({name:'employees', query:{a:1}}).catch(() => {});
            }
        },
        async signOut() {
            await this.$store.dispatch("signOut");
            window.location.href="/";
            //await this.$router.push({name: "login"});
        },
        async switchOrganization(id) {

            var res = await this.$store.dispatch("switchOrganization", { Id: id });
            if(res != null)
            {
                this.$store.commit("setAccount", null);
                setTimeout(function() {
                    window.location.href="/app/tracking/my-activities";
                },1000);
            }

        }
    },
    computed: {
        account() {
            return this.$store.getters.getAccount;
        },
        showMenu() {
            return this.$store.getters.getShowMenu;
        },
        
    }
}
</script>

