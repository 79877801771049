import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    employees:null
  }),
  getters: {
    getEmployees(state) {
      return state.employees;
    }
  },
  mutations: {
    setEmployees(state, payload) {

      payload.Items = payload.Items.sort((a, b) => {
          return a.Role - b.Role;
      });

      state.employees = payload;
    }
  },
  actions: {
    async getEmployees(store, payload) {
      if(!payload)
      {
        payload = {
          Page: 1,
          PageSize: 1000,
          IncludeTeams: true,
          IncludeUsers:true,
        };
      }
      var res = await axios.post("/employee/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setEmployees", res.data.Data);
      return res.data.Data;
    },
    async addEmployee(store, payload) {
      var res = await axios.post("/employee/create", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getEmployees");
      return res.data.Data;
    },
    async updateEmployee(store, payload) {
      var res = await axios.post("/employee/update", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getEmployees");
      return res.data.Data;
    },
    async deleteEmployee(store, payload) {
      var res = await axios.post("/employee/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getEmployees");
      return res.data.Data;
    },
    async updateEmployeeTrackSettings(store, payload) {
      var res = await axios.post("/employee/updateTrackSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getEmployees");
      return res.data.Data;
    },
    async updateEmployeeCostSettings(store, payload) {
      var res = await axios.post("/employee/UpdateCostSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getEmployees");
      return res.data.Data;
    },
    async resendEmployeeInvitation(store, payload) {
      var res = await axios.post("/employee/resendInvitation", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.dispatch("getEmployees");
      return res.data.Data;
    },
    async toggleBlockEmployee(store, payload) {
      var res = await axios.post("/employee/Block", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }


      var index = store.state.employees.Items.findIndex(x => x.ID == payload.Id);
      if(index != -1)
      {
        store.state.employees.Items[index].Blocked = !store.state.employees.Items[index].Blocked;
      }

      return res.data.Data;
    },
  },
}

export default store;