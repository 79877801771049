import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    account:null,
    notifications: null,
  }),
  getters: {
    getAccount(state) {
      return state.account;
    },
    getNotifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    setAccount(state, payload) {

      if(payload && payload.CurrentOrganization != null)
      {
        payload.CurrentOrganization.TrackSettings.Clockin = payload.CurrentOrganization.TrackSettings.Clockin.substring(0,5);
        payload.CurrentOrganization.TrackSettings.Clockout = payload.CurrentOrganization.TrackSettings.Clockout.substring(0,5);
      }
      state.account = payload;
    },
    setCurrentOrganizationTracksettings(state, payload) {
      state.account.CurrentOrganization.TrackSettings = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    }
  },
  actions: {
    async getAccount(store, payload) {
      var res = await axios.post("/account/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setAccount", res.data.Data);
      return res.data.Data;
    },
    async getNotifications(store, payload) {
      var res = await axios.post("/account/notificationSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setNotifications", res.data.Data);
      return res.data.Data;
    },
    async updateNotifications(store, payload) {
      var res = await axios.post("/account/UpdateNotifications", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async updateProfile(store, payload) {
      var res = await axios.post("/account/updateProfile", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      await store.dispatch("getAccount");
      return res.data.Data;
    },
    async featurePending(store, payload) {
      var res = await axios.post("/account/FeaturePending", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async switchOrganization(store, payload) {
      var res = await axios.post("/account/SwitchOrganization", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      await store.commit("signIn", res.data.Data.Token);
      Message.success("Organization switched successfully, redirecting...");
      return res.data.Data;
    },
    async updatePassword(store, payload) {
      var res = await axios.post("/account/updatePassword", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      await store.dispatch("getAccount");
      return res.data.Data;
    },
    async deleteAccount(store, payload) {
      var res = await axios.post("/account/deleteAccount", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;