
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">
      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save">

        
          
          <el-form-item>
            
            <FormLabelItem title="Hourly pay rate" tooltip="It will be used to manage your employee's payroll." required  />
            <MoneyInput :min="0" :max="10000" :fixed="2" v-model="formData.PayRate" clearable>
              <template slot="append">{{ $getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency) }}</template>
            </MoneyInput>
            <ValidationErrorItem validationKey="PayRate" />
          </el-form-item> 

          <el-form-item>
            <FormLabelItem title="Hourly bill rate" tooltip="It will be used to manage your customer's invoice." required  />
            <MoneyInput :min="0" :max="10000" :fixed="2" v-model="formData.BillRate" clearable>
              <template slot="append">{{$getEnumTitle('CurrencyType', organization.TrackSettings.CostManagementCurrency)}}</template>
            </MoneyInput>
            <ValidationErrorItem validationKey="BillRate" />
          </el-form-item> 
          

          <el-form-item label="Limitations">
            <el-switch
              v-model="formData.WeeklyLimit"
              active-text="Enable weekly limit"
              inactive-text="">
            </el-switch>
            <ValidationErrorItem validationKey="WeeklyLimit" />
          </el-form-item> 

          <el-form-item v-if="formData.WeeklyLimit">
            <FormLabelItem title="Weekly hours" required />
            <el-input-number style="width:100%;" :min="1" :max="168" v-model="formData.WeeklyLimitHours" />
            <ValidationErrorItem validationKey="WeeklyLimitHours" />
          </el-form-item> 

      </el-form>
    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          Save changes
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import MoneyInput from '@/components/form/MoneyInput.vue';

export default {
    name: 'EditCostSettingsModal',
    props: ["data"],
    components: {
        MoneyInput
    },
    data(){
        return { 
          loading:null,
          formData: {
            EmployeeId: null,
            PayRate: 0,
            BillRate: 0,
            WeeklyLimit: false,
            WeeklyLimitHours: 45
          }
        }   
    },
    async mounted() {
      if(this.data)
      {
        this.formData = this.data;
      }
    },
    methods: {
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("updateEmployeeCostSettings", this.formData);
       
        this.loading = null;
        if(res != null)
        {
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      account() {
            return this.$store.getters.getAccount;
      },
      organization() {
            return this.account.CurrentOrganization;
      },
      subscription() {
            return this.organization.Subscription;
      }
    },
    watch: {
      // 'formData.EnableCustomTrackSettings'() {
      //   if(this.formData.EnableCustomTrackSettings == false)
      //   {
      //     this.formData.EnableScreenshots = false;
      //     this.formData.BlurScreenshots = false,
      //     this.formData.EnableTimelapseVideos = false,
      //     this.formData.ScreenCaptureIntervalMins = 5
      //   }
      // }
    }
}
</script>

<style>

</style>