<template>
  <div class="card p-5 bg-purple">
        <h3 class="mt-1 mb-1">Coming soon</h3>
        <p class="mt-1 mb-1">This feature is currently on the development list.</p>
        <p class="mt-1 mb-1">Please let us know if you intend to use this feature by clicking <a href="javascript:;" @click="send">here</a>. We will be sending you an e-mail when the feature is complete.</p>
      </div>
</template>

<script>
export default {
    name: 'ComingSoon',
    props: ["feature"],
    data() {
      return {
        loading:null
      };
    },
    methods: {
      async send() {
        if(this.loading == 'send')
        {
          return;
        }
        this.loading = 'send';
        await this.$store.dispatch("featurePending", {
          Title: this.feature
        });
        this.loading = null;
      }
    }
}
</script>
