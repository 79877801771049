
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-red p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p><b>{{ organization.Title }}</b></p>
            <p>You are about to delete entire <b>{{ organization.Title }}</b> organization.</p>
            <p>All of your data will be permanently deleted.</p>
            <p>This action can not be undo, are you sure about this?</p>


          </div>
          

        </div>
      </div>

      <div class="row d-flex align-items-center justify-content-center mb-5" v-if="!next">
        <el-button type="danger" icon="fas fa-exclamation-circle" @click="showNext" v-if="!next">Proceed to next step</el-button>
      </div>


      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="deleteOrganization" v-if="next">

        <el-form-item label="Organization name" required>
          <el-input v-model="formData.OrganizationName" placeholder="Type the name of the organization you want to delete" required clearable></el-input>
        </el-form-item>

        <el-form-item label="Your password" required>
          <el-input v-model="formData.Password" type="password" placeholder="******" :disabled="formData.OrganizationName != organization.Title" required clearable></el-input>
          <ValidationErrorItem validationKey="Password"  />
        </el-form-item>

        <el-form-item label="Reason" required>
          <el-input v-model="formData.DeletionReason" type="text" placeholder="Please specify..." :disabled="formData.OrganizationName != organization.Title" required clearable></el-input>
          <ValidationErrorItem validationKey="DeletionReason"  />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer" v-if="next">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="danger" icon="fas fa-exclamation-circle" @click="deleteOrganization" :loading="loading == 'delete'" :disabled="formData.OrganizationName != organization.Title">Delete organization</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DeleteOrganizationModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          next:false,
          formData: {
            DeletionReason:null,
            Password:null,
            OrganizationName:null,
          }
        }   
    },
    methods: {
      showNext() {
        this.next = true;
      },
      async deleteOrganization() {
        this.loading = 'delete';

        var res = await this.$store.dispatch("deleteOrganization", this.formData);
        
        this.loading = null;
        if(res != null)
        {
          await this.$store.dispatch("signOut");
          await this.$router.push({name: "login"});
          //this.$emit('closeDialog');
        }
      }
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      organization() {
        return this.account.CurrentOrganization;
      },
    }
}
</script>

