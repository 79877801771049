<template>
    <div class="login-page">
       
        <div class="card login-card shadow-md">
            <div class="login-hero">
                <div class="login-text">
                    <h1>Forgot password</h1>
                    <p>Dont remeber your password? Reset password here.</p>
                </div>
            </div>

            <div class="login-form">
                <div class="form-header">
                    <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="" class="dark-logo"/>
                    <img src="@/assets/img/worktivity-horizontal-logo-white.svg" alt="" class="white-logo"/>
                    <h3>Forgot Password</h3>
                    <p>Fill your account email and we will send you a password code.</p>
                </div>
                <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="forgotPassword">
                    <el-form-item label="Your e-mail address">
                        <el-input v-model="formData.email" type="email" placeholder="someone@example.com"  clearable></el-input>
                        <ValidationErrorItem validationKey="Email" />
                    </el-form-item>
                    <el-form-item class="text-center" v-if="validateMe" style="min-height:73px">
                        <cfturnstile
                        sitekey="0x4AAAAAAAHfPtMmKhNgJWQa"
                        @verify="verify"
                        />
                        <ValidationErrorItem validationKey="CaptchaToken" />
                    </el-form-item>
                    <el-form-item class="login-button">
                        <el-button :loading="loading == 'forgotPassword'" type="primary" round @click="forgotPassword">Recover my password</el-button>
                    </el-form-item>
                </el-form>

                <div class="forgot-button">
                    <el-button type="text" @click="$router.push({ name: 'login' })">Remember your password?</el-button>
                </div>
                <div class="d-flex justify-content-center">
                    <label for="theme" class="theme">
                        <span class="theme__toggle-wrap">
                            <input type="checkbox" class="theme__toggle" id="theme" role="switch" v-model="$store.state.darkMode" name="theme" value="dark" />
                            <span class="theme__icon">
                            <span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span
                            ><span class="theme__icon-part"></span>
                            </span>
                        </span>
                    </label>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Turnstile from 'cfturnstile-vue2'

export default {
    name: 'ForgotPasswordIndex',
    components: {
        'cfturnstile': Turnstile
    },
    data(){
        return {
            meta: {
                title: 'Forgot password',
                desc: 'Dont remeber your password? Reset password here.'
            },
            validateMe:true,
            loading:null,
            formData: {
                email: null,
                CaptchaToken:null
            }
        }
    },
    mounted() {
        this.$setMeta(this.meta);
    },
    methods: {
        verify(token) {
            this.formData.CaptchaToken = token;
        },
        async forgotPassword() {
            this.loading = 'forgotPassword';
            var res = await this.$store.dispatch("forgotPassword", this.formData);
            this.loading = null;

            if(res == null)
            {
                this.validateMe = false;
                setTimeout(() => {
                    this.validateMe = true;
                },100);
                
                return;
            }

            this.$router.push({name:'resetpassword'});
        }
    }
}
</script>


