<template>
  <div class="container mt-10" id="pageTopToScroll">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
    
    <div class="page-filter-area">
      <div class="filter-options">

        <div class="filter-item" style="max-width:220px;">
          <DateRangeFilterPicker :clearable="true" @changed="(dates) => { 
            filter.StartDate = dates.StartDate; 
            filter.EndDate = dates.EndDate 
            }" />
        </div>

        <div class="filter-item" v-if="$isInRole('Owner,Coowner')">
          <TeamSelectbox :selectedId="filter.TeamId" @changed="(id) => filter.TeamId = id" :key="filter.TeamId" />
        </div>
        <div class="filter-item" v-if="$isInRole('Owner,Coowner,Manager')">
          <EmployeeSelectbox :selectedId="filter.EmployeeId" @changed="(id) => filter.EmployeeId = id" @changedTeam="(id) => filter.TeamId = id" :key="filter.EmployeeId" />
        </div>
        <div class="filter-item">
          <LeaveRightTypeSelectbox :nullable="true" :selectedId="filter.Type" @changed="(id) => filter.Type = id" />
        </div>
        <div class="filter-item">
          <LeaveRequestStatusSelectbox :nullable="true" :selectedId="filter.Status" @changed="(id) => filter.Status = id" />
        </div>
      </div>

      <el-tooltip class="item" effect="dark" content="Sync data" placement="top">
        <el-button type="link" round icon="fas fa-sync" @click="getData" :loading="loading == 'getData'"></el-button>
      </el-tooltip>

      <el-button round icon="fas fa-plus" @click="addModal = true">Add</el-button>
    </div>

    <div class="card p-5" v-if="!leaveRequests">
      <el-skeleton :rows="12" animated />
    </div>
    <div class="card p-5" id="activityHistoryTable" v-else>
      <div class="activity-table" >

        <NoData v-if="leaveRequests.Items.length == 0" />
        <el-table :data="leaveRequests.Items" v-if="leaveRequests.Items.length > 0" v-loading="loading == 'getData'" ref="tab" @row-click="expand" :row-class-name="'cursor-pointer'">

          <el-table-column label="Employee" min-width="250">
            <template slot-scope="scope">
              <EmployeeTablePreview :employee="scope.row.Employee" />
            </template>
          </el-table-column>

          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="d-flex">
                <div class="f-flex flex-column" v-if="props.row.ApprovedByEmployee != null">
                  <p>Approved by: </p>
                  <EmployeeTablePreview :employee="props.row.ApprovedByEmployee" />
                </div>
                <div class="f-flex flex-column" v-if="props.row.RejectedByEmployee != null">
                  <p>Rejected by: </p>
                  <EmployeeTablePreview :employee="props.row.RejectedByEmployee" />
                </div>
              </div>

              <p class="mt-2" v-if="props.row.Notes != null && props.row.Notes != ''">Notes: {{ props.row.Notes }}</p>

               <p class="mt-2">Created at: {{ moment(props.row.CreateDate).format('DD MMMM yyyy, dddd HH:mm') }}</p>
            </template>
          </el-table-column> 


          <el-table-column label="Type" class-name="text-center" width="140">
            <template slot-scope="scope">
              <span class="badge" :class="getBadgeBg(scope.row.Type)">{{ $getEnumTitle('LeaveRightType', scope.row.Type) }}</span>
            </template>
          </el-table-column>

          

          <el-table-column label="Start date" width="120" class-name="text-center">
            <template slot-scope="scope">
              <span>{{ moment(scope.row.StartDate).format("DD.MM.yyyy") }}</span>
            </template>
          </el-table-column>

          <el-table-column label="End date" width="120" class-name="text-center">
            <template slot-scope="scope">

              <el-tooltip class="item" effect="dark" :disabled="scope.row.OffDays <= 0" :content="scope.row.OffDays + ' off days excluded.'" placement="top">
                <span :class="scope.row.OffDays > 0 ? 'text-blue' : ''">{{ moment(scope.row.EndDate).format("DD.MM.yyyy") }}</span>
              </el-tooltip>

              
            </template>
          </el-table-column>

          <el-table-column label="Duration" width="120" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge">{{ scope.row.Count }} day(s)</span>
            </template>
          </el-table-column>

          <el-table-column label="Status" width="120" class-name="text-center">
            <template slot-scope="scope">
              <span class="badge" :class="getStatusBg(scope.row.Status)">{{ $getEnumTitle('LeaveRequestStatus', scope.row.Status) }}</span>
            </template>
          </el-table-column>

          
          <el-table-column width="140" v-if="$isInRole('Owner,Coowner')">
            <template slot-scope="scope" >
              <el-row :key="scope.row.ID" style="display:flex;justify-content:space-between">
                <el-tooltip class="item" effect="dark" content="Approve" placement="top" >
                <span>
                  <el-button type="success" icon="fas fa-check" size="small" circle @click.stop="approveLeaveRequest(scope.row)" :loading="loading == 'approve-' + scope.row.ID" :disabled="scope.row.Status == 1 || scope.row.Status != 0"></el-button>
                </span>
              </el-tooltip>

               <el-tooltip class="item" effect="dark" content="Reject" placement="top" v-if="$isInRole('Owner,Coowner')">
                <span>
                  <el-button type="warning" icon="fas fa-times" size="small" circle @click.stop="rejectLeaveRequest(scope.row)" :loading="loading == 'reject-' + scope.row.ID" :disabled="scope.row.Status != 0 || scope.row.Status == 2"></el-button>
                </span>
              </el-tooltip>
              
              <el-tooltip class="item" effect="dark" :content="'Delete'" placement="top">
                <span>
                  <el-button type="danger" icon="fas fa-trash" size="small" @click.stop="deleteLeaveRequest(scope.row.ID)" circle :loading="loading == 'delete-' + scope.row.ID"></el-button>
                </span>
              </el-tooltip>
              </el-row>
            </template>
          </el-table-column>

          
        </el-table>
      </div>

      <Pagination
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="leaveRequests.TotalCount"  
        v-if="leaveRequests.Items.length > 0"
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />
    </div>

      <el-dialog :close-on-click-modal="false" class="medium" title="Add leave request" :visible.sync="addModal" center modal-append-to-body destroy-on-close>
      <LeaveRequestAddUpdateModal @leaveRequestCreated="getData" @closeDialog="addModal = false" v-if="addModal" />
    </el-dialog>

      <div class="d-flex mb-5 justify-content-center mt-5" v-if="leaveRequests && leaveRequests.TotalCount > 0 && $isInRole('Owner,Coowner')">
        <el-button :loading="loading == 'exportXlsx'" :disabled="sub.Subscription.Plan.DataExport != 0" type="text" @click="exportXlsx">
          Export this report as xlsx <i class="fas fa-cloud-download-alt"></i>
        </el-button>
        <div class="d-flex align-items-center">
          <span class="badge badge-sm blue cursor-pointer  ml-2" v-if="sub.Subscription.Plan.DataExport != 0" @click="$goToBilling()">UPGRADE NOW</span>
        </div>
       </div>

  </div>
</template>

<script>

import Breadcrumb from '../partials/Breadcrumb';
import moment from "moment/min/moment-with-locales";

import Pagination from '@/components/Pagination';
import SearchInput from '@/components/form/SearchInput.vue';

import TeamSelectbox from '@/components/form/TeamSelectbox.vue';
import EmployeeSelectbox from '@/components/form/EmployeeSelectbox.vue';
import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import LeaveRightTypeSelectbox from '@/components/form/LeaveRightTypeSelectbox.vue';
import LeaveRequestStatusSelectbox from '@/components/form/LeaveRequestStatusSelectbox.vue';

import LeaveRequestAddUpdateModal from './LeaveRequestAddUpdateModal.vue';

export default {
    name: 'LeaveSettingsIndex',
    components: {
    Breadcrumb,
    Pagination,
    SearchInput,
    TeamSelectbox,
    EmployeeSelectbox,
    DateTypeFilterSelectbox,
    LeaveRightTypeSelectbox,
    LeaveRequestStatusSelectbox,
    LeaveRequestAddUpdateModal
},
    data(){
        return {
          loading:null,
          moment,
          meta: {
            title: 'Leave requests',
            desc:'You can manage your leave requests here.'
          },
          addModal:false,
          filter: {
            SearchTerm:null,
            TeamId:null,
            EmployeeId:null,
            Page: 1,
            PageSize:12,
            StartDate:null,
            EndDate:null,
            Type:null,
            Status:null,
          },
        }
    },
    async mounted() {
      this.$setMeta(this.meta);

      await this.$store.dispatch("getSubscription");
      await this.getData();
    },
    methods: {
      getBadgeBg(status) {
        switch (status) {
          case 0:
            return "blue";
          case 1:
            return "orange";
          case 2:
            return "yellow";
          default:
            break;
        }
      },
      getStatusBg(status) {
        switch (status) {
          case 0:
            return "blue";
          case 1:
            return "green";
          case 2:
            return "yellow";
          default:
            break;
        }
      },
      expand(row, isExpanded) {
        if(this.$refs.tab.store.states.expandRows.some(x => x.ID == row.ID))
        {
          this.$refs.tab.store.states.expandRows = this.$refs.tab.store.states.expandRows.filter(x => x.ID != row.ID);
        }
        else
        {
          this.$refs.tab.store.states.expandRows.push(row);
        }
      },
      async approveLeaveRequest(item) {
        this.$confirm('You are about to approve this leave request. This action can not be undo, are you sure about this?', 'Confirmation', {
          confirmButtonText: 'Yes, approve',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(async () => {
          this.loading = 'approve-' + item.ID ;
          await this.$store.dispatch("approveLeaveRequest", {Id: item.ID});
          await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
      async rejectLeaveRequest(item) {
         this.$prompt('Reason', '', {
          confirmButtonText: 'Reject',
          title:'Confirmation',
          inputPattern: /[a-zA-Z0-9]/,
          inputErrorMessage: "Please specify...",
          cancelButtonText: 'Cancel'
        }).then(async ({ value }) => {
          this.loading = 'reject-' + item.ID;
          await this.$store.dispatch("rejectLeaveRequest", {Id: item.ID, RejectionReason: value});
          await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
      async getData() {
        this.loading = 'getData';
        await this.$store.dispatch("leaveRequests", this.filter)
        this.loading = null;
      },
      async exportXlsx() {
        this.loading = 'exportXlsx';
        var res = await this.$store.dispatch("leaveRequestsExport", this.filter);
        if(res != null)
        {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      async deleteLeaveRequest(id) {
         this.$confirm('You are about to delete this leave request. This action can not be undo, are you sure about this?', 'Confirmation', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          this.loading = 'delete-' + id ;
          await this.$store.dispatch("deleteLeaveRequest", {Id: id});
          //await this.getData();
          this.loading = null;
        }).catch(() => {
          this.loading = null;
        });
      },
    },
    computed: {
      leaveRequests() {
        return this.$store.getters.getLeaveRequests;
      },
      sub() {
        return this.$store.getters.getSubscription;
      },
    },
    watch: {
      'filter.StartDate'() {
        if (this.filter.Page != 1) {
          this.filter.Page = 1;
        }
        else {
          this.getData();
        }
      },
      'filter.TeamId'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.Type'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.EmployeeId'() {
        if(this.filter.Page > 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      },
      'filter.SearchTerm'() {
        this.filter.Page = 1;
        this.getData();
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("pageTopToScroll");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("pageTopToScroll");
      }
    }
}
</script>
